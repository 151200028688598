import { Bar } from "react-chartjs-2"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { forwardRef } from "react"
import { ESTIMATOR_SAVVLY_BONUS, ESTIMATOR_INDEX_FUNDS_ALONE, RI_INDEX_FUNDS_ALONE_DATALABELS } from "@config"
import { nFormatter } from "@utils"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)

export const Returns = forwardRef(({ returns, options }, ref) => {
  const returnInvestmentData = Object.values(returns).map((data) => ({
    indexFundAlone: data.index_fund_alone,
    savvlyUpside: data.difference,
    returnLabels: data.payout_age,
    [data.difference]: data.savvly_upside
  }))

  const indexFundAlone = returnInvestmentData.map((data) => Number(data.indexFundAlone))
  const savvlyUpside = returnInvestmentData.map((data) => data.savvlyUpside)
  const labels = returnInvestmentData.map((data) => data.returnLabels)

  const data = {
    labels,
    datasets: [
      {
        label: "Index Funds Alone",
        data: indexFundAlone,
        backgroundColor: `${ESTIMATOR_INDEX_FUNDS_ALONE}`,
        minBarLength: 20,
        barThickness: 80,

        datalabels: {
          color: `${RI_INDEX_FUNDS_ALONE_DATALABELS}`
        }
      },
      {
        label: "Savvly Bonus",
        data: savvlyUpside,
        backgroundColor: `${ESTIMATOR_SAVVLY_BONUS}`,
        minBarLength: 20,
        barThickness: 80,

        datalabels: {
          labels: {
            totalBar: {
              formatter: (value) => {
                const obj = returnInvestmentData.find((data) => Object.values(data).indexOf(value) !== -1)
                return `$${nFormatter(obj[value])}`
              },
              anchor: "end",
              align: "end",
              offset: 2
            }
          }
        }
      }
    ]
  }
  return (
    <div className="column is-full">
      <div className="box my-box">
        <h1 className="has-text-weight-semibold has-text-centered is-size-3">Supercharge your retirement</h1>
        <h2 className="mb-4 has-text-centered is-size-5 has-text-primary has-text-weight-semibold">
          How Savvly boosts returns over time
        </h2>
        <div className="px-5 mx-5">
          <Bar options={options} data={data} ref={ref} />
        </div>
      </div>
    </div>
  )
})
