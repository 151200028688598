import React from "react"
import { MultiFileDropzone } from "./upload/MultiFileDropzone"
import { FaCloudUploadAlt, FaExclamationCircle } from "react-icons/fa"
import { IconNumberOne, IconNumberTwo, IconNumberThree } from "@assets"

const UploadFiles = ({ files, setFiles }) => {
  return (
    <>
      <h1 className="is-size-3 mb-4 is-mobile-centered has-text-weight-bold has-text-primary">
        We will need the client's verification documents.
      </h1>
      <h2 className="is-size-4 has-text-weight-medium mb-4 has-text-grey mb-6">
        {" "}
        <FaCloudUploadAlt className="has-text-primary" /> Upload Files
      </h2>

      <div className="columns is-12 is-justify-content-space-around">
        <div className="column is-6">
          <MultiFileDropzone files={files} setFiles={setFiles} />
        </div>
        <div className="column is-6">
          <p>
            <span className="has-text-black has-text-weight-bold is-size-5">
              {" "}
              <FaExclamationCircle className="has-text-primary" /> To be compliant
            </span>{" "}
            <br />
            Savvly needs the following documents.
          </p>
          <br />
          <ul>
            <li className="is-flex is-align-items-center mt-2 mb-3">
              <img src={IconNumberOne} alt="icon number one" className="number-list" />
              <span>Driver License or ID or Passport as Proof of identity</span>
            </li>
            <li className="is-flex is-align-items-center mt-2 mb-3">
              <img src={IconNumberTwo} alt="icon number two" className="number-list" />
              <span>W9 Form as Proof of income tax</span>
            </li>
            <li className="is-flex is-align-items-center mt-2 mb-3">
              <img src={IconNumberThree} alt="icon number three" className="number-list" />
              <span>Bank Statement or Utility Bill as Proof of residency</span>
            </li>
          </ul>
          <p className="mt-6 has-text-grey has-text-weight-medium is-size-5">
            Skip for now, send files via email to: <br />
            <a href="mailto:service-center@savvly.com" className="button is-text p-0 has-text-primary">
              service-center@savvly.com
            </a>
          </p>
        </div>
      </div>
    </>
  )
}

export default UploadFiles
