import * as Sentry from "@sentry/react"
import axios from "axios"
import { REACT_APP_ESTIMATOR_API_URL } from "@config"

export const getRetirementSavings = async (dataForm) => {
  try {
    const {
      currentAge,
      annualIncome,
      currentRetirementSavings,
      monthlyContributions,
      monthlyBudget,
      otherRetirementIncome,
      retirementAge,
      lifeExpectancy,
      preRetirementRateOfReturn,
      postRetirementRateOfReturn,
      inflationRate,
      annualIncomeIncrease
    } = dataForm

    const response = await axios.get(
      `${REACT_APP_ESTIMATOR_API_URL}/retirement-savings-simulation?current_age=${currentAge}&annual_pre_tax_income=${annualIncome}&current_retirement_savings=${currentRetirementSavings}&monthly_contribution=${monthlyContributions}&monthly_budget_in_retirement=${monthlyBudget}&other_retirement_income=${
        otherRetirementIncome || 0
      }&retirement_age=${retirementAge}&life_expectancy=${lifeExpectancy}&pre_retirement_rate_of_return=${preRetirementRateOfReturn}&post_retirement_rate_of_return=${postRetirementRateOfReturn}&inflation_rate=${inflationRate}&annual_income_increase=${annualIncomeIncrease}`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )

    if (response && response.data) {
      return response.data
    }
  } catch (e) {
    Sentry.captureException(e, {
      tags: {
        section: "Fetch Retirement Savings API"
      },
      extra: {
        message: "Cannot fetch data"
      }
    })
  }
}
