import React, { useState } from "react"
import { BiFemale, BiMale, BiCaretDown, BiCaretUp } from "react-icons/bi"
import {
  ToastNotification,
  ageOutOfRangeNotification,
  fiveYearsDifferenceNotification,
  payoutAgeGreaterThanClientAge
} from "@components/notification"
import { LUMP_SUM_AND_MULTIPLE_PAYOUT_GRAPH_PAGE, RETURN_INVESTMENT_GRAPH_PAGE, DEFAULT_CLIENT_AGE } from "@utils"

export const ProfileModal = ({
  pageId,
  toggleModal,
  isModalOpen,
  gender,
  setGender,
  currentAge,
  setCurrentAge,
  payoutAge,
  setPayoutAge
}) => {
  const [dropdownActive, setDropdownActive] = useState(false)
  const [profileAge, setProfileAge] = useState(currentAge)
  const [profileGender, setProfileGender] = useState(gender)

  const handleGenderSelect = (e) => {
    setProfileGender(e.target.id)
  }
  const handleSubmit = () => {
    if (profileAge >= 18 && (pageId === RETURN_INVESTMENT_GRAPH_PAGE ? profileAge <= 65 : profileAge <= 80)) {
      setGender(profileGender)
      setCurrentAge(profileAge)

      if (payoutAge > profileAge && pageId) {
        if (Math.abs(profileAge - payoutAge) < 5) {
          setPayoutAge(profileAge + 5)
          ToastNotification(fiveYearsDifferenceNotification(profileAge))
        }
      } else if (pageId === LUMP_SUM_AND_MULTIPLE_PAYOUT_GRAPH_PAGE) {
        setPayoutAge(profileAge + 5)
        ToastNotification(payoutAgeGreaterThanClientAge(payoutAge, profileAge))
      }

      toggleModal()
    } else {
      ToastNotification(ageOutOfRangeNotification(profileAge))
      setProfileAge(DEFAULT_CLIENT_AGE)
      setCurrentAge(DEFAULT_CLIENT_AGE)
    }
  }

  const handleCancel = () => {
    toggleModal()
    setCurrentAge(DEFAULT_CLIENT_AGE)
  }

  return (
    <div>
      <div className={`modal ${isModalOpen ? "is-active" : null}`}>
        <div className="modal-background" onClick={toggleModal}></div>
        <div className="modal-content">
          <div className="box box-container">
            <div className="content is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
              <h1 className="title is-3 has-text-primary">Client Profile</h1>
              <div className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
                <div className="card is-rounded mr-1">
                  <div className="card-content card-style-background">
                    <div className="has-text-centered">
                      <span className="has-text-primary is-size-3">{profileGender === "Male" ? <BiMale /> : <BiFemale />}</span>
                    </div>
                    <div className="has-text-centered">
                      <div
                        className={`dropdown mb-4 ${dropdownActive ? "is-active" : null}`}
                        onClick={() => setDropdownActive(!dropdownActive)}>
                        <div className="dropdown-trigger">
                          <button className="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu">
                            <span data-cy="gender-dropdown-btn">
                              {profileGender} {dropdownActive ? <BiCaretUp /> : <BiCaretDown />}
                            </span>
                          </button>
                        </div>
                        <div className="dropdown-menu" id="dropdown-menu" role="menu">
                          <div className="dropdown-content">
                            <a
                              className="dropdown-item px-5"
                              value="Male"
                              id="Male"
                              data-cy="male-dropdown-option"
                              onClick={handleGenderSelect}>
                              Male
                            </a>
                            <a
                              className="dropdown-item px-5"
                              value="Female"
                              id="Female"
                              data-cy="female-dropdown-option"
                              onClick={handleGenderSelect}>
                              Female
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="is-flex flex-direction-row is-justify-content-center is-align-items-center">
                      <div className="field is-flex is-flex-direction-column is-align-items-center">
                        <label className="label">Age</label>
                        <div className="control">
                          <input
                            className="input is-primary has-text-primary"
                            type="number"
                            required
                            min="18"
                            max={pageId === RETURN_INVESTMENT_GRAPH_PAGE ? 65 : 80}
                            placeholder="Age"
                            value={profileAge}
                            data-cy="age-input"
                            onChange={(e) => setProfileAge(e.target.value ? parseInt(e.target.value) : 0)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-flex is-justify-content-space-between m-3">
              <div className="control">
                <button className="button is-primary" data-cy="save-client-gender-btn" onClick={handleSubmit}>
                  Save
                </button>
              </div>
              <div className="control">
                <button className="button is-text" data-cy="cancel-client-gender-btn" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={toggleModal}></button>
      </div>
    </div>
  )
}
