import React, { useState, useEffect } from "react"
import {
  DEFAULT_INVESTMENT_AMOUNT,
  MAX_INVESTMENT_AMOUNT,
  MIN_INVESTMENT_AMOUNT,
  STEP_INVESTMENT_AMOUNT
} from "@components/inputs"
import { SliderInput } from "@components/estimator"

export const InvestmentAmountSimulation = ({ fundingAmount, setFundingAmount }) => {
  const [invAmount, setInvAmount] = useState(fundingAmount / 1000)
  const [fireInvAmount, setFireInvAmount] = useState(false)

  useEffect(() => {
    if (!fireInvAmount) return
    setFireInvAmount(false)
    setFundingAmount(invAmount * 1000)
  }, [fireInvAmount])

  return (
    <div>
      <section className="investment__amount">
        <div className="subtitle">
          <div className="slide-container pt-1">
            <SliderInput
              sliderData={invAmount}
              setSliderData={setInvAmount}
              keyName={"investmentAmount"}
              min={MIN_INVESTMENT_AMOUNT}
              max={MAX_INVESTMENT_AMOUNT}
              step={STEP_INVESTMENT_AMOUNT}
              defaultValue={DEFAULT_INVESTMENT_AMOUNT}
              fireState={setFireInvAmount}
            />
          </div>
        </div>
        <div className="is-flex is-flex-direction-row is-justify-content-space-between">
          <div className="is-size-7">
            <p>
              <strong className="has-text-grey">${MIN_INVESTMENT_AMOUNT}K</strong>
            </p>
          </div>
          <div className="is-size-7">
            <p>
              <strong className="has-text-grey">${MAX_INVESTMENT_AMOUNT}K</strong>
            </p>
          </div>
        </div>
        <div className="subtitle is-flex is-justify-content-center">
          <p>
            <strong className="is-size-4 has-text-primary" data-cy="control-investment-amount">
              ${invAmount}K
            </strong>
          </p>
        </div>
      </section>
    </div>
  )
}
