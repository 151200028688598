import { Bar } from "react-chartjs-2"
import React from "react"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)
export function MultiplePayoutsSimulation({ options, chartData, multiplePayoutsRef }) {
  return (
    <div className="container-height" data-cy="multiple-payouts-bar">
      <h2 className="is-size-6 hidden-public-estimator has-text-weight-bold">MULTIPLE PAYOUTS</h2>
      {chartData && <Bar options={options} data={chartData} ref={multiplePayoutsRef} id="multiplePayoutsCanvas" />}
    </div>
  )
}
export const MultiplePayoutsSimulationMemoized = React.memo(MultiplePayoutsSimulation)
