import React, { useEffect } from "react"
import { StepsProgressBar, ContractInput } from "@components/contractForm"
import { ContractDateInput, ContractTextInput, ToggleButton } from "@components/inputs"
import { COUNTRIES } from "@config"

const SpouseSsnInfo = (props) => {
  const { data, inputChanged, setValidations } = props

  useEffect(() => {
    let validations = [
      {
        field: "spouseSSN",
        type: "required"
      }
    ]

    if (data.spouseIsUSCitizen === "N") {
      validations = [
        ...validations,
        {
          field: "spouseIsGreenCard",
          type: "required"
        },
        {
          field: "spouseAlienIdOrVisa",
          type: "required"
        },
        {
          field: "spouseAlienIdOrVisaExpiration",
          type: "required"
        },
        {
          field: "spouseAlienIdOrVisaExpiration",
          type: "max10years"
        },
        {
          field: "spousePassportNumber",
          type: "required"
        },
        {
          field: "spousePassportExpiration",
          type: "required"
        },
        {
          field: "spousePassportExpiration",
          type: "max10years"
        },
        {
          field: "spousePassportCountry",
          type: "required"
        }
      ]
    }
    setValidations(validations)
  }, [data.spouseIsUSCitizen])

  return (
    <>
      <StepsProgressBar step={2} />
      <div className="columns is-justify-content-space-around">
        <div className="column is-8">
          <div>
            <h1 className="is-size-3 mb-4 is-mobile-centered has-text-weight-bold has-text-primary">
              Is the spouse a U.S. Citizen?
            </h1>
            <ToggleButton
              value={data.spouseIsUSCitizen}
              sideA={"Y"}
              sideB={"N"}
              labelA={"Yes"}
              labelB={"No"}
              setState={(value) => inputChanged("spouseIsUSCitizen", value)}
            />

            {data.spouseIsUSCitizen === "Y" && (
              <>
                <div className="has-text-weight-normal is-size-6"></div>
                <ContractTextInput {...props} title="Social Security Number (SSN)" field="spouseSSN" mask={"ssn"} />
              </>
            )}
            {data.spouseIsUSCitizen === "N" && (
              <>
                <div className="has-text-weight-normal">What is their resident status?</div>
                <div>
                  <ToggleButton
                    value={data.spouseIsGreenCard}
                    labelA={"U.S Permanent Resident (Green Card)"}
                    labelB={"U.S Visa Holder"}
                    sideA={"Y"}
                    sideB={"N"}
                    setState={(value) => inputChanged("spouseIsGreenCard", value)}
                  />
                </div>
                <br />

                <div className="columns is-multiline">
                  <div className="column is-6">
                    <ContractTextInput
                      {...props}
                      title="Social Security Number (SSN)"
                      field="spouseSSN"
                      mask={"ssn"}
                      placeholder="123-45-6789"
                    />
                  </div>
                  <ContractInput
                    {...props}
                    type="dropdown"
                    title="Country of Origin"
                    field="spousePassportCountry"
                    values={Object.keys(COUNTRIES).map((country) => ({ title: COUNTRIES[country], value: country }))}
                  />
                  <div className="column is-6">
                    <ContractTextInput
                      {...props}
                      title={data.spouseIsGreenCard === "Y" ? "USCIS #" : "Visa Number"}
                      mask={data.spouseIsGreenCard === "Y" ? "uscis" : "visa"}
                      max={data.spouseIsGreenCard === "Y" ? 11 : 19}
                      field="spouseAlienIdOrVisa"
                    />
                  </div>
                  <div className="column is-6">
                    <ContractDateInput
                      {...props}
                      title={`${data.spouseIsGreenCard === "Y" ? "USCIS #" : "Visa"} Expiration Date`}
                      field="spouseAlienIdOrVisaExpiration"
                    />
                  </div>

                  <div className="column is-6">
                    <ContractTextInput
                      {...props}
                      title="Passport Number"
                      field="spousePassportNumber"
                      mask={"passport"}
                      max="9"
                      placeholder="A12345678"
                    />
                  </div>

                  <div className="column is-6">
                    <ContractDateInput {...props} title="Passport Expiration Date" field="spousePassportExpiration" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SpouseSsnInfo
