import { Link } from "react-router-dom"
import { LogInButton, BackgroundImage } from "@components"
import { SavvlyLogo, LandingHero } from "@assets"

const Landing = () => {
  const backgroundStyles = {
    backgroundImage: `linear-gradient(rgb(0 0 0 / 0.527), rgb(0 0 0 / 0.5)), url(${LandingHero})`,
    backgroundPosition: "50% 100%",
    backgroundRepeat: "no-repeat",
    height: "100%"
  }
  return (
    <BackgroundImage style={backgroundStyles}>
      <div className="is-flex is-justify-content-space-between p-5 is-align-items-center">
        <div>
          <img src={SavvlyLogo} />
        </div>
        <div>
          <LogInButton />
        </div>
      </div>
      <div className="landing-hero is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
        <h1 className="title has-text-white">Welcome to Savvly</h1>
        <p className="has-text-white mb-4 has-text-centered">
          Savvly is an alternative investment fund that's designed to protect and perform.
        </p>
        <Link to="register" className="button is-primary" data-cy="register-button">
          Register Now
        </Link>
      </div>
    </BackgroundImage>
  )
}

export default Landing
