import * as Sentry from "@sentry/react"
import React, { useEffect } from "react"
import { useMsal } from "@azure/msal-react"
import { LoadingCube } from "@assets"
import { registerRequest, loginRequest } from "@config"

const Register = () => {
  const { instance } = useMsal()
  const accounts = instance.getAllAccounts()

  useEffect(() => {
    if (accounts.length > 0) {
      instance.acquireTokenSilent(registerRequest).catch((error) => {
        Sentry.captureException(error, {
          tags: {
            section: "Registration page"
          },
          extra: {
            message: "Could not acquire token and redirect to registration",
            errors: error
          }
        })
        instance.acquireTokenRedirect(registerRequest)
      })
    } else {
      instance.loginRedirect(loginRequest)
    }
  }, [])

  return (
    <div className="is-flex is-justify-content-center is-align-items-center is-full-height">
      <img src={LoadingCube} alt="loading cube gif" />
    </div>
  )
}

export default Register
