const StepsProgressBar = ({ step }) => {
  const steps = [
    { title: "STEP 1", minStep: 0 },
    { title: "STEP 2", minStep: 1 },
    { title: "STEP 3", minStep: 2 },
    { title: "STEP 4", minStep: 3 },
    { title: "STEP 5", minStep: 4 }
  ]
  const activeStep = step >= 0 && step <= 5 ? step : 0

  return (
    <ul className={"steps has-content-above is-mobile-hide"}>
      {steps.map((step, index) => (
        <li key={index} className={"steps-segment " + (activeStep === index ? "is-active" : "")}>
          <span className="steps-marker is-flex is-align-items-center is-justify-content-center"></span>
          <div className="steps-content is-divider-content has-text-centered">
            <p
              className={
                "is-size-6 has-text-weight-semibold " + (activeStep > step.minStep ? "has-text-primary" : "has-text-grey")
              }>
              {step.title}
            </p>
          </div>
        </li>
      ))}
      <li className={"steps-segment " + (activeStep === 5 ? "is-active" : "")}>
        <span className="steps-marker is-flex is-align-items-center is-justify-content-center"></span>
        <div className="steps-content is-divider-content has-text-centered">
          <p className="is-size-4"></p>
        </div>
      </li>
    </ul>
  )
}

export default StepsProgressBar
