import { IconChecked } from "@assets"

export const SidebarAccordion = (props) => {
  const { step } = props
  const PhaseOneItems = ["Client Information", "U.S. Citizenship", "Contact Information"]

  return (
    <div className="sidebar-accordion">
      <section className="container-accordion">
        <div className="is-multiline section-accordion">
          <Collapse title="Client Profile" number={1} {...props}>
            {PhaseOneItems.map((title, i) => (
              <CollapseItem key={i} step={step} index={i} title={title} />
            ))}
          </Collapse>
          <Collapse title="Contract Choice" number={2} {...props}></Collapse>
          <Collapse title="Review" number={3} {...props}></Collapse>
          <Collapse title="Upload Files" number={4} {...props}></Collapse>
          <Collapse title="Send Agreement" number={5} {...props}></Collapse>
        </div>
      </section>
    </div>
  )
}

const CollapseItem = ({ step, index, title }) => <div className={step === index + 1 ? "active" : ""}>{title}</div>

const Collapse = ({ title, children, number, phase, step }) => {
  let cardClass = "phase-pending"

  if (phase > number) cardClass = "phase-completed"
  else if (phase === number && children) cardClass = "current-phase-with-children"
  else if (phase === number && !children) cardClass = "current-phase"

  return (
    <div>
      <div className="card">
        <header className={`card-header is-flex is-align-items-center ${cardClass}`}>
          <span className="circle-accordion is-flex is-align-items-center is-justify-content-center">
            {phase > number ? <img src={IconChecked} alt="icon checked" /> : number}
          </span>
          <p className="p-4 has-text-weight-medium is-size-6">{title}</p>
        </header>
        <div className={`card-content p-0 ${step && phase === number ? "active-card" : "hidden-card"}`}>
          {children && <div className="content accordion-content has-text-centered">{children}</div>}
        </div>
      </div>
    </div>
  )
}
