import React from "react"
import * as Slider from "@radix-ui/react-slider"
import * as Tooltip from "@radix-ui/react-tooltip"
import "./styles.css"

export const SliderInput = ({ sliderData, setSliderData, fireState, min, defaultValue, max, step, tooltipText }) => {
  return (
    <Slider.Root
      className="SliderRoot"
      defaultValue={[defaultValue]}
      max={max}
      min={min}
      step={step}
      value={[sliderData]}
      onValueChange={([value]) => setSliderData(value)}
      onValueCommit={() => fireState(true)}>
      <Slider.Track className="SliderTrack">
        <Slider.Range className="SliderRange" />
      </Slider.Track>
      {tooltipText && (
        <Tooltip.Provider delayDuration={100}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <Slider.Thumb className="SliderThumb" aria-label="Volume" />
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className="TooltipContent" sideOffset={5}>
                {tooltipText}
                <Tooltip.Arrow className="TooltipArrow" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      )}
      {!tooltipText && <Slider.Thumb className="SliderThumb" aria-label="Return" />}
    </Slider.Root>
  )
}
