import * as Sentry from "@sentry/react"
import debounce from "lodash.debounce"
import { useState, useEffect, useRef } from "react"
import { useMediaQuery } from "react-responsive"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import {
  PUBLIC_DEFAULT_AVERAGE_MARKET_RETURN,
  PUBLIC_MAX_AVERAGE_MARKET_RETURN,
  PUBLIC_MIN_AVERAGE_MARKET_RETURN,
  PUBLIC_STEP_AVERAGE_MARKET_RETURN,
  PUBLIC_DEFAULT_CLIENT_AGE,
  PUBLIC_DEFAULT_GENDER,
  PUBLIC_DEFAULT_MALE_WITHDRAWAL_AGE,
  PUBLIC_DEFAULT_FEMALE_WITHDRAWAL_AGE
} from "@components/inputs"
import { useAppContext } from "@context"
import { useChart } from "@hooks"
import { getMultiplePayouts } from "@api"
import {
  publicMultiplePayoutOptions,
  setPieChartData,
  setPublicEstimatorData,
  DEFAULT_INVESTMENT_AMOUNT_NUMERIC,
  SINGLE_UPFRONT_PAYMENT,
  MONTHLY_INSTALLMENT_VALUES,
  PUBLIC_SAVVLY_INSTALLMENTS_MAX_INVESTMENT_AMOUNT,
  PUBLIC_SAVVLY_INSTALLMENTS_MIN_INVESTMENT_AMOUNT,
  FREQUENCY_AND_DURATION_OPTIONS,
  DEFAULT_MONTHLY_INSTALLMENT,
  ESTIMATOR_MULTIPLE_PAYOUTS_ENDPOINT,
  ESTIMATOR_INSTALLMENT_ENDPOINT,
  getCurrentAgeData,
  getWithdrawalAgeData,
  genderLabel,
  FULL_RANGE_AGE_LIMIT_PAYMENTS,
  LIMITED_RANGE_AGE_LIMIT_PAYMENTS,
  TARGET_OPTION_TO_LIMIT_AGE_RANGE,
  shouldDisplayOption,
  shouldDisableOption
} from "@config"
import { PlusSymbolEstimator } from "@assets"
import { marketReturnTooltip, formatNumberAsCurrency, nFormatter } from "@utils"
import { SliderInput } from "@components/estimator"

const PublicEstimator = () => {
  const [gender, setGender] = useState(PUBLIC_DEFAULT_GENDER)
  const [currentAge, setCurrentAge] = useState(PUBLIC_DEFAULT_CLIENT_AGE)
  const [averageReturn, setAverageReturn] = useState(PUBLIC_DEFAULT_AVERAGE_MARKET_RETURN)
  const [withdrawalAge, setWithdrawalAge] = useState(PUBLIC_DEFAULT_MALE_WITHDRAWAL_AGE)
  const [sliderData, setSliderData] = useState(PUBLIC_DEFAULT_AVERAGE_MARKET_RETURN)
  const [multiple, setMultiple] = useState(null)
  const [fireAverageReturn, setFireAverageReturn] = useState(false)
  const [chartData, setChartData] = useState(null)
  const [isMobile, setIsMobile] = useState(null)
  const [pieChartOptions, setPieChartOptions] = useState(null)
  const [frequencyAndDurationInstallment, setFrequencyAndDurationInstallment] = useState(SINGLE_UPFRONT_PAYMENT)
  const [investmentAmountError, setInvestmentAmountError] = useState(false)
  const [investmentAmount, setInvestmentAmount] = useState(DEFAULT_INVESTMENT_AMOUNT_NUMERIC)
  const [ageLimits, setAgeLimits] = useState(FULL_RANGE_AGE_LIMIT_PAYMENTS)
  const prevFrequencyAndDurationRef = useRef(frequencyAndDurationInstallment)

  const [debounceFinished, setDebounceFinished] = useState(true)

  const { estimatorEndpoint, setEstimatorEndpoint } = useAppContext()
  const highchartsRef = useRef(null)
  const isTabletorMobile = useMediaQuery({ query: "(max-width: 900px)" })

  const savvlyUpside = nFormatter(multiple?.[0]?.total_savvly_upside)
  const totalIndexFundAlone = nFormatter(multiple?.[0]?.total_index_fund_alone ?? multiple?.[0]?.total_market_alone)
  const differenceAmount = nFormatter(
    multiple?.[0]?.total_savvly_upside - (multiple?.[0]?.total_index_fund_alone ?? multiple?.[0]?.total_market_alone)
  )
  const amountToGetBack = nFormatter(multiple?.[0]?.amount_to_get_back)
  const payoutAge = multiple?.[3]?.payout_age
  const amountAlreadyPaid = multiple?.[0]?.amount_already_paid

  const withdrawalAgeData = getWithdrawalAgeData(currentAge)
  const currentAgeData = getCurrentAgeData(ageLimits)

  useEffect(() => {
    setIsMobile(isTabletorMobile)
    if (isMobile) {
      fetchData(multiplePayoutsSimulationUrl)
    }
  }, [isTabletorMobile])

  const multiplePayoutsSimulationUrl = `multiple-payouts-simulation?gender=${gender}&current_age=${currentAge}&average_return=${averageReturn}&funding_amount=${investmentAmount}&withdrawal_age=${withdrawalAge}`

  const multiplePayoutsInstallmentsSimulationUrl = `multiple-payouts-installments-simulation?gender=${gender}&current_age=${currentAge}&average_return=${averageReturn}&monthly_installment=${investmentAmount}&installment_years=${frequencyAndDurationInstallment}&withdrawal_age=${withdrawalAge}`

  const chartImages = {
    PlusSymbolEstimator
  }

  const chartRef = useChart(chartData, publicMultiplePayoutOptions, chartImages)

  const fetchData = async (url) => {
    try {
      const data = await getMultiplePayouts(url)

      if (data && investmentAmount !== "") {
        if (!Array.isArray(data) || data.length === 0) {
          return
        }
        setMultiple(data)
        setChartData(setPublicEstimatorData(data))
      }
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          section: "Public Estimator"
        },
        extra: {
          message: "Cannot get public estimator data"
        }
      })
    }
  }

  useEffect(() => {
    if (!fireAverageReturn) return
    setFireAverageReturn(false)
    setAverageReturn(sliderData)
  }, [fireAverageReturn])

  useEffect(() => {
    if (!investmentAmountError && frequencyAndDurationInstallment === SINGLE_UPFRONT_PAYMENT && investmentAmount !== "") {
      fetchData(multiplePayoutsSimulationUrl)
      setEstimatorEndpoint(ESTIMATOR_MULTIPLE_PAYOUTS_ENDPOINT)
    }
  }, [gender, currentAge, averageReturn, withdrawalAge, frequencyAndDurationInstallment])

  useEffect(() => {
    if (!investmentAmountError && frequencyAndDurationInstallment !== SINGLE_UPFRONT_PAYMENT) {
      fetchData(multiplePayoutsInstallmentsSimulationUrl)
      setEstimatorEndpoint(ESTIMATOR_INSTALLMENT_ENDPOINT)
    }
  }, [multiplePayoutsInstallmentsSimulationUrl, frequencyAndDurationInstallment])

  useEffect(() => {
    if (multiple && estimatorEndpoint) {
      setPieChartOptions(setPieChartData(multiple, estimatorEndpoint))
    }
  }, [multiple, estimatorEndpoint])

  const handleChangeFrequencyAndDuration = (e) => {
    const value = e.target.value
    prevFrequencyAndDurationRef.current = frequencyAndDurationInstallment

    setFrequencyAndDurationInstallment(value)

    if (value === SINGLE_UPFRONT_PAYMENT) {
      setInvestmentAmount(DEFAULT_INVESTMENT_AMOUNT_NUMERIC)
    } else if (prevFrequencyAndDurationRef.current === SINGLE_UPFRONT_PAYMENT) {
      setInvestmentAmount(DEFAULT_MONTHLY_INSTALLMENT)
    }

    if (value === FREQUENCY_AND_DURATION_OPTIONS[TARGET_OPTION_TO_LIMIT_AGE_RANGE].value) {
      setAgeLimits(LIMITED_RANGE_AGE_LIMIT_PAYMENTS)
    } else {
      setAgeLimits(FULL_RANGE_AGE_LIMIT_PAYMENTS)
    }
  }

  const validateInvestmentAmount = (amount) => {
    if (amount < PUBLIC_SAVVLY_INSTALLMENTS_MIN_INVESTMENT_AMOUNT) {
      return PUBLIC_SAVVLY_INSTALLMENTS_MIN_INVESTMENT_AMOUNT
    } else if (amount > PUBLIC_SAVVLY_INSTALLMENTS_MAX_INVESTMENT_AMOUNT) {
      return PUBLIC_SAVVLY_INSTALLMENTS_MAX_INVESTMENT_AMOUNT
    }
    return amount
  }

  const debouncedFetchData = useRef(
    debounce((multiplePayoutsSimulationUrl, amount) => {
      if (
        amount !== "" &&
        amount > PUBLIC_SAVVLY_INSTALLMENTS_MIN_INVESTMENT_AMOUNT &&
        amount < PUBLIC_SAVVLY_INSTALLMENTS_MAX_INVESTMENT_AMOUNT
      ) {
        fetchData(multiplePayoutsSimulationUrl)
        setEstimatorEndpoint(ESTIMATOR_MULTIPLE_PAYOUTS_ENDPOINT)
      }

      setDebounceFinished(true)
    }, 2000)
  ).current

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel()
    }
  }, [debouncedFetchData])

  const handleInvestmentAmountChange = (e) => {
    const { value } = e.target
    const numericPart = value.replaceAll(/\D+/g, "")
    const amount = numericPart.length > 0 ? parseFloat(numericPart) : ""

    setInvestmentAmountError(
      amount < PUBLIC_SAVVLY_INSTALLMENTS_MIN_INVESTMENT_AMOUNT ||
        amount > PUBLIC_SAVVLY_INSTALLMENTS_MAX_INVESTMENT_AMOUNT ||
        amount === ""
    )

    const multiplePayoutsSimulationUrl = `multiple-payouts-simulation?gender=${gender}&current_age=${currentAge}&average_return=${averageReturn}&funding_amount=${amount}&withdrawal_age=${withdrawalAge}`

    setInvestmentAmount(amount)
    setDebounceFinished(false)
    debouncedFetchData(multiplePayoutsSimulationUrl, amount)
  }

  useEffect(() => {
    if (debounceFinished && frequencyAndDurationInstallment === SINGLE_UPFRONT_PAYMENT) {
      const validatedAmount = validateInvestmentAmount(investmentAmount)

      if (validatedAmount !== investmentAmount) {
        setInvestmentAmount(validatedAmount)
        setInvestmentAmountError(false)

        const multiplePayoutsSimulationUrl = `multiple-payouts-simulation?gender=${gender}&current_age=${currentAge}&average_return=${averageReturn}&funding_amount=${validatedAmount}&withdrawal_age=${withdrawalAge}`

        fetchData(multiplePayoutsSimulationUrl)
        setEstimatorEndpoint(ESTIMATOR_MULTIPLE_PAYOUTS_ENDPOINT)
      }
    }
  }, [debounceFinished, frequencyAndDurationInstallment, investmentAmount, investmentAmountError])

  const handleChangeGender = (e) => {
    const { value } = e.target

    setGender(value)
    const defaultWithdrawalAge =
      value === genderLabel.male ? PUBLIC_DEFAULT_MALE_WITHDRAWAL_AGE : PUBLIC_DEFAULT_FEMALE_WITHDRAWAL_AGE

    setWithdrawalAge(defaultWithdrawalAge)
  }

  return (
    <div id="public-estimator">
      <div className="columns is-mobile is-multiline pb-0 my-0 w-full">
        <div className="column is-half-mobile is-half-tablet field pb-0 my-0 is-one-quarter-desktop">
          <div className="control is-expanded">
            <label htmlFor="select" className="has-text-weight-bold">
              Your Age:
            </label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  className="has-background-light"
                  defaultValue={currentAge}
                  onChange={(v) => setCurrentAge(v.target.value)}
                  data-cy="select-age">
                  {currentAgeData.map((age) => (
                    <option key={age}>{age}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-half-mobile is-half-tablet field pb-0 my-0  is-one-quarter-desktop">
          <div className="control is-expanded">
            <label htmlFor="select" className="has-text-weight-bold">
              Your Gender:
            </label>
            <div className="control">
              <div className="select is-fullwidth">
                <select className="has-background-light" onChange={handleChangeGender} data-cy="select-gender">
                  <option>{genderLabel.male}</option>
                  <option>{genderLabel.female}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-full-mobile field pb-0 my-0 is-3-desktop">
          <div className="control is-expanded">
            <label htmlFor="select" className="has-text-weight-bold">
              Frequency & Duration:
            </label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  className="has-background-light"
                  defaultValue={frequencyAndDurationInstallment}
                  onChange={handleChangeFrequencyAndDuration}
                  data-cy="select-frequency-and-duration">
                  {FREQUENCY_AND_DURATION_OPTIONS.map(
                    ({ label, value }) =>
                      shouldDisplayOption(label, currentAge) && (
                        <option key={value} value={value} disabled={shouldDisableOption(label, currentAge)}>
                          {label}
                        </option>
                      )
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>
        {frequencyAndDurationInstallment !== SINGLE_UPFRONT_PAYMENT ? (
          <div className="column is-full-mobile is-half-tablet field pb-0 my-0 is-3-desktop is-one-quarter-desktop">
            <div className="control is-expanded">
              <label htmlFor="select" className="has-text-weight-bold">
                Investment Amount:
              </label>
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    className="has-background-light"
                    onChange={(v) => setInvestmentAmount(v.target.value)}
                    value={investmentAmount}
                    data-cy="monthly-investment-amount">
                    {MONTHLY_INSTALLMENT_VALUES.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="column is-full-mobile is-half-tablet field pb-0 my-0 is-3-desktop is-one-quarter-desktop">
            <div className="control is-expanded">
              <label className="has-text-weight-bold">Investment Amount:</label>
              <div className="control">
                <input
                  className="input is-primary has-text-weight-bold"
                  data-cy="select-investment-amount"
                  type="text"
                  value={investmentAmount.toLocaleString() ?? ""}
                  onChange={handleInvestmentAmountChange}
                />
                {investmentAmount < PUBLIC_SAVVLY_INSTALLMENTS_MIN_INVESTMENT_AMOUNT ? (
                  <span className="has-text-danger is-size-6 has-text-weight-medium">
                    * Minimum investment allowed {formatNumberAsCurrency(PUBLIC_SAVVLY_INSTALLMENTS_MIN_INVESTMENT_AMOUNT)}
                  </span>
                ) : investmentAmount > PUBLIC_SAVVLY_INSTALLMENTS_MAX_INVESTMENT_AMOUNT ? (
                  <span className="has-text-danger is-size-6 has-text-weight-medium">
                    * Maximum investment allowed {formatNumberAsCurrency(PUBLIC_SAVVLY_INSTALLMENTS_MAX_INVESTMENT_AMOUNT)}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="field pb-0 my-3 mb-3 mx-4">
        <div className="control is-expanded" data-cy="average-market-return-slider">
          <label htmlFor="input" className="has-text-black has-text-weight-bold">
            Choose your long-term S&P 500 return expectation:
            <span className="has-text-orange has-text-weight-semibold pl-2" data-cy="label-average-market-return">
              {sliderData} %
            </span>
          </label>
          <SliderInput
            tooltipText={marketReturnTooltip}
            sliderData={sliderData}
            setSliderData={setSliderData}
            keyName={"averageReturn"}
            min={PUBLIC_MIN_AVERAGE_MARKET_RETURN}
            max={PUBLIC_MAX_AVERAGE_MARKET_RETURN}
            step={PUBLIC_STEP_AVERAGE_MARKET_RETURN}
            defaultValue={PUBLIC_DEFAULT_AVERAGE_MARKET_RETURN}
            fireState={setFireAverageReturn}
          />
        </div>
      </div>
      {isMobile ? (
        <p className="px-0 py-3 has-text-centered is-size-5">
          With Savvly, you can get
          <span className="has-text-orange has-text-weight-bold"> ${totalIndexFundAlone}</span> more than investing in markets
          alone!
        </p>
      ) : (
        <p className="p-3 has-text-centered is-size-4">
          With Savvly, you can get an estimated total of
          <span className="has-text-orange has-text-weight-bold" data-cy="savvly-upside-label">
            {" "}
            ${savvlyUpside}{" "}
          </span>
          <span>
            in late-life payouts if you live until
            <span className="has-text-orange has-text-weight-bold" data-cy="payout-age-label">
              {" "}
              {payoutAge}
            </span>
            .
          </span>
          <br />
          <b>
            That's
            <span className="has-text-orange has-text-weight-bold" data-cy="total-index-fund-alone-label">
              {" "}
              ${totalIndexFundAlone}{" "}
            </span>
            more than{" "}
            <span className="has-text-orange has-text-weight-bold" data-cy="difference-amount-label">
              {" "}
              ${differenceAmount}{" "}
            </span>{" "}
            investing in the markets alone! *
          </b>
        </p>
      )}
      <div className="columns pb-0 my-0 iframe-wrapper">
        <div className="column pb-0 my-0">
          <div className="container-height">
            {isMobile ? (
              <div>
                <HighchartsReact highcharts={Highcharts} options={pieChartOptions} ref={highchartsRef} />
              </div>
            ) : (
              <canvas ref={chartRef} />
            )}
            <div
              className={`p-3 has-text-centered ${
                isMobile ? "is-size-6" : "is-size-5"
              } is-flex is-justify-content-center is-flex-wrap-wrap is-align-items-center`}>
              <span>If you withdraw or pass away at age&nbsp;</span>
              <div className={`${isMobile ? "select is-small" : "select is-medium"} `}>
                <select
                  className="has-background-light"
                  value={withdrawalAge}
                  onChange={(e) => setWithdrawalAge(e.target.value)}
                  data-cy="select-withdrawal-age">
                  {withdrawalAgeData.map((age) => (
                    <option key={age}>{age}</option>
                  ))}
                </select>
              </div>
              <span>&nbsp;you or your estate will get back</span>
              <span className="has-text-orange has-text-weight-semibold ml-1" data-cy="amount-to-get-back-label">
                ${amountToGetBack}
              </span>
              <div>&nbsp;**</div>
              {amountAlreadyPaid !== 0 ? (
                <p className="has-text-centered">
                  on top of the
                  <span className="has-text-orange has-text-weight-semibold mx-1" data-cy="amount-already-paid-label">
                    ${nFormatter(amountAlreadyPaid)}
                  </span>
                  you already received in Savvly payouts.
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicEstimator
