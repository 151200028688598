const formatApiUrl = (envDomain, type) => {
  let envDomainScheme = "https://"
  if (envDomain?.includes("localhost") || envDomain?.match(/^https?:\/\/(?:[0-9]{1,3}\.){3}[0-9]{1,3}/)) {
    envDomainScheme = "http://"
  }
  if (type === "api") {
    return `${envDomainScheme}${envDomain}/api`
  } else if (type === "public") {
    return `${envDomainScheme}${envDomain}/public`
  } else {
    return `${envDomainScheme}${envDomain}`
  }
}

export const REACT_APP_MSAL_CLIENT_ID = process.env.REACT_APP_MSAL_CLIENTID
export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const REACT_APP_DOCUMENT_UPLOAD_API_KEY = process.env.REACT_APP_DOCUMENT_UPLOAD_API_KEY
export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN

export const REACT_APP_API_URL = formatApiUrl(process.env.REACT_APP_API_DOMAIN)

export const REACT_APP_CDN_URL = formatApiUrl(process.env.REACT_APP_CDN_DOMAIN, "public")

export const REACT_APP_API_V2_URL = formatApiUrl(process.env.REACT_APP_API_V2_DOMAIN, "api")
export const REACT_APP_DOCUMENT_UPLOAD_API_URL = formatApiUrl(process.env.REACT_APP_DOCUMENT_UPLOAD_API_DOMAIN, "api")
export const REACT_APP_ESTIMATOR_API_URL = formatApiUrl(process.env.REACT_APP_ESTIMATOR_API_DOMAIN, "api")

export const REACT_APP_MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE
