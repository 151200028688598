export const chartPlusImagePlugin = (img) => {
  const imgPlugin = {
    id: "imagePlugin",
    beforeDraw(chart) {
      const {
        ctx,
        chartArea: { top, bottom, right, left, width, height },
        scales: { x, y }
      } = chart
      const barWidth = x.getPixelForValue(1) - x.getPixelForValue(0)
      const barCount = chart?.data?.datasets?.[0]?.data?.length

      ctx.save()
      for (let index = 0; index < barCount - 1; index++) {
        const barRight = x.getPixelForValue(index + 1) - barWidth / 2
        const imageX = barRight - 40 / 2
        ctx.drawImage(img, imageX, height * 0.8, 40, 40)
      }
    }
  }

  return imgPlugin
}

export const chartImagePayoutPlugin = (imgs) => {
  const imgPlugin = {
    id: "imagePlugin",
    beforeDraw(chart) {
      const {
        ctx,
        chartArea: { top, bottom, right, left, width, height },
        scales: { x, y }
      } = chart
      const barWidth = x.getPixelForValue(1) - x.getPixelForValue(0)
      const barCount = chart?.data?.datasets?.[0]?.data?.length
      const spaceBetweenBars = (right - left - barWidth * barCount) / (barCount + 1)

      ctx.save()
      for (let index = 0; index < barCount; index++) {
        const imageX = left + (index + 1) * spaceBetweenBars + index * barWidth + (barWidth - 40) / 2
        ctx.drawImage(imgs[index], imageX - 30, height * 0.05, 90, 30)
      }
    }
  }

  return imgPlugin
}
