export const GENERAL_ERROR_MESSAGE = "Something went wrong. Please try again later."

export const SERVER_ERROR_MESSAGE = "Something went wrong with your request. Please try again."

export const BAD_RESPONSE_MESSAGE = "Bad request received."

export const FAILED_CREATING_CONTRACT = "Failed to create contract."

export const VALIDATION_ERROR_MESSAGE = "Validation error."

export const ERROR_SAVING_CONTRACT = "Error saving contract."

export const FAILED_SAVING_CONTRACT = "There was an error saving the contract. Please try again."

export const FAILED_SENDING_CONTRACT = "There was an error sending the contract by email. Please try again."

export const ERROR_SENDING_CONTRACT = "Error sending the contract"

export const ERROR_PREVIEWING_CONTRACT = "There was an error previewing the contract. Please try again."

export const ERROR_PREVIEWING_TITLE = "Error previewing the contract"

export const ERROR_EDITING_CONTRACT = "Error editing contract. Please try again."

export const ERROR_RETRIEVING_CONTRACT_TITLE = "Error retrieving contract details"

export const ERROR_RETRIEVING_CONTRACT_INFORMATION = "Failed to retrieve contract information. Please try again."
