import {
  GENDER_FEMALE,
  GENDER_MALE,
  IS_GREEN_CARD_YES,
  IS_US_CITIZEN_YES,
  MULTIPLE_PAYOUTS,
  SINGLE,
  SINGLE_PURCHASER,
  VOO_VANGUARD,
  COUNTRY,
  ACCREDITED_INVESTOR
} from "@utils"

export const contractPayload = {
  firstName: "",
  middleName: "",
  lastName: "",
  occupation: "",
  sourceOfWealth: "",
  email: "",
  address: "",
  address2: "",
  zipCode: "",
  city: "",
  state: "",
  birthDate: "",
  sex: GENDER_MALE,
  isUSCitizen: IS_US_CITIZEN_YES,
  isMarried: SINGLE,
  isGreenCard: IS_GREEN_CARD_YES,
  ssn: "",
  passportNumber: "",
  passportExpiration: "",
  passportCountry: "",
  country: COUNTRY,
  alienIdOrVisa: "",
  alienIdOrVisaExpiration: "",
  spouseFirstName: "",
  spouseMiddleName: "",
  spouseLastName: "",
  spouseBirthDate: "",
  spouseGender: GENDER_FEMALE,
  spouseSSN: "",
  spouseIsUSCitizen: IS_US_CITIZEN_YES,
  spouseIsGreenCard: IS_GREEN_CARD_YES,
  spouseAlienIdOrVisa: "",
  spouseAlienIdOrVisaExpiration: "",
  spousePassportExpiration: "",
  spousePassportCountry: "",
  spousePassportNumber: "",
  spouseAddress: "",
  spouseCountry: COUNTRY,
  spouseEmail: "",
  investmentStartDate: "",
  payoutOptions: 0,
  payoutAges: [85],
  payoutOption: MULTIPLE_PAYOUTS,
  advisorFee: 0,
  ETF: VOO_VANGUARD,
  funding: 0,
  purchaserType: ACCREDITED_INVESTOR,
  purchasingAs: SINGLE_PURCHASER
}
