import React from "react"
import { FaFilePdf } from "react-icons/fa"
import { SavvlyLogoBlack } from "@assets"

export const PdfCover = ({ setPreviewPdf, clientName }) => {
  return (
    <div className="card pb-6">
      <div className="card-header is-justify-content-space-between">
        <div className="is-flex is-align-items-center p-3 mr-3">
          <img src={SavvlyLogoBlack} alt="Savvly Inc. Logo Light Mode" />
        </div>
        <div className="is-flex is-align-items-center p-3 mr-3">
          <button
            className="button is-primary is-normal is-responsive"
            onClick={() => setPreviewPdf(true)}
            data-cy="view-document-button">
            Preview Contract <FaFilePdf className="ml-1" />
          </button>
        </div>
      </div>
      <div className="card-content">
        <h2 className="is-size-4 has-text-weight-bold">Savvly Subscription Agreement</h2>
        <div className="mt-6 mb-6"></div>
        <div className="mb-6">
          <span className="has-text-primary">Prepared for:</span> <br />
          <span className="has-text-weight-medium is-size-5">{clientName}</span>
        </div>
      </div>
      <div className="mt-6 has-text-centered">
        <p className="subtitle has-text-primary">
          WILL BE SENT BY: <br />
          <span className="has-text-dark has-text-weight-bold is-size-4 mb-4">
            DocuSign<sup className="is-size-7">&#174;</sup>
          </span>
        </p>
      </div>
    </div>
  )
}
