import React, { useEffect } from "react"
import { ContractInput, StepsProgressBar } from "@components/contractForm"
import { DEFAULT_FEMALE_PAYOUT_AGES, DEFAULT_MALE_PAYOUT_AGES, PayoutAgesList } from "@config"
import { GENDER_MALE, LUMP_SUM_PAYOUT, MULTIPLE_PAYOUTS, VOO_VANGUARD } from "@utils"

const ContractChoices = (props) => {
  const { inputChanged, data, setValidations } = props
  useEffect(() => {
    setValidations([
      {
        field: "funding",
        type: "required"
      },
      {
        field: "funding",
        type: "amountRequired"
      },
      {
        field: "advisorFee",
        type: "required"
      },
      {
        field: "advisorFee",
        type: "max",
        value: "100"
      }
    ])
  }, [setValidations])

  useEffect(() => {
    let ages = []
    if (data.payoutOption === MULTIPLE_PAYOUTS) {
      ages = data.sex === GENDER_MALE ? DEFAULT_MALE_PAYOUT_AGES : DEFAULT_FEMALE_PAYOUT_AGES
    } else if (data.payoutOption === LUMP_SUM_PAYOUT) {
      ages = [data.sex === GENDER_MALE ? DEFAULT_MALE_PAYOUT_AGES[0] : DEFAULT_FEMALE_PAYOUT_AGES[0]]
    }
    inputChanged("payoutAges", ages)
  }, [data.payoutOption])

  return (
    <>
      <StepsProgressBar step={4} />
      <div className="columns is-justify-content-space-around">
        <div className="column is-8">
          <h2 className="is-size-3 mb-4 is-mobile-centered has-text-weight-bold has-text-primary">
            What investment do you want to make?
          </h2>
          <div className="columns is-multiline">
            <ContractInput {...props} type="money" size="12" title="Amount To Invest" field="funding" />
            <ContractInput
              {...props}
              type="dropdown"
              title="Investment Fund"
              field="ETF"
              values={[
                {
                  title: VOO_VANGUARD
                }
              ]}
            />
            <ContractInput {...props} title="Advisor Fee (in bps)" field="advisorFee" isNumber={true} max={100} min={0} />
            <hr />
            <ContractInput
              size="12"
              type="toggle"
              labelA={"Single Payout Age"}
              labelB={"Multiple Payouts"}
              sideA={LUMP_SUM_PAYOUT}
              sideB={MULTIPLE_PAYOUTS}
              value={data.payoutOption}
              setState={(val) => inputChanged("payoutOption", val)}
            />
            {data?.payoutOption === LUMP_SUM_PAYOUT ? (
              <ContractInput
                {...props}
                valueType="array"
                type="dropdown"
                title="Payout Age"
                field="payoutAges"
                values={PayoutAgesList(data?.sex).map((i) => ({
                  title: i,
                  value: i
                }))}
                data-cy="contract-input-payout-age"
              />
            ) : (
              <div className="column is-6">
                <div className="field">
                  <label>Payout Ages</label>
                  <div className="control box p-1 mt-3">
                    <input type="text" className="input has-text-weight-semibold" value={`${data.payoutAges}`} disabled={true} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default ContractChoices
