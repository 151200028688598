import { BROCHURES } from "@utils"

const Documentation = () => {
  return (
    <div id="doc-container" className="container p-4 mt-5-non-desktop">
      <div className="is-flex is-justify-content-space-between">
        <h1 className="title has-text-primary">Documentation</h1>
        <button
          className="button is-primary"
          onClick={() =>
            window.open(
              "https://calendly.com/meet-savvly/savvly-platform-demo-call?utm_medium=website&utm_source=adviser&utm_content=documentation",
              "_blank",
              "noopener,noreferrer"
            )
          }>
          Request a Meeting
        </button>
      </div>
      <hr />
      <div className="columns is-multiline">
        {BROCHURES.map((data, i) => (
          <div className="column is-3" key={i}>
            <a href={data.pdfLink} target="_blank" rel="noreferrer" data-cy={`documentation-brochure-${data.title}`}>
              <img src={data.img} alt={`${data.title} Image`} className="is-clickable" />
            </a>
            <div>
              <p>{data.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Documentation
