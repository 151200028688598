import React, { useEffect } from "react"
import { MARRIED } from "@utils"
import {
  ClientInformation,
  ContactInformation,
  ContractChoices,
  Review,
  SendAgreement,
  SpouseSsnInfo,
  SsnInfo,
  UploadFiles
} from "@components/contractForm"

export const ContractFormRouter = (originalProps) => {
  const { page, updatePhase, data } = originalProps

  const props = { ...originalProps }
  delete props.updatePhase

  const components = [
    {
      comp: <ClientInformation />,
      phase: 1,
      step: 1
    },
    {
      comp: <SsnInfo />,
      phase: 1,
      step: 2
    },
    ...(data.isMarried === MARRIED
      ? [
          {
            comp: <SpouseSsnInfo />,
            phase: 1,
            step: 2
          }
        ]
      : []),
    {
      comp: <ContactInformation />,
      phase: 1,
      step: 3
    },
    {
      comp: <ContractChoices />,
      phase: 2,
      step: null
    },
    {
      comp: <Review />,
      phase: 3,
      step: null
    },
    {
      comp: <UploadFiles />,
      phase: 4,
      step: null
    },
    {
      comp: <SendAgreement />,
      phase: 5,
      step: null
    }
  ]

  useEffect(() => {
    const { phase, step } = components[page - 1]
    updatePhase(phase, step)
  }, [page])

  return React.cloneElement(components[page - 1].comp, { ...props })
}
