import React, { useState } from "react"
import { FaAngleDown, FaAngleUp } from "react-icons/fa"

export const CollapsiblePanel = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={`collapsible-panel is-clickable ${isOpen ? "open" : ""}`}>
      <div className="is-flex is-align-items-center" onClick={() => setIsOpen(!isOpen)}>
        <h2 className="is-size-5 has-text-weight-semibold my-4 mr-2" data-cy="collapsible-panel-title">
          {title}
        </h2>
        {isOpen ? <FaAngleUp className="icon is-small" /> : <FaAngleDown className="icon is-small" />}
      </div>
      <div className="content">{content}</div>
    </div>
  )
}
