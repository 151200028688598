import React from "react"
import { FaFileContract } from "react-icons/fa"
import { CONTRACTS_QTY } from "@utils"

export default function Pagination({ totalContracts, contractsPerPage, currentPage, onPageChange, onContractsPerPageChange }) {
  const totalPages = contractsPerPage === -1 ? 1 : Math.ceil(totalContracts / contractsPerPage)
  const PAGES = contractsPerPage === -1 ? [-1] : Array.from({ length: totalPages }, (_, index) => index + 1)

  const handlePageClick = (page) => {
    onPageChange(page)
  }

  const handleChangeContractsPerPage = (event) => {
    const selectedValue = parseInt(event.target.value)
    onContractsPerPageChange(selectedValue)
  }

  return (
    <div className="notification">
      <div className="level">
        <div className="level-left mt-2">
          <div className="level-item">
            <div className="buttons has-addons">
              {PAGES.map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageClick(page)}
                  type="button"
                  className={`button ${page === currentPage ? "is-active is-primary" : ""}`}
                  disabled={contractsPerPage === -1 && page === -1}>
                  {page === -1 ? "All" : page}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="level-center mt-2">
          <div className="level-item">
            <small className="mr-2">Show</small>
            <div className="control has-icons-left">
              <div className="select">
                <select onChange={handleChangeContractsPerPage} data-cy="select-contracts-pagination">
                  {CONTRACTS_QTY.map((qty) => (
                    <option key={qty} value={qty}>
                      {qty === -1 ? "All" : qty}
                    </option>
                  ))}
                </select>
              </div>
              <div className="icon is-small is-left has-text-primary">
                <FaFileContract />
              </div>
            </div>
            <small className="ml-2">Contracts</small>
          </div>
        </div>
        <div className="level-right mt-2">
          <div className="level-item">
            <small>
              Page {currentPage} of {totalPages === 0 ? 1 : totalPages}
            </small>
          </div>
        </div>
      </div>
    </div>
  )
}
