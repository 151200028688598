import React, { useEffect } from "react"
import { useMsal } from "@azure/msal-react"
import { BackgroundImage } from "@components"
import { useAppContext } from "@context"
import { useQuery } from "@hooks"
import { saveAdviser } from "@api"
import { BlackPattern } from "@assets"

const PendingApproval = () => {
  const { homeAccountId, userIsApproved, idTokenClaims } = useAppContext()
  const { instance } = useMsal()
  const { query } = useQuery()

  useEffect(() => {
    if (!userIsApproved && idTokenClaims) saveAdviser(idTokenClaims, query)
  }, [])

  const logout = (instance) => {
    instance.logoutRedirect({
      account: instance.getAccountByHomeId(homeAccountId),
      postLogoutRedirectUri: "/"
    })
  }

  const backgroundStyles = {
    backgroundImage: `url(${BlackPattern})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  }

  return (
    <BackgroundImage style={backgroundStyles} className="is-full-height is-flex is-align-items-center is-justify-content-center">
      <div className="container has-background-white p-6">
        <h1 className="title has-text-primary">Thank you for completing the registration</h1>
        <div className="ml-2">
          <h3>The next step is to book a demo with one of our Savvly team members</h3>
          <button
            className="button is-primary mt-4 mb-4"
            onClick={() =>
              window.open(
                "https://calendly.com/meet-savvly/savvly-platform-demo-call?utm_medium=website&utm_source=adviser&utm_content=registration",
                "_blank",
                "noopener,noreferrer"
              )
            }>
            Schedule a Meeting
          </button>
          <h4 className="has-text-weight-bold mt-4">Need Assistance?</h4>
          <h4>
            Contact us at{" "}
            <a href="mailto:info@savvly.com" className="has-text-primary">
              info@savvly.com
            </a>
          </h4>
          <br />
          <button
            className="button is-primary is-outlined is-align-items-end"
            data-cy="pending-approval-logout"
            onClick={() => logout(instance)}>
            Logout
          </button>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default PendingApproval
