import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import swal from "sweetalert2"
import { contractPayload } from "./contractPayload"
import { ContractFormRouter, SidebarAccordion } from "@components/contractForm"
import { BackgroundImage } from "@components/BackgroundImage"
import { LOADING_CONTRACT_INFORMATION, promiseAlertMessage, showAlertMessage, AlertType } from "@components/notification"
import { ERROR_RETRIEVING_CONTRACT_TITLE, ERROR_RETRIEVING_CONTRACT_INFORMATION } from "@config"
import { useAppContext } from "@context"
import { useContract } from "@api"
import { SavvlyLogo, BlackPattern } from "@assets"
import { convertPayloadToEdit } from "@utils"

const ContractForm = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { advisorId } = useAppContext()
  const { getContract } = useContract()

  const [contractData, setContractData] = useState(contractPayload)
  const [errors, setErrors] = useState({})
  const [validations, setValidations] = useState([])

  const [page, setPage] = useState(1)
  const [phase, setPhase] = useState(1)
  const [step, setStep] = useState()
  const [files, setFiles] = useState([])
  const [editingContract, setEditingContract] = useState(false)

  useEffect(() => {
    const fetchContractDetails = async () => {
      if (id) {
        setEditingContract(true)
        promiseAlertMessage(LOADING_CONTRACT_INFORMATION)

        const response = await getContract(id, advisorId)
        if (response) {
          const convertedData = convertPayloadToEdit(response)
          setContractData(convertedData)
          swal.close()
        } else {
          showAlertMessage(AlertType.ERROR, ERROR_RETRIEVING_CONTRACT_TITLE, ERROR_RETRIEVING_CONTRACT_INFORMATION)
          navigate("/contracts")
        }
      }
    }
    fetchContractDetails()
  }, [id])

  const updatePhase = (phase, step) => {
    setPhase(phase)
    setStep(step)
  }

  const inputChanged = (field, value) => setContractData({ ...contractData, [field]: value })

  const cancelContract = () => {
    swal
      .fire({
        title: "Are you sure you want to cancel? You will lose your progress so far",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
        confirmButtonColor: "#f58350",
        denyButtonColor: "#737373"
      })
      .then((result) => {
        if (result.isConfirmed) {
          navigate("/")
        }
      })
  }

  const validateFormPerPage = () => {
    const errs = {}
    validations.forEach((val) => {
      const type = val.type
      const value = contractData[val.field]

      if (type === "required" && (value ?? "").toString().trim() === "") {
        errs[val.field] = "This field is required."
      }

      if (type === "amountRequired" && value <= 0) {
        errs[val.field] = "The funding amount must be greater than 0."
      }

      if (type === "length" && value?.length !== val.length) {
        errs[val.field] = `This field must have ${val.length} characters.`
      }

      if (type === "min" && Number(value) < Number(val.value)) {
        errs[val.field] = `Value must be above ${val.value}.`
      }

      if (type === "max" && Number(value) > Number(val.value)) {
        errs[val.field] = `Value must be below ${val.value}.`
      }

      if (type === "onlyNumbers" && !/^[0-9]+$/.test(value)) {
        errs[val.field] = `Only numbers are allowed.`
      }

      if (type === "18yo" && value?.trim() !== "") {
        let birthDate = value?.replace(/-/g, "/")
        birthDate = new Date(birthDate)
        const currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00"
        const myAge = ~~((Date.now(currentDate) - birthDate) / 31557600000)

        if (myAge < 18) errs[val.field] = `Needs to be at least 18 years old.`

        if (myAge > 120) errs[val.field] = `Maximum age is 120 years.`
      }

      if (type === "max10years" && value?.trim() !== "") {
        let date = value?.replace(/-/g, "/")
        date = new Date(date)

        const now = new Date()
        now.setHours(0, 0, 0, 0)

        if (date < now) errs[val.field] = `Document is expired.`

        const diffInDays = Math.round((date - now) / (1000 * 60 * 60 * 24))

        if (diffInDays > 3652) {
          errs[val.field] = `Document expiration can't be more than 10 years.`
        }
      }

      if (type === "validEmail" && value?.trim() !== "") {
        if (!value?.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
          errs[val.field] = `This email is not valid.`
        }
      }

      if (val.field === "spouseEmail" && contractData.spouseEmail === contractData.email) {
        errs[val.field] = "Spouse email should be different from subscriber email."
      }
    })

    setErrors(errs)
    return Object.keys(errs).length === 0
  }

  const nextPage = () => {
    if (validateFormPerPage() && phase !== 5) setPage(page + 1)
  }

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }

  const backgroundStyles = {
    backgroundImage: `url(${BlackPattern})`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1.5rem",
    color: "#fff"
  }

  return (
    <div id="client-information">
      <BackgroundImage style={backgroundStyles}>
        <img src={SavvlyLogo} alt="Savvly Logo" />
        <p className="is-mobile-hide">{editingContract ? "Subscription Agreement Update" : "Subscription Agreement Creation"}</p>
        <p className="is-clickable has-text-weight-bold has-text-primary" onClick={cancelContract}>
          Cancel
        </p>
      </BackgroundImage>
      <div className="columns m-0 is-flex-grow-1">
        <div className="column is-flex is-flex-direction-column is-9-desktop has-background-light px-6 is-12-tablet">
          <div className="step-content is-flex-grow-1">
            <ContractFormRouter
              page={page}
              updatePhase={updatePhase}
              inputChanged={inputChanged}
              data={contractData}
              setValidations={setValidations}
              errors={errors}
              setPage={setPage}
              files={files}
              setFiles={setFiles}
            />
          </div>
          <div className="mt-6">
            <div className="is-flex is-justify-content-space-between">
              <div className="buttons mb-0">
                {!(phase === 1 && step === 1) && (
                  <button className="button is-primary is-outlined" onClick={prevPage}>
                    Back
                  </button>
                )}
                {phase < 5 && (
                  <button className="button is-primary" onClick={nextPage}>
                    Next Step
                  </button>
                )}
              </div>
              <a href="#" className="button is-text has-text-primary" onClick={cancelContract}>
                Cancel
              </a>
            </div>
          </div>
        </div>
        <div className="column p-0 m-0 is-3-desktop is-hidden-tablet-only has-background-light">
          <SidebarAccordion phase={phase} step={step} />
        </div>
      </div>
    </div>
  )
}

export default ContractForm
