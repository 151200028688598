import { useEffect } from "react"
import { useAppContext } from "@context"

const SidebarPage = ({ children, value = true }) => {
  const { setShowSideBar } = useAppContext()

  useEffect(() => {
    setShowSideBar(value)
  }, [value])

  return children
}

export default SidebarPage
