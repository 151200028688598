import React, { useEffect } from "react"
import { ContractInput, StepsProgressBar } from "@components/contractForm"
import { STATES } from "@config"
import { MARRIED } from "@utils"

const ContactInformation = (props) => {
  const { inputChanged, data, setValidations } = props

  useEffect(() => {
    let validations = [
      {
        field: "email",
        type: "required"
      },
      {
        field: "email",
        type: "validEmail"
      },
      {
        field: "address",
        type: "required"
      },
      {
        field: "state",
        type: "required"
      },
      {
        field: "city",
        type: "required"
      },
      {
        field: "zipCode",
        type: "length",
        length: 5
      },
      {
        field: "zipCode",
        type: "onlyNumbers"
      }
    ]

    if (data.isMarried === MARRIED) {
      validations = [
        ...validations,
        {
          field: "spouseEmail",
          type: "required"
        },
        {
          field: "spouseEmail",
          type: "validEmail"
        }
      ]
    }

    setValidations(validations)
  }, [])

  return (
    <>
      <StepsProgressBar step={3} />
      <div className="columns is-justify-content-space-around">
        <div className="column is-8">
          <h1 className="is-size-3 mb-4 is-mobile-centered has-text-weight-bold has-text-primary">
            How we should contact the client?
          </h1>

          <div className="columns is-multiline">
            <ContractInput {...props} type="text" size="12" title="Email address" field="email" />
            {data.isMarried === MARRIED && (
              <ContractInput {...props} type="text" size="12" title="Spouse Email address" field="spouseEmail" />
            )}

            <ContractInput {...props} type="text" title="Address" field="address" />
            <ContractInput {...props} type="text" title="Address Line 2 (Optional)" field="address2" />
            <ContractInput {...props} type="text" title="City" field="city" />
            <ContractInput
              {...props}
              type="dropdown"
              title="State"
              field="state"
              values={STATES.map((state) => ({ title: state.name, value: state.abbrev }))}
              data-cy="contract-input-state"
            />
            <ContractInput {...props} type="text" title="Zip Code" field="zipCode" max={5} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactInformation
