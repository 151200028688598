import { createCypressSlug } from "@utils"

const ContractDateInput = ({ inputChanged, data, title, field, errors }) => {
  const errs = errors[field]

  return (
    <div className="field" data-cy={createCypressSlug(`contract-field-${title}`)}>
      <label className="contractLabel">{title}</label>
      <div className="control">
        <input
          value={data[field] ?? ""}
          onChange={(e) => inputChanged(field, e.target.value)}
          className={"input " + (errs ? "is-danger" : "")}
          type="date"
          max="9999-12-31"
          data-cy={createCypressSlug(`contract-input-${title}`)}
        />
      </div>
      <span className="help is-danger">{errs}</span>
    </div>
  )
}

export default ContractDateInput
