import React, { useState } from "react"
import "react-toastify/dist/ReactToastify.css"
import { ToastNotification, returnInvestmentInvalidEntryNotification } from "@components/notification"

export const ReturnInvestmentModal = ({ isReturnModalOpen, toggleReturnModal, averageReturn, setAverageReturn }) => {
  const [profileReturn, setProfileReturn] = useState(averageReturn)
  const validReturnEntries = ["2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]

  const handleSubmit = () => {
    if (validReturnEntries.includes(profileReturn.toString())) {
      setAverageReturn(profileReturn)
      toggleReturnModal()
    } else {
      ToastNotification(returnInvestmentInvalidEntryNotification(profileReturn, validReturnEntries))
      setAverageReturn(6)
      setProfileReturn(6)
    }
  }

  return (
    <div className={`modal ${isReturnModalOpen ? "is-active" : null}`}>
      <div className="modal-background" onClick={toggleReturnModal}></div>
      <div className="modal-content">
        <div className="box box-container modal-default-height">
          <div className="content is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
            <h1 className="title has-text-primary has-text-centered">Average Market Return</h1>

            <div className="card is-rounded has-background-white-ter">
              <div className="card-content py-6 px-6">
                <div className="is-flex px-6 mx-6 is-flex-direction-column is-justify-content-center is-align-items-center">
                  <input
                    className="input is-primary has-text-primary has-text-weight-bold"
                    value={profileReturn}
                    type="number"
                    required
                    min={2}
                    max={12}
                    step={1}
                    data-cy="return-input"
                    onChange={(e) => setProfileReturn(e.target.value)}
                  />
                  <button className="button is-primary is-size-6 mt-4" data-cy="set-return-btn" onClick={() => handleSubmit()}>
                    SET AVERAGE RETURN
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="field is-flex is-justify-content-flex-end m-3">
            <div className="control">
              <button className="button is-text" data-cy="cancel-return-btn" onClick={toggleReturnModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
