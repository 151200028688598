import * as Sentry from "@sentry/react"
import { useState, useEffect } from "react"
import { ContractsTable, ClientProfile, ContractPreview } from "@components/contracts"
import { REACT_APP_API_V2_URL } from "@config"
import { useAppContext } from "@context"
import { useQuery } from "@hooks"

const Contracts = () => {
  const { query } = useQuery()
  const { advisorId } = useAppContext()

  const [contracts, setContracts] = useState()
  const [selectedContract, setSelectedContract] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [currentView, setCurrentView] = useState("contractsTable")

  useEffect(() => {
    if (!selectedContract) {
      setContracts()
      fetchContracts()
    }
  }, [selectedContract])

  async function fetchContracts() {
    try {
      setLoading(true)
      const { response, error } = await query({
        path: `${REACT_APP_API_V2_URL}/subscription-agreements?adviserId=${advisorId}`
      })
      if (error) setError(error)
      response?.data && setContracts(response.data)
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          section: "Contract Page"
        },
        extra: {
          message: "Error retrieving the contracts."
        }
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div id="contracts-page" className="container p-4 mt-5-non-desktop">
      {loading && (
        <div className="is-flex is-justify-content-center is-align-items-center">
          <div className="loading-spinner"></div>
        </div>
      )}
      {!selectedContract && currentView === "contractsTable" ? (
        <ContractsTable
          loading={loading}
          error={error}
          contracts={contracts}
          setSelectedContract={setSelectedContract}
          setCurrentView={setCurrentView}
        />
      ) : currentView === "contractPreview" ? (
        <ContractPreview contract={selectedContract} setSelectedContract={setSelectedContract} setCurrentView={setCurrentView} />
      ) : (
        <ClientProfile contract={selectedContract} setSelectedContract={setSelectedContract} setCurrentView={setCurrentView} />
      )}
    </div>
  )
}

export default Contracts
