import * as Tooltip from "@radix-ui/react-tooltip"
import React, { useState } from "react"
import { AiOutlineQuestionCircle } from "react-icons/ai"

export const InputField = ({ labelText, placeholder, tooltipText, name, register, errors, type }) => {
  const [open, setOpen] = useState(false)
  return (
    <div className="label is-size-6 is-flex is-flex-direction-column">
      <label className="label-text mb-2 is-flex is-flex-direction-row is-align-items-center">
        {labelText}
        {tooltipText && (
          <Tooltip.Provider delayDuration={100}>
            <Tooltip.Root open={open} delayDuration={0} onOpenChange={setOpen}>
              <Tooltip.Trigger asChild>
                <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center">
                  <AiOutlineQuestionCircle className="ml-2 has-text-primary is-clickable" onClick={() => setOpen(!open)} />
                </div>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content className="TooltipContent" sideOffset={5}>
                  <span className="is-family-secondary">{tooltipText}</span>
                  <Tooltip.Arrow className="TooltipArrow" />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        )}
      </label>
      <input
        type={type}
        data-cy={name}
        className={`p-2 pl-4 is-family-secondary input is-rounded ${errors[name] ? "is-danger" : "is-link-light"}`}
        placeholder={placeholder}
        {...register(name)}
      />
      <ErrorMessage message={errors?.[name]?.message} />
    </div>
  )
}

export function ErrorMessage({ message }) {
  return message && <span className="is-size-7 has-text-danger">{message}</span>
}
