// Public Estimator
export const PUBLIC_ESTIMATOR_LABELS = "#313131"
export const PUBLIC_ESTIMATOR_DATALABELS = "#313131"
export const PUBLIC_ESTIMATOR_X_TICKS = "#313131"
export const PUBLIC_ESTIMATOR_Y_TICKS = "#313131"
export const PUBLIC_ESTIMATOR_SAVVLY_DISTRIBUTION = "#f58350"
export const PUBLIC_ESTIMATOR_MARKET_DISTRIBUTION = "#a0cdc8"

// Return Investment
export const RI_BORDER_COLOR = "transparent"
export const RI_INDEX_FUNDS_ALONE = "#fcf9f4"
export const RI_INVESTMENT = "#313131"
export const RI_X_TICKS = "#313131"
export const RI_Y_TICKS = "#c7c3c1"
export const RI_GRID = "#c7c3c1"
export const RI_INDEX_FUNDS_ALONE_DATALABELS = "#313131"

// Lump Sum
export const LS_Y_TICKS = "#c7c3c1"
export const LS_GRID = "#c7c3c1"

// Multiple Payouts
export const MP_DATALABELS = "#313131"
export const MP_X_TICKS = "#313131"
export const MP_Y_TICKS = "#c7c3c1"
export const MP_GRID = "#c7c3c1"

// Graph Colors
export const ESTIMATOR_SAVVLY_BONUS = "#f58350"
export const ESTIMATOR_INDEX_FUNDS_ALONE = "#c7c3c1"
export const ESTIMATOR_INVESTMENT = "#313131"
export const ESTIMATOR_DATALABELS = "#313131"
export const ESTIMATOR_BACKGROUND_COLOR = "#fcf9f4"

// Retirement Savings Chart
export const RECOMMENDED_AMOUNT_FOR_RETIREMENT = "#f58350"
export const CURRENT_RETIREMENT_FORECAST = "#c7c3c1"
