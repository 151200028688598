import React, { useEffect, useRef } from "react"
import "react-toastify/dist/ReactToastify.css"
import { ToastNotification, uploadCancelledNotification } from "@components/notification"
import { formatName } from "@utils"

export function FileDropzone({ file, onUpload, setFiles }) {
  const cancelFile = useRef(null)

  useEffect(() => {
    const upload = () => {
      onUpload(file)
    }
    upload()
  }, [])

  function onDeleteFile(file) {
    if (cancelFile.current) cancelFile.current("Upload Canceled")
    setFiles((curr) => curr.filter((fw) => fw.file !== file))
    ToastNotification(uploadCancelledNotification(file.name))
  }

  const fileName = formatName(file?.name, file?.name.length)

  return (
    <>
      <strong data-cy={`file-${file?.name}`}>{fileName}</strong>
      <div className="is-flex is-flex-direction-row">
        <i className="">pending...</i>
        <button className="ml-3 delete" onClick={() => onDeleteFile(file)} />
      </div>
    </>
  )
}
