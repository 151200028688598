import React from "react"
import { formatNumberAsCurrency } from "@utils"

export const RetirementSavingsInfo = ({ retirementSavings }) => {
  const result = retirementSavings?.find((obj) => obj?.current_age === retirementSavings?.[0]?.retirement_age - 1)

  const { what_you_will_have: whatYouWillHave, what_you_will_need: whatYouWillNeed, retirement_age: retirementAge } = result || {}

  return (
    <div>
      <h1
        data-cy="retirement-saving-age"
        className="is-size-3 has-text-weight-semibold is-family-poppins mb-4">{`Retirement Savings ${
        retirementAge ? `at Age ${retirementAge}` : ""
      }`}</h1>
      <div className="columns m-2">
        <div className="column card-border-right is-half is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <span className="is-size-5">What you'll have:</span>
          <span className="is-size-2 has-text-weight-bold has-text-grey" data-cy="label-what-you-will-have">
            {whatYouWillHave ? formatNumberAsCurrency(whatYouWillHave) : "$0"}
          </span>
        </div>
        <div className="column is-half is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <span className="is-size-5">What you'll need:</span>
          <span className="is-size-2 has-text-weight-bold has-text-primary" data-cy="label-what-you-will-need">
            {whatYouWillNeed ? formatNumberAsCurrency(whatYouWillNeed) : "$0"}
          </span>
        </div>
      </div>
    </div>
  )
}
