import { useState } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { SidebarPage, MaintenanceLayout } from "@layouts"
import { Dashboard, PageNotFound, Contracts, Estimator, Documentation, FAQ, ContractForm, PendingApproval } from "@pages"
import { useAppContext } from "@context"
import { BlackPattern } from "@assets"
import { BackgroundImage, Sidebar } from "@components"
import { REACT_APP_MAINTENANCE_MODE } from "@config"

const PageLayout = () => {
  const { showSideBar, userIsApproved } = useAppContext()
  const [maintenanceMode] = useState(REACT_APP_MAINTENANCE_MODE === "true")

  const backgroundStyles = {
    backgroundImage: `url(${BlackPattern})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  }

  return (
    <>
      {userIsApproved ? (
        <div id="savvly-dashboard">
          <Router>
            {maintenanceMode ? (
              <MaintenanceLayout />
            ) : (
              <div
                className={`is-flex is-justify-content-space-between is-mobile is-mobile-navbar ${showSideBar && "is-desktop"}`}>
                {showSideBar && (
                  <BackgroundImage
                    style={backgroundStyles}
                    className="p-0 m-0 is-2 sidebar is-flex is-flex-direction-column is-justify-content-space-between">
                    <Sidebar />
                  </BackgroundImage>
                )}
                <div className={`p-0 ${showSideBar ? "is-10" : "is-12 is-fullwidth"}`}>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <SidebarPage>
                          <Dashboard />
                        </SidebarPage>
                      }
                    />
                    <Route
                      path="/contracts"
                      element={
                        <SidebarPage>
                          <Contracts />
                        </SidebarPage>
                      }
                    />
                    <Route
                      path="/newcontract"
                      element={
                        <SidebarPage value={false}>
                          <ContractForm />
                        </SidebarPage>
                      }
                    />
                    <Route
                      path="/editContract/:id"
                      element={
                        <SidebarPage value={false}>
                          <ContractForm />
                        </SidebarPage>
                      }
                    />
                    <Route
                      path="/estimator"
                      element={
                        <SidebarPage>
                          <Estimator />
                        </SidebarPage>
                      }
                    />
                    <Route
                      path="/documentation"
                      element={
                        <SidebarPage>
                          <Documentation />
                        </SidebarPage>
                      }
                    />
                    <Route
                      path="/faq"
                      element={
                        <SidebarPage>
                          <FAQ />
                        </SidebarPage>
                      }
                    />
                    <Route
                      path="*"
                      element={
                        <SidebarPage value={false}>
                          <PageNotFound />
                        </SidebarPage>
                      }
                    />
                  </Routes>
                </div>
              </div>
            )}
          </Router>
        </div>
      ) : (
        <PendingApproval />
      )}
    </>
  )
}

export default PageLayout
