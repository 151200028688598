import { ClientAgeGender, ReturnPercentage, PayoutOption, PayoutAge, InvestmentAmountSimulation } from "@components/estimator"
import { RETURN_INVESTMENT_GRAPH_PAGE, LUMP_SUM_AND_MULTIPLE_PAYOUT_GRAPH_PAGE } from "@utils"

export const EstimatorControls = ({
  gender,
  setGender,
  payoutAge,
  setPayoutAge,
  currentAge,
  setCurrentAge,
  investment,
  setInvestment,
  fundingAmount,
  setFundingAmount,
  averageReturn,
  setAverageReturn,
  pageId
}) => {
  return (
    <div className="column is-full">
      <div className="box">
        <div className="columns is-multiline">
          <div className="column is-flex is-flex-direction-column">
            <h2 className="is-size-6 has-text-weight-semibold has-text-centered mb-5">CLIENT AGE & GENDER</h2>
            <ClientAgeGender
              gender={gender}
              setGender={setGender}
              currentAge={currentAge}
              setCurrentAge={setCurrentAge}
              payoutAge={payoutAge}
              setPayoutAge={setPayoutAge}
              pageId={pageId}
            />
            {pageId === LUMP_SUM_AND_MULTIPLE_PAYOUT_GRAPH_PAGE && (
              <PayoutAge gender={gender} currentAge={currentAge} payoutAge={payoutAge} setPayoutAge={setPayoutAge} />
            )}
          </div>
          <div className="column is-flex is-flex-direction-column is-align-items-center">
            {pageId === RETURN_INVESTMENT_GRAPH_PAGE ? (
              <>
                <h2 className="is-size-6 has-text-weight-semibold has-text-centered mb-5">AVERAGE MARKET RETURN</h2>
                <ReturnPercentage averageReturn={averageReturn} setAverageReturn={setAverageReturn} />
              </>
            ) : (
              <>
                <h2 className="is-size-6 has-text-weight-semibold has-text-centered mb-5">PAYOUT OPTIONS</h2>
                <PayoutOption setInvestment={setInvestment} investment={investment} />
              </>
            )}
          </div>
          <div className="column is-flex is-flex-direction-column">
            <h2 className="is-size-6 has-text-weight-semibold has-text-centered mb-5">INVESTMENT AMOUNT</h2>
            <InvestmentAmountSimulation fundingAmount={fundingAmount} setFundingAmount={setFundingAmount} />
            {pageId === LUMP_SUM_AND_MULTIPLE_PAYOUT_GRAPH_PAGE && (
              <ReturnPercentage averageReturn={averageReturn} setAverageReturn={setAverageReturn} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
