import React, { Fragment, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { FaSadTear, FaSort, FaSortUp, FaSortDown } from "react-icons/fa"

function SortedContractsTable({ currentContracts, columnSettings, contractKeyValue, error, contractsPerPage }) {
  const [sortOrder, setSortOrder] = useState(null)
  const [sortBy, setSortBy] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    setCurrentPage(1)
  }, [contractsPerPage])

  const handleSortColumnClick = (label) => {
    if (sortBy && label !== sortBy) {
      setSortOrder("asc")
      setSortBy(label)
    } else {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc")
      setSortBy(label)
    }
  }

  const getSortIcons = (label) => {
    if (label !== sortBy || sortOrder === null) {
      return (
        <div>
          <FaSort data-cy={`arrows-${label}`} />
        </div>
      )
    } else if (sortOrder === "asc") {
      return (
        <div>
          <FaSortUp data-cy={`arrow-up-${label}`} />
        </div>
      )
    } else if (sortOrder === "desc") {
      return (
        <div>
          <FaSortDown data-cy={`arrow-down-${label}`} />
        </div>
      )
    }
  }

  const updatedColumnSettings = columnSettings.map((column) => {
    if (!column.sortColumn) {
      return column
    }

    return {
      ...column,
      header: () => (
        <th className="is-clickable is-hoverabled" onClick={() => handleSortColumnClick(column.label)}>
          <div className="is-flex is-align-items-flex-center is-justify-content-center" data-cy="table-header">
            <span className="mr-1">{getSortIcons(column.label)}</span>
            {column.label}
          </div>
        </th>
      )
    }
  })

  let sortedData = currentContracts

  if (sortOrder && sortBy) {
    const { sortColumn } = updatedColumnSettings.find((column) => column.label === sortBy)
    sortedData = [...currentContracts].sort((a, b) => {
      const valueA = sortColumn(a)
      const valueB = sortColumn(b)
      const reverseOrder = sortOrder === "asc" ? 1 : -1

      if (typeof valueA === "string") {
        return valueA.localeCompare(valueB) * reverseOrder
      } else {
        return (valueA - valueB) * reverseOrder
      }
    })
  }

  const renderHeader = (column) => {
    if (column.header) {
      return <Fragment key={column.render}>{column.header()}</Fragment>
    }

    return <th key={column.render}>{column.label}</th>
  }

  const renderTableCell = (rowValue, column) => (
    <td
      className="p-2 is-align-items-center"
      key={column.render}
      data-cy={`table-cell-${column.label}`}
      data-label={column.label}>
      {column.render(rowValue)}
    </td>
  )

  const renderErrorRow = (
    <tr>
      <td colSpan={100}>
        <div className="notification is-danger">Error loading contracts...</div>
      </td>
    </tr>
  )

  const renderEmptyRow = (
    <tr>
      <td colSpan={100}>
        <div className="is-flex is-justify-content-center is-align-items-center">
          <FaSadTear className="no-contracts-icon mr-1" />
          <p> No contracts found…</p>
        </div>
      </td>
    </tr>
  )

  const tableHeaders = updatedColumnSettings.map((column) => renderHeader(column))

  if (contractsPerPage === -1) {
    currentContracts = sortedData
  } else {
    const indexOfLastContract = currentPage * contractsPerPage
    const indexOfFirstContract = indexOfLastContract - contractsPerPage
    currentContracts = sortedData.slice(indexOfFirstContract, indexOfLastContract)
  }

  const tableBody = currentContracts.map((rowValue) => (
    <Fragment key={contractKeyValue(rowValue)}>
      <tr className="border-b" data-cy="table-row">
        {updatedColumnSettings.map((column) => renderTableCell(rowValue, column))}
      </tr>
    </Fragment>
  ))

  return (
    <>
      <div className="header is-flex is-justify-content-space-between is-align-items-center">
        <h1 className="title has-text-primary">Clients + Contracts</h1>
        <Link to="/newcontract" className="button is-primary" data-cy="start-new-contract">
          Start New Contract
        </Link>
      </div>
      <hr />
      <div className="b-table">
        <div className="table-wrapper has-mobile-cards">
          <div className="table-responsive">
            <table
              id="contracts-table"
              className="table is-fullwidth is-striped is-hoverable is-fullwidth"
              data-cy="contracts-table">
              <thead>
                <tr className="border-b-2">{tableHeaders}</tr>
              </thead>
              <tbody data-cy="table-body">
                {error ? renderErrorRow : currentContracts.length === 0 ? renderEmptyRow : tableBody}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default SortedContractsTable
