export const PayoutAgesList = (gender) => {
  const maximumAge = 95
  const minimumFemaleAge = 75
  const minimumMaleAge = 70

  if (gender === "M") {
    return [...Array(maximumAge - minimumMaleAge + 1).keys()].map((x) => x + minimumMaleAge)
  }
  return [...Array(maximumAge - minimumFemaleAge + 1).keys()].map((x) => x + minimumFemaleAge)
}

export const DEFAULT_FEMALE_PAYOUT_AGES = [80, 85, 90, 95]
export const DEFAULT_MALE_PAYOUT_AGES = [75, 80, 85, 90]
