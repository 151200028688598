import React, { useState } from "react"
import { Pagination, SortedContractsTable } from "@components/contracts"
import { contractKeyValue, createColumnSettings, DEFAULT_CONTRACTS_PER_PAGE } from "@utils"

const ContractsTable = ({ loading, error, contracts, setSelectedContract, setCurrentView }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [contractsPerPage, setContractsPerPage] = useState(DEFAULT_CONTRACTS_PER_PAGE)

  const openContractPreview = (contract) => {
    setSelectedContract(contract)
    setCurrentView("contractPreview")
  }

  const openClientInformation = (contract) => {
    setSelectedContract(contract)
    setCurrentView("clientInformation")
  }

  if (!contracts) return null

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleChangeContractsPerPage = (contractsPerPage) => {
    setCurrentPage(1)
    setContractsPerPage(contractsPerPage)
  }

  const columnSettings = createColumnSettings(openContractPreview, openClientInformation)

  let currentContracts
  if (contractsPerPage === -1) {
    currentContracts = contracts
  } else {
    const lastContractIndex = currentPage * parseInt(contractsPerPage)
    const firstContractIndex = lastContractIndex - parseInt(contractsPerPage)
    currentContracts = contracts.slice(firstContractIndex, lastContractIndex)
  }

  return (
    <>
      <div>
        <SortedContractsTable
          currentContracts={currentContracts}
          columnSettings={columnSettings}
          contractKeyValue={contractKeyValue}
          error={error}
          contractsPerPage={contractsPerPage}
        />
      </div>
      {currentContracts?.length > 0 && (
        <Pagination
          totalContracts={contracts.length}
          contractsPerPage={contractsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onContractsPerPageChange={handleChangeContractsPerPage}
        />
      )}
    </>
  )
}

export default ContractsTable
