import { DateTime } from "luxon"
import { SOURCE_OF_WEALTH } from "@config"

export const formatName = (text, textLength) => {
  const fileName = text.slice(0, 20)
  if (textLength > 20) {
    return `${fileName}...`
  }

  return fileName
}
export const formatTickLabels = (value, index, ticks) => {
  return value > 999999 ? `${(value / 1000000).toFixed(0)}M` : `${(value / 1000).toFixed(0)}K`
}

export const formatNumber = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const nFormatter = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G"
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M"
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K"
  }
  return num?.toFixed(1).replace(/\.0$/, "")
}

export const createCypressSlug = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "")
}

export const formatDate = (date) => {
  const formattedDate = DateTime.fromISO(date)

  if (formattedDate.isValid) return formattedDate.toLocaleString()
}

export const formatNumberAsCurrency = (funding) => {
  let value = typeof funding === "number" ? funding.toString() : funding
  if (!isNaN(parseFloat(value))) {
    value = Math.floor(parseFloat(value.replace(/,/g, "")))
  }
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
}

export const formatValueAsNumber = (funding) => {
  let value = typeof funding === "number" ? funding.toString() : funding
  if (!isNaN(parseFloat(value))) {
    value = Math.floor(parseFloat(value.replace(/,/g, "")))
  }
  return value.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
}

export const removeUnnecessaryText = (text, wordToReplace) => text.replace(new RegExp(wordToReplace, "g"), "")

export const formatFullDate = (date) => {
  const newDate = DateTime.fromISO(date)
  if (newDate.isValid) return newDate.toLocaleString(DateTime.DATE_FULL)
}

export const getOrdinalIndicator = (number) => {
  const j = number % 10
  const k = number % 100
  if (j === 1 && k !== 11) {
    return "st"
  }
  if (j === 2 && k !== 12) {
    return "nd"
  }
  if (j === 3 && k !== 13) {
    return "rd"
  }
  return "th"
}

export const formatValueAsFloat = (value) => {
  return parseFloat(value.toString().replaceAll(",", ""))
}

export const getSourceOfWealthValue = (key) => SOURCE_OF_WEALTH.find((i) => i.value === key)?.title

export const removeCommasFromInput = (dataForm) => {
  const cleanedData = {}

  Object.keys(dataForm).forEach((key) => {
    let value = dataForm[key]

    if (typeof value === "string") {
      value = value.replace(/,/g, "")
    }

    const numberValue = parseFloat(value)
    cleanedData[key] = isNaN(numberValue) ? value : numberValue
  })

  return cleanedData
}
