import { getOrdinalIndicator, nFormatter, formatNumberAsCurrency } from "@utils"
import {
  ESTIMATOR_SAVVLY_BONUS,
  ESTIMATOR_INDEX_FUNDS_ALONE,
  ESTIMATOR_INVESTMENT,
  ESTIMATOR_BACKGROUND_COLOR,
  PUBLIC_ESTIMATOR_SAVVLY_DISTRIBUTION,
  PUBLIC_ESTIMATOR_MARKET_DISTRIBUTION,
  PUBLIC_ESTIMATOR_LABELS,
  ESTIMATOR_INSTALLMENT_ENDPOINT,
  ESTIMATOR_MULTIPLE_PAYOUTS_ENDPOINT,
  PIE_CHART_INVESTMENT_LABEL,
  PIE_CHART_MONTHLY_INVESTMENT_LABEL,
  getDescriptionLabel,
  getLabelNames,
  getPieChartTitle
} from "@config"
import { CURRENT_RETIREMENT_FORECAST, RECOMMENDED_AMOUNT_FOR_RETIREMENT } from "./colors"

const transformData = (data) => {
  if (!data) {
    throw new Error("Chart data is missing or undefined")
  }

  if (!Array.isArray(data) || data.length === 0) {
    throw new Error("Chart data is not an array or is empty")
  }

  return data.map((item) => ({
    investment: item.investment || 0,
    indexFundAlone: item.index_fund_alone || 0,
    savvlyUpside: item.savvly_upside || 0,
    payoutAge: item.payout_age || 0,
    total: item.total || 0,
    marketDistribution: item.market_distribution || 0,
    savvlyDistribution: item.savvly_distribution || 0,
    monthlyInstallment: item.monthly_installment || 0,
    installmentYears: item.installment_years || 0,
    [item.investment]: item.investment,
    [item.index_fund_alone]: item.withoutRounded,
    [item.savvly_upside]: item.total
  }))
}

export const setPieChartData = (data, estimatorEndpoint) => {
  const multiplePayoutsData = transformData(data)

  const estimatorData = multiplePayoutsData.map((item) => ({
    name: item.payoutAge,
    y: item.total,
    color: ESTIMATOR_SAVVLY_BONUS
  }))

  const isMultiplePayoutsEndpoint = estimatorEndpoint === ESTIMATOR_MULTIPLE_PAYOUTS_ENDPOINT
  const isInstallmentEndpoint = estimatorEndpoint === ESTIMATOR_INSTALLMENT_ENDPOINT

  const yValue = isMultiplePayoutsEndpoint
    ? multiplePayoutsData.reduce((acc, item) => acc + item.investment, 0)
    : isInstallmentEndpoint && multiplePayoutsData[0].monthlyInstallment * multiplePayoutsData[0].installmentYears * 12

  const labelName = isMultiplePayoutsEndpoint
    ? PIE_CHART_INVESTMENT_LABEL
    : isInstallmentEndpoint && PIE_CHART_MONTHLY_INVESTMENT_LABEL

  const investmentData = {
    name: labelName,
    y: yValue,
    color: ESTIMATOR_INDEX_FUNDS_ALONE
  }

  const total = multiplePayoutsData.map((item) => item.total).reduce((acc, val) => acc + val, 0)

  return {
    opacity: 10,
    caption: {
      text: getDescriptionLabel,
      align: "center",
      useHtml: true,
      style: {
        color: PUBLIC_ESTIMATOR_LABELS,
        fontSize: "14px"
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      plotBackgroundColor: ESTIMATOR_BACKGROUND_COLOR,
      backgroundColor: ESTIMATOR_BACKGROUND_COLOR
    },
    title: {
      text: getPieChartTitle(total),
      align: "center",
      style: {
        color: PUBLIC_ESTIMATOR_LABELS,
        fontSize: "1.25rem",
        backgroundColor: ESTIMATOR_BACKGROUND_COLOR
      }
    },
    accessibility: {
      enabled: false
    },
    tooltip: {
      headerFormat: "",

      padding: 15,
      style: {
        fontSize: "16px",
        fontWeight: "bold"
      },
      pointFormatter: function () {
        const formattedValue = `$${nFormatter(this.y)}`
        return `${formattedValue}`
      }
    },
    plotOptions: {
      pie: {
        states: {
          inactive: {
            opacity: 0.8
          }
        },
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          crop: true,
          distance: 15,
          style: {
            color: PUBLIC_ESTIMATOR_LABELS,
            textOutline: "none",
            fontSize: "14px"
          },
          formatter: function () {
            const idx = this.point.index + 1
            return this.point.name !== labelName ? getLabelNames(idx, this.point.name) : labelName
          }
        }
      }
    },
    series: [
      {
        colorByPoint: true,
        type: "pie",
        data: [...estimatorData, investmentData]
      }
    ]
  }
}

export const buildRetirementSavingsChartConfig = (data) => {
  return {
    accessibility: {
      enabled: false
    },

    credits: {
      enabled: false
    },

    chart: {
      type: "area",
      style: {
        fontFamily: "Poppins"
      }
    },

    title: {
      text: ""
    },

    xAxis: {
      allowDecimals: true,
      gridLineWidth: 0,
      title: {
        text: "Age"
      },
      floor: data?.[0]?.current_age,
      ceiling: data?.[data?.length - 1]?.current_age,
      showLastLabel: true
    },

    yAxis: {
      allowDecimals: true,
      showFirstLabel: true,
      gridLineWidth: 0,
      lineWidth: 1,
      title: {
        text: ""
      },
      tickAmount: 5,
      labels: {
        formatter: function () {
          return `$ ${nFormatter(this.value)}`
        }
      }
    },

    legend: {
      symbolRadius: 0,
      align: "top",
      verticalAlign: "top",
      layout: "vertical",
      x: 60,
      y: 0
    },

    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        const formattedValue = `
        <div style="font-family: Poppins">
        <span style="color:gray;">Age ${this.x}</span>
        <hr style="padding: 0px; margin:5px 0px;"/>
        <div style="display: flex; justify-content: space-between; margin-bottom:2px;">
          <span style="color: gray; margin-right:10px;">
            <b style="color:${this.points[0].color}">\u25CF</b>
            What you will need:
          </span>
          <b style="color: #000"> ${formatNumberAsCurrency(this.points[0].y)}</b>
        </div>
        <div style="display: flex; justify-content: space-between; margin-bottom:2px;">
          <span style="color: gray; margin-right:10px;">
            <b style="color:${this.points[1].color}">\u25CF</b>
            What you will have:
          </span>
          <b style="color: #000"> ${formatNumberAsCurrency(this.points[1].y)}</b>
        </div>
        <hr style="padding: 0px; margin:5px 0px;"/>
        <div style="display: flex; justify-content: space-between; margin-bottom:2px;">
          <span style="color: gray">
            Difference:
          </span>
          <b style="color: #006545"> ${formatNumberAsCurrency(this.points[0].point.difference)}</b>
        </div>        
      </div>
     `
        return formattedValue
      }
    },

    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true
            }
          }
        }
      }
    },
    series: [
      {
        name: "Recommended Amount for Retirement",
        legendSymbol: "rectangle",
        fillColor: RECOMMENDED_AMOUNT_FOR_RETIREMENT,
        color: RECOMMENDED_AMOUNT_FOR_RETIREMENT,
        data: data.map((entry) => {
          return {
            x: entry.current_age,
            y: entry.what_you_will_need,
            difference: entry.difference
          }
        })
      },
      {
        name: "Current Retirement Forecast",
        legendSymbol: "rectangle",
        fillColor: CURRENT_RETIREMENT_FORECAST,
        color: CURRENT_RETIREMENT_FORECAST,
        data: data.map((entry) => {
          return {
            x: entry.current_age,
            y: entry.what_you_will_have,
            difference: entry.difference
          }
        })
      }
    ]
  }
}

export const setPublicEstimatorData = (data) => {
  const multiplePayoutsData = transformData(data)

  const total = multiplePayoutsData.map((item) => item.total)
  const savvlyDistribution = multiplePayoutsData.map((item) => item.savvlyDistribution)
  const marketDistribution = multiplePayoutsData.map((item) => item.marketDistribution)
  const labels = multiplePayoutsData.map((item, index) => {
    const payoutNumber = index + 1
    const ordinalIndicator = getOrdinalIndicator(payoutNumber)
    const payoutAge = item.payoutAge

    return `${payoutNumber}${ordinalIndicator} payout at ${payoutAge}`
  })

  return {
    labels,
    datasets: [
      {
        label: "Market return",
        data: marketDistribution,
        backgroundColor: `${PUBLIC_ESTIMATOR_MARKET_DISTRIBUTION}`,
        barPercentage: 0.5,
        datalabels: {
          labels: {
            marketDistribution: {
              formatter: () => {
                return ""
              },
              anchor: "end",
              align: "end",
              offset: 2
            }
          }
        }
      },
      {
        label: "Savvly bonus for living longer",
        data: savvlyDistribution,
        backgroundColor: `${PUBLIC_ESTIMATOR_SAVVLY_DISTRIBUTION}`,
        barPercentage: 0.5,
        datalabels: {
          labels: {
            savvlyDistribution: {
              formatter: () => {
                return ""
              },
              anchor: "end",
              align: "end",
              offset: 2
            },
            totalBar: {
              formatter: (value, index) => {
                const { dataIndex } = index
                if (Array.isArray(total) && total.length > 0) {
                  return `$${nFormatter(total[dataIndex])}`
                }
                return ""
              },
              anchor: "end",
              align: "end",
              offset: 2
            }
          }
        }
      }
    ]
  }
}

export const setMultiplePayoutsData = (data) => {
  const multiplePayoutsData = transformData(data)
  const investments = multiplePayoutsData.map((item) => item.investment)
  const indexFundAlone = multiplePayoutsData.map((item) => item.indexFundAlone)
  const savvlyUpside = multiplePayoutsData.map((item) => item.savvlyUpside)
  const labels = multiplePayoutsData.map((item) => item.payoutAge)

  return {
    labels,
    datasets: [
      {
        label: "Investment",
        data: investments,
        backgroundColor: `${ESTIMATOR_INVESTMENT}`,
        barPercentage: 0.5,
        datalabels: {
          labels: {
            value: {
              formatter: (value) => {
                return `$${nFormatter(value)}`
              },
              anchor: "end",
              align: "left",
              clamping: true,
              offset: (ctx) => {
                return ctx.chart.chartArea.width * 0.05
              }
            }
          }
        }
      },
      {
        label: "Index Funds Alone",
        data: indexFundAlone,
        backgroundColor: `${ESTIMATOR_INDEX_FUNDS_ALONE}`,
        barPercentage: 0.5,
        datalabels: {
          labels: {
            value: {
              formatter: (value) => {
                return `$${nFormatter(value)}`
              },
              anchor: "center",
              align: "left",
              offset: (ctx) => {
                return ctx.chart.chartArea.width * 0.05
              }
            }
          }
        }
      },
      {
        label: "Savvly Bonus",
        data: savvlyUpside,
        backgroundColor: `${ESTIMATOR_SAVVLY_BONUS}`,
        barPercentage: 0.5,
        datalabels: {
          labels: {
            totalBar: {
              formatter: (value) => {
                if (Array.isArray(data) && data.length > 0) {
                  const obj = multiplePayoutsData.find((data) => Object.values(data).indexOf(value) !== -1)
                  return `$${nFormatter(obj[value])}`
                }
                return ""
              },
              anchor: "end",
              align: "end",
              offset: 2
            },
            value: {
              formatter: (value) => {
                return `$${nFormatter(value)}`
              },
              anchor: "center",
              align: "left",
              offset: (ctx) => {
                return ctx.chart.chartArea.width * 0.05
              }
            }
          }
        }
      }
    ]
  }
}
