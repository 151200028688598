import React, { useEffect, useState } from "react"
import { createCypressSlug } from "@utils"
export const ToggleButton = ({
  title,
  sideA,
  sideB,
  labelA,
  labelB,
  setState,
  iconActiveA,
  iconInactiveA,
  iconActiveB,
  iconInactiveB,
  disabled,
  value,
  stylesA,
  stylesB
}) => {
  sideA = sideA || labelA
  sideB = sideB || labelB

  const defaultValue = value && value.trim() !== "" ? value : sideA
  const [isChecked, setIsChecked] = useState(defaultValue)

  useEffect(() => {
    setIsChecked(value)
  }, [value])

  const handleClick = (e) => {
    e.preventDefault()
    setIsChecked(e.target.id)
    setState(e.target.id)
  }

  return (
    <>
      {title && <label>{title}</label>}
      <div className={`is-clipped is-size-7 is-flex tabs is-toggle is-flex is-justify-content-center ${disabled && "disabled"}`}>
        <ul className="is-flex is-align-items-center ul-fit-content">
          <li className={`${isChecked === sideA ? "is-active" : null}`} onClick={handleClick} id={sideA}>
            <span onClick={handleClick} id={sideA} data-cy={createCypressSlug(`contract-input-${title || ""}-${labelA}`)}>
              <a id={sideA} className={`is-flex is-align-items-center has-text-weight-bold py-2 px-4 ${stylesA}`}>
                {iconActiveA && (
                  <img
                    src={isChecked === sideA ? iconActiveA : iconInactiveA}
                    alt={sideA}
                    className="px-2"
                    onClick={handleClick}
                    id={sideA}
                  />
                )}
                {labelA}
              </a>
            </span>
          </li>
          <li className={isChecked === sideB ? "is-active" : null} onClick={(e) => handleClick(e)} id={sideB}>
            <span onClick={handleClick} id={sideB} data-cy={createCypressSlug(`contract-input-${title || ""}-${labelB}`)}>
              <a id={sideB} className={`is-flex is-align-items-center has-text-weight-bold py-2 px-4 ${stylesB}`}>
                {iconActiveB && (
                  <img
                    src={isChecked === sideB ? iconActiveB : iconInactiveB}
                    alt={sideB}
                    className="pr-2"
                    onClick={handleClick}
                    id={sideB}
                  />
                )}
                {labelB}
              </a>
            </span>
          </li>
        </ul>
      </div>
    </>
  )
}
