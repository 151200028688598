import React, { useState } from "react"
import { PayoutAgeModal } from "@components/estimator"

export const PayoutAge = ({ gender, currentAge, payoutAge, setPayoutAge }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <section>
      <div className="is-size-7 gender-select p-3 mt-5">
        <div className="is-flex is-justify-content-space-between pl-2 has-text-weight-bold">
          <span data-cy="control-payout-age">Lump Sum Payout Age: {payoutAge}</span>
          <span
            className="has-text-primary pl-2 is-underlined is-clickable"
            data-cy="edit-payout-age-btn"
            onClick={() => {
              toggleModal()
            }}>
            Edit
          </span>
        </div>
      </div>
      {isModalOpen && (
        <PayoutAgeModal
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          gender={gender}
          currentAge={currentAge}
          setPayoutAge={setPayoutAge}
          payoutAge={payoutAge}
        />
      )}
    </section>
  )
}
