import * as Sentry from "@sentry/react"
import { useNavigate, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { FaCheckDouble, FaClipboardCheck } from "react-icons/fa"
import { PdfPreview } from "@components/pdf"
import { UploadDocumentModal, PdfCover } from "@components/contractForm"
import { PdfErrorMessage } from "@components/PdfErrorMessage"
import {
  CONTRACT_NOT_SENT,
  CONTRACT_SAVED_AND_SENT,
  CONTRACT_SENT,
  promiseAlertMessage,
  SAVED_CONTRACT,
  SAVE_CONTRACT_AS_DRAFT,
  SAVING_CONTRACT_AS_DRAFT,
  SENDING_CONTRACT_BY_EMAIL,
  UNABLE_TO_SEND_CONTRACT,
  EDITING_CONTRACT_MESSAGE,
  CONTRACT_EDITED_AND_SAVED_MESSAGE,
  UPDATED_CONTRACT,
  showAlertMessage,
  AlertType
} from "@components/notification"
import {
  BAD_RESPONSE_MESSAGE,
  GENERAL_ERROR_MESSAGE,
  ERROR_SAVING_CONTRACT,
  ERROR_SENDING_CONTRACT,
  FAILED_CREATING_CONTRACT,
  FAILED_SAVING_CONTRACT,
  FAILED_SENDING_CONTRACT,
  SERVER_ERROR_MESSAGE,
  VALIDATION_ERROR_MESSAGE,
  ERROR_EDITING_CONTRACT,
  REACT_APP_API_V2_URL
} from "@config"
import { useAppContext } from "@context"
import { useQuery, useUploadFile } from "@hooks"
import { useContract } from "@api"
import { IconNumberOne, IconNumberTwo, IconNumberThree } from "@assets"
import { convertContractPayload } from "@utils"

const SendAgreement = ({ data, files, setFiles }) => {
  const [pdfFile, setPdfFile] = useState()
  const [previewPdf, setPreviewPdf] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [clientId, setClientId] = useState("")
  const [pdfError, setPdfError] = useState(null)

  const { advisorId } = useAppContext()
  const { uploadFile } = useUploadFile()
  const { query } = useQuery()
  const { id } = useParams()
  const { editContract } = useContract()

  const navigate = useNavigate()
  const clientName = `${data.firstName} ${data.lastName}`
  const validatedData = convertContractPayload(data, advisorId)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleEditContract = async () => {
    promiseAlertMessage(EDITING_CONTRACT_MESSAGE)
    try {
      const response = await editContract(id, data, advisorId)

      if (response?.success) {
        showAlertMessage(AlertType.SUCCESS, UPDATED_CONTRACT, CONTRACT_EDITED_AND_SAVED_MESSAGE)
        navigate("/contracts")
      } else {
        showAlertMessage(AlertType.ERROR, ERROR_SAVING_CONTRACT, ERROR_EDITING_CONTRACT)
      }
    } catch (error) {
      showAlertMessage(AlertType.ERROR, ERROR_SAVING_CONTRACT, ERROR_EDITING_CONTRACT)
    }
  }

  const getErrorMessage = (error) => {
    if (error.response && error.response.data && error.response.data.errors) {
      return error.response.data.errors.map((error) => `${error.field}: ${error.message}`).join("; ")
    }
    return error.message || FAILED_CREATING_CONTRACT
  }

  const handleError = (validationErrors) => {
    const errors = validationErrors?.response?.data?.errors
    if (errors?.length > 0) {
      showAlertMessage(AlertType.ERROR, VALIDATION_ERROR_MESSAGE, errors[0].message)
      return true
    }
    return false
  }

  useEffect(() => {
    const generatePdf = async () => {
      try {
        const { response, error } = await query({
          path: `${REACT_APP_API_V2_URL}/subscription-agreements/preview`,
          method: "POST",
          data: validatedData
        })
        if (error && handleError(error)) {
          setPdfError(SERVER_ERROR_MESSAGE)
        } else {
          if (response && response?.data) {
            setPdfFile(`data:application/pdf;base64,${response?.data}`)
          } else {
            throw new Error(BAD_RESPONSE_MESSAGE)
          }
        }
      } catch (error) {
        setPdfError(GENERAL_ERROR_MESSAGE)
        Sentry.captureException(error, {
          tags: {
            section: "Contract creation"
          },
          extra: {
            message: "Cannot preview contract",
            errors: getErrorMessage(error)
          }
        })
      }
    }

    if (previewPdf) generatePdf()
  }, [previewPdf])

  const saveContract = async () => {
    promiseAlertMessage(SAVING_CONTRACT_AS_DRAFT)

    try {
      const { response, error } = await query({
        method: "POST",
        path: `${REACT_APP_API_V2_URL}/subscription-agreements/create-with-client`,
        data: validatedData
      })
      if (error) {
        if (handleError(error)) {
          return
        } else {
          showAlertMessage(AlertType.ERROR, FAILED_CREATING_CONTRACT, GENERAL_ERROR_MESSAGE)
        }
      }

      if (response) {
        showAlertMessage(AlertType.SUCCESS, SAVED_CONTRACT, SAVE_CONTRACT_AS_DRAFT)
        return response
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error)

      Sentry.captureException(error, {
        tags: {
          section: "Contract Creation"
        },
        extra: {
          message: "Cannot add potential client",
          errors: errorMessage
        }
      })
      showAlertMessage(AlertType.ERROR, ERROR_SAVING_CONTRACT, FAILED_SAVING_CONTRACT)

      return false
    }
  }

  const sendContract = async () => {
    promiseAlertMessage(SENDING_CONTRACT_BY_EMAIL)
    try {
      const { statusCode, error, response } = await query({
        path: `${REACT_APP_API_V2_URL}/subscription-agreements/create-with-client?sendAgreement=true`,
        method: "POST",
        data: validatedData
      })
      if (error) {
        if (handleError(error)) {
          return
        } else {
          showAlertMessage(AlertType.ERROR, ERROR_SENDING_CONTRACT, FAILED_SENDING_CONTRACT)
        }
      }

      navigate("/contracts")
      if (statusCode === 202) {
        // Status code 202 means the agreement was created on the backend in draft state but failed to send via docuSign
        showAlertMessage(AlertType.WARNING, CONTRACT_NOT_SENT, UNABLE_TO_SEND_CONTRACT)
      } else {
        showAlertMessage(AlertType.SUCCESS, CONTRACT_SENT, CONTRACT_SAVED_AND_SENT)
      }

      if (response) {
        return response
      }
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          section: "Contract creation"
        },
        extra: {
          message: "Cannot send contract"
        }
      })
      showAlertMessage(AlertType.ERROR, ERROR_SENDING_CONTRACT, FAILED_SENDING_CONTRACT)
      return false
    }
  }

  const uploadDocuments = async (clientId) => {
    for (const { file } of files) {
      const fileResponse = await uploadFile(file, clientId)
      if (!fileResponse) {
        toggleModal()
        return
      }
    }
    navigate("/contracts")
  }

  const saveAsDraft = async () => {
    const contract = await saveContract()
    if (!contract) return
    setClientId(contract?.data?.primaryClientId)
    await uploadDocuments(contract?.data?.primaryClientId)
  }

  const generateContract = async () => {
    const contract = await sendContract()
    if (!contract) return
    const { primaryClientId } = contract?.data

    setClientId(primaryClientId)
    await uploadDocuments(primaryClientId)
  }

  return (
    <>
      {!previewPdf ? (
        <>
          <h1 className="is-size-3 mb-4 is-mobile-centered has-text-weight-bold has-text-primary">
            The agreement is ready to go. Review and send!
          </h1>
          <h2 className="is-size-4 has-text-weight-medium mb-4 has-text-grey mb-6">
            <FaCheckDouble className="has-text-primary" /> Review the prepared agreement
          </h2>
          <div className="columns is-12 is-justify-content-space-around">
            <div className="column is-6">
              <PdfCover setPreviewPdf={setPreviewPdf} clientName={clientName} />
            </div>
            <div className="column is-6">
              <p>
                <span className="has-text-black has-text-weight-bold is-size-5">
                  <FaClipboardCheck className="has-text-primary" />
                  Once you click send,
                </span>
                <br />
                this agreement will be sent via DocuSign
              </p>
              <br />
              <ul>
                <li className="is-flex is-align-items-center mt-2 mb-3">
                  <img src={IconNumberOne} alt="icon number one" className="number-list" />
                  <span>Your client (and spouse if applicable) will receive the DocuSign Agreement</span>
                </li>
                <li className="is-flex is-align-items-center mt-2 mb-3">
                  <img src={IconNumberTwo} alt="icon number two" className="number-list" />
                  <span>After signature, the client will be visible in your Savvly dashboard.</span>
                </li>
                <li className="is-flex is-align-items-center mt-2 mb-3">
                  <img src={IconNumberThree} alt="icon number three" className="number-list" />
                  <span>The agreement will be fully executed once funds are received by Savvly.</span>
                </li>
              </ul>
              {!id ? (
                <div className="buttons">
                  <button onClick={generateContract} className="button is-primary mt-4" data-cy="send-agreement">
                    Send Agreement
                  </button>
                  <button onClick={saveAsDraft} className="button is-primary is-outlined mt-4" data-cy="save-draft">
                    Save as Draft
                  </button>
                </div>
              ) : (
                <div className="buttons">
                  <button onClick={handleEditContract} className="button is-primary mt-4" data-cy="save-information">
                    Update Agreement
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="is-flex is-justify-content-space-between">
            <button className="button is-primary mb-3 is-outlined" onClick={() => setPreviewPdf(false)}>
              Close Preview
            </button>
            <button onClick={generateContract} className="button is-primary mb-3" data-cy="send-subscription-button">
              Send Subscription Agreement
            </button>
          </div>
          {pdfError ? <PdfErrorMessage error={pdfError} /> : <PdfPreview pdf={pdfFile} />}
        </div>
      )}
      <UploadDocumentModal
        toggleModal={toggleModal}
        isModalOpen={isModalOpen}
        files={files}
        setFiles={setFiles}
        clientId={clientId}
      />
    </>
  )
}

export default SendAgreement
