import { createCypressSlug } from "@utils"

const ContractTextInput = ({ inputChanged, data, field, title, errors, min, max, mask = null, isNumber, placeholder }) => {
  const applyMask = (value, mask) => {
    switch (mask) {
      case "passport":
        value = value.replace(/[&/#,+()$~%.'":*?<>{}]/g, "")
        value = value.replace(/[^a-zA-Z0-9]/g, "")
        break
      case "ssn":
        value = value.replace(/\D/g, "")
        value = value.replace(/^(\d{3})/, "$1-")
        value = value.replace(/-(\d{2})/, "-$1-")
        value = value.replace(/(\d)-(\d{4}).*/, "$1-$2")
        break
      case "uscis":
        value = value.replace(/\D/g, "")
        value = value.replace(/^(\d{3})/, "$1-")
        value = value.replace(/-(\d{3})/, "-$1-")
        value = value.replace(/(\d)-(\d{4}).*/, "$1-$2")
        break
      case "visa":
        value =
          value
            .replace(/\s/g, "")
            .match(/.{1,4}/g)
            ?.join(" ")
            .substr(0, 19) || ""
        break
      default:
        break
    }
    return value
  }

  const handleChange = (e) => {
    let { value } = e.target
    value = applyMask(value, mask)
    if (isNumber) {
      value = Math.max(parseInt(value), 0)
    }
    inputChanged(field, value)
  }

  const errs = errors[field]

  return (
    <div className="field" data-cy={createCypressSlug(`contract-field-${title}`)}>
      <label className="contractLabel">{title}</label>
      <div className="control">
        <input
          onChange={handleChange}
          value={data[field] ?? ""}
          className={"input " + (errs ? "is-danger" : "")}
          type={isNumber ? "number" : "text"}
          minLength={min}
          maxLength={max}
          placeholder={placeholder}
          data-cy={createCypressSlug(`contract-input-${title}`)}
        />
      </div>
      <span className="help is-danger">{errs}</span>
    </div>
  )
}

export default ContractTextInput
