import {
  formatValueAsNumber,
  applyMask,
  removeMask,
  IS_GREEN_CARD_YES,
  IS_GREEN_CARD_NO,
  IS_US_CITIZEN_NO,
  IS_US_CITIZEN_YES,
  COUNTRY,
  GENDER_FEMALE
} from "./"

export function convertEmptyStringToNull(obj) {
  for (const prop in obj) {
    if (obj[prop] === "") {
      obj[prop] = null
    }
    if (typeof obj[prop] === "object" && obj[prop] !== null) {
      convertEmptyStringToNull(obj[prop])
    }
  }
  return obj
}

export const convertContractPayload = (payload, advisorId) => {
  const data = {
    client: {
      firstName: payload.firstName,
      lastName: payload.lastName,
      middleName: payload.middleName,
      email: payload.email,
      phoneNumber: null,
      gender: payload.sex,
      address: {
        street1: payload.address,
        street2: payload.address2,
        city: payload.city,
        state: payload.state,
        zipCode: payload.zipCode,
        country: payload.country
      },
      type: payload.purchaserType,
      birthDate: payload.birthDate,
      maritalStatus: payload.isMarried,
      occupation: payload.occupation,
      sourceOfWealth: payload.sourceOfWealth,
      driversLicenseNumber: null,
      driversLicenseState: null,
      driversLicenseExpirationDate: null,
      citizenshipInfo: {
        ssn: removeMask(payload?.ssn, "ssn") ?? null,
        isUSCitizen: payload?.isUSCitizen === IS_US_CITIZEN_YES,
        isPermanentResident: payload?.isGreenCard === IS_GREEN_CARD_YES,
        greenCardNumber: payload?.isGreenCard === IS_GREEN_CARD_YES ? removeMask(payload.alienIdOrVisa, "uscis") ?? null : null,

        greenCardExpirationDate: payload?.isGreenCard === IS_GREEN_CARD_YES ? payload?.alienIdOrVisaExpiration : null,
        visaNumber: payload?.isGreenCard === IS_GREEN_CARD_NO ? removeMask(payload?.alienIdOrVisa, "visa") ?? null : null,
        visaExpirationDate: payload.isGreenCard === IS_GREEN_CARD_NO ? payload.alienIdOrVisaExpiration : null,
        passportNumber: payload.passportNumber,
        passportExpirationDate: payload.passportExpiration,
        passportCountry: payload.passportCountry
      },
      spouse: {
        firstName: payload.spouseFirstName,
        middleName: payload.spouseMiddleName,
        lastName: payload.spouseLastName,
        email: payload.spouseEmail,
        phoneNumber: null,
        gender: payload.spouseGender,
        birthDate: payload.spouseBirthDate,
        address: {
          street1: payload.address,
          street2: payload.address2,
          city: payload.city,
          state: payload.state,
          zipCode: payload.zipCode,
          country: payload.spouseCountry
        },
        citizenshipInfo: {
          ssn: removeMask(payload?.spouseSSN, "ssn") ?? null,
          isUSCitizen: payload.spouseIsUSCitizen === IS_US_CITIZEN_YES,
          isPermanentResident: payload.spouseIsGreenCard === IS_GREEN_CARD_YES,
          greenCardNumber:
            payload.spouseIsGreenCard === IS_GREEN_CARD_YES ? removeMask(payload?.spouseAlienIdOrVisa, "uscis") ?? null : null,
          visaNumber:
            payload.spouseIsGreenCard === IS_GREEN_CARD_NO ? removeMask(payload.spouseAlienIdOrVisa, "visa") ?? null : null,
          greenCardExpirationDate: payload.spouseIsGreenCard === IS_GREEN_CARD_YES ? payload.spouseAlienIdOrVisaExpiration : null,
          visaExpirationDate: payload.spouseIsGreenCard === IS_GREEN_CARD_NO ? payload.spouseAlienIdOrVisaExpiration : null,
          passportNumber: payload.spousePassportNumber,
          passportExpirationDate: payload.spousePassportExpiration,
          passportCountry: payload.spousePassportCountry
        }
      }
    },
    subscriptionAgreement: {
      adviserId: advisorId,
      contractType: payload.purchasingAs,
      payoutType: payload.payoutOption,
      payoutAges: payload.payoutAges, // double check default value for single ages
      fundName: payload.ETF,
      fundingAmount: +payload?.funding?.replace(/,/g, ""),
      beneficiaryFullName: null,
      beneficiaryRelationship: null,
      beneficiaryContactInfo: null,
      riaFeePercentage: payload.advisorFee,
      signUpFeePercentage: null,
      managementFeePercentage: null,
      deathFeePercentage: null,
      earlyWithdrawalFeePercentage: null,
      payoutFeePercentage: null,
      withdrawalAmountPercentage: null
    }
  }

  if (payload.isMarried === "S") {
    data.client.spouse = null
  }
  return convertEmptyStringToNull(data)
}

export const convertPayloadToEdit = (payload) => {
  const { client, subscriptionAgreement } = payload?.data
  const { spouse } = payload?.data?.client

  const dataPayload = {
    firstName: client?.firstName,
    middleName: client?.middleName,
    lastName: client?.lastName,
    occupation: client?.occupation,
    sourceOfWealth: client?.sourceOfWealth,
    email: client?.email,
    address: client?.address?.street1,
    address2: client?.address?.street2,
    zipCode: client?.address?.zipCode,
    city: client?.address?.city,
    state: client?.address?.state,
    birthDate: client?.birthDate,
    sex: client?.gender,
    isMarried: client?.maritalStatus,

    isUSCitizen: client?.citizenshipInfo?.isUsCitizen ? IS_US_CITIZEN_YES : IS_US_CITIZEN_NO,
    isGreenCard: client?.citizenshipInfo?.isPermanentResident ? IS_GREEN_CARD_YES : IS_GREEN_CARD_NO,
    ssn: applyMask(client?.citizenshipInfo?.ssn, "ssn"),

    passportNumber: applyMask(client?.citizenshipInfo?.passportNumber, "passport") ?? null,
    passportExpiration: client.citizenshipInfo?.passportExpirationDate,
    passportCountry: client.citizenshipInfo?.passportCountry,
    country: client?.address?.country,

    alienIdOrVisa: client?.citizenshipInfo?.isPermanentResident
      ? applyMask(client?.citizenshipInfo?.greenCardNumber, "uscis") ?? null
      : applyMask(client?.citizenshipInfo?.visaNumber, "visa") ?? null,

    alienIdOrVisaExpiration: client?.citizenshipInfo?.isPermanentResident
      ? client?.citizenshipInfo?.greenCardExpirationDate
      : client?.citizenshipInfo?.visaExpirationDate,

    spouseFirstName: spouse?.firstName,
    spouseMiddleName: spouse?.middleName,
    spouseLastName: spouse?.lastName,
    spouseBirthDate: spouse?.birthDate,
    spouseGender: spouse?.gender ?? GENDER_FEMALE,
    spouseSSN: applyMask(spouse?.citizenshipInfo?.ssn, "ssn"),

    spouseIsUSCitizen: spouse?.citizenshipInfo?.isUsCitizen
      ? spouse.citizenshipInfo.isUsCitizen
        ? IS_US_CITIZEN_YES
        : IS_US_CITIZEN_NO
      : IS_US_CITIZEN_YES,

    spouseIsGreenCard: spouse?.citizenshipInfo?.isPermanentResident
      ? spouse.citizenshipInfo.isUsCitizen
        ? IS_GREEN_CARD_YES
        : IS_GREEN_CARD_NO
      : IS_GREEN_CARD_YES,

    spouseAlienIdOrVisa: spouse?.citizenshipInfo?.isPermanentResident
      ? applyMask(spouse?.citizenshipInfo?.greenCardNumber, "uscis") ?? null
      : applyMask(spouse?.citizenshipInfo?.visaNumber, "visa") ?? null,
    spouseAlienIdOrVisaExpiration: spouse?.citizenshipInfo?.isPermanentResident
      ? spouse?.citizenshipInfo?.isPermanentResident
        ? spouse?.citizenshipInfo?.greenCardExpirationDate
        : spouse?.citizenshipInfo?.visaExpirationDate
      : null,

    spousePassportExpiration: spouse?.citizenshipInfo?.passportExpirationDate ?? null,
    spousePassportCountry: spouse?.citizenshipInfo?.passportCountry ?? null,
    spousePassportNumber: applyMask(spouse?.citizenshipInfo?.passportNumber, "passport") ?? null,
    spouseAddress: spouse?.address?.street1,
    spouseCountry: spouse?.address?.country ?? COUNTRY,
    spouseEmail: spouse?.email,

    investmentStartDate: "",
    payoutOptions: "",
    payoutAges: subscriptionAgreement?.payoutAges,
    payoutOption: subscriptionAgreement?.payoutType,
    advisorFee: formatValueAsNumber(subscriptionAgreement?.riaFeePercentage),
    ETF: subscriptionAgreement?.fundName,
    funding: formatValueAsNumber(subscriptionAgreement?.fundingAmount),
    purchaserType: client?.type,
    purchasingAs: subscriptionAgreement?.contractType
  }
  return dataPayload
}
