import { createCypressSlug } from "@utils"

const ContractDropdown = ({ inputChanged, data, title, field, values, errors, valueType }) => {
  const errs = errors[field]

  const onChangeSelection = (e) => {
    if (valueType === "array") {
      inputChanged(field, [parseInt(e.target.value)])
    } else {
      inputChanged(field, e.target.value)
    }
  }
  return (
    <div className="field" data-cy={createCypressSlug(`contract-field-${title}`)}>
      <label>{title}</label>
      <div className="control">
        <div className="select is-fullwidth">
          <select
            onChange={(e) => onChangeSelection(e)}
            value={data[field]?.toString() ?? ""}
            data-cy={createCypressSlug(`contract-input-${title}`)}>
            {values.map((v) => (
              <option key={v.value || v.title} value={v.value || v.title}>
                {v.title}
              </option>
            ))}
          </select>
          <span className="help is-danger">{errs}</span>
        </div>
      </div>
    </div>
  )
}

export default ContractDropdown
