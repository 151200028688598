import React from "react"
import { InputField } from "@components/inputs"

export const RetirementsDetailsForm = ({ register, errors }) => {
  return (
    <>
      <div className="column is-12">
        <InputField
          type="number"
          name="currentAge"
          labelText="Current Age"
          placeholder="Enter your current age"
          register={register}
          errors={errors}
        />
      </div>
      <div className="column is-12">
        <InputField
          type="number"
          name="annualIncome"
          labelText="Annual Pre-Tax Income"
          placeholder="Enter your annual pre-tax income"
          register={register}
          errors={errors}
        />
      </div>
      <div className="column is-12">
        <InputField
          type="number"
          name="currentRetirementSavings"
          labelText="Current Retirement Savings"
          placeholder="Enter your current retirement savings"
          tooltipText="This is the total of all your retirement savings, including your 401(k) and IRA balances plus any other savings you've designated for retirement."
          register={register}
          errors={errors}
        />
      </div>
      <div className="column is-12">
        <InputField
          type="number"
          name="monthlyContributions"
          labelText="Monthly Contributions"
          placeholder="Enter your monthly contributions"
          tooltipText="This is how much you can contribute monthly to have enough savings for your retirement. Changing your retirement age or retirement budget will change this number."
          register={register}
          errors={errors}
        />
      </div>
      <div className="column is-12">
        <InputField
          type="number"
          name="monthlyBudget"
          labelText="Monthly Budget in Retirement"
          placeholder="Many start with 70% of their pre-retirement income"
          tooltipText="This number can be different for everyone, but many people start with 70% of their projected income at retirement age."
          register={register}
          errors={errors}
        />
      </div>
      <div className="column is-12">
        <InputField
          type="number"
          name="otherRetirementIncome"
          labelText="Optional: Other Retirement Income (Monthly)"
          placeholder="Enter any other retirement expected monthly retirement income"
          tooltipText="This is the monthly total of any other income you expect to receive in retirement, such as pension benefits or Social Security."
          register={register}
          errors={errors}
          rules={{ required: false }}
        />
      </div>
    </>
  )
}
