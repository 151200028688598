import { REACT_APP_CDN_URL } from "@config"

const getAssetUrl = (fileName) => `${REACT_APP_CDN_URL}/${fileName}`

export const BlackPattern = getAssetUrl("global/blackPatternBlock.jpg")
export const SavvlyLogo = getAssetUrl("global/savvlyLogoWhite.svg")
export const SavvlyLogoBlack = getAssetUrl("global/savvlyLogoBlack.svg")
export const NotFoundAnimation = getAssetUrl("global/notFoundAnimation.gif")
export const LoadingCube = getAssetUrl("global/loadingCube.svg")
export const MaintenanceLogo = getAssetUrl("global/maintenanceLogo.svg")
export const LandingHero = getAssetUrl("landing/hero.jpg")
export const LearnIcon = getAssetUrl("dashboard/dashboardBrochures.svg")
export const PlanIcon = getAssetUrl("dashboard/dashboardEstimator.svg")
export const StartIcon = getAssetUrl("dashboard/dashboardContracts.svg")
export const IconLumpSumWhite = getAssetUrl("estimator/lumpSumWhite.svg")
export const IconLumpSumBlack = getAssetUrl("estimator/lumpSumBlack.svg")
export const IconMultiplePayoutsWhite = getAssetUrl("estimator/multiplePayoutsWhite.svg")
export const IconMultiplePayoutsBlack = getAssetUrl("estimator/multiplePayoutsBlack.svg")
export const IconInfoEstimator = getAssetUrl("estimator/infoPayoutType.svg")
export const MobileView = getAssetUrl("estimator/mobilesView.jpg")
export const PlusSymbolEstimator = getAssetUrl("estimator/plusSolidBlack.svg")
export const IconUploadFiles = getAssetUrl("contract/uploadFiles.svg")
export const IconNumberOne = getAssetUrl("contract/numberOne.svg")
export const IconNumberTwo = getAssetUrl("contract/numberTwo.svg")
export const IconNumberThree = getAssetUrl("contract/numberThree.svg")
export const IconChecked = getAssetUrl("contract/checked.svg")
export const AdvisorGuide = getAssetUrl("documentation/advisorGuide.jpg")
export const AdvisorGuidePdf = getAssetUrl("pdf/savvlyAdvisorGuide.pdf")
export const AdvisorScript = getAssetUrl("documentation/advisorScript.jpg")
export const AdvisorScriptPdf = getAssetUrl("pdf/savvlyAdvisorScript.pdf")
export const AccreditedInvestorGuide = getAssetUrl("documentation/accreditedInvestorGuide.jpg")
export const AccreditedInvestorGuidePdf = getAssetUrl("pdf/savvlyAccreditedInvestorGuide.pdf")
export const QualifiedPurchaserGuide = getAssetUrl("documentation/qualifiedPurchaserGuide.jpg")
export const QualifiedPurchaserGuidePdf = getAssetUrl("pdf/savvlyQualifiedPurchaserGuide.pdf")
export const FundingInstructions = getAssetUrl("documentation/fundingInstructions.jpg")
export const FundingInstructionsPdf = getAssetUrl("pdf/savvlyFundingInstructions.pdf")
export const PrivatePlacementMemorandum = getAssetUrl("documentation/privatePlacementMemorandum.jpg")
export const PrivatePlacementMemorandumPdf = getAssetUrl("pdf/savvlyPrivatePlacementMemorandum.pdf")
export const SubscriptionAgreement = getAssetUrl("documentation/subscriptionAgreementTemplate.jpg")
export const SubscriptionAgreementPdf = getAssetUrl("pdf/savvlySubscriptionAgreementTemplate.pdf")
export const LimitedPartnershipAgreement = getAssetUrl("documentation/limitedPartnershipAgreement.jpg")
export const LimitedPartnershipAgreementPdf = getAssetUrl("pdf/savvlyLimitedPartnershipAgreement.pdf")
