import React from "react"
import { MaintenanceLogo } from "@assets"

export default function MaintenanceLayout() {
  const handleReloadButton = (event) => {
    event.preventDefault()
    window.location.reload()
  }
  return (
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="column is-6 is-offset-3">
            <div className="box">
              <img src={MaintenanceLogo} alt="Savvly maintenance logo" />
              <h1 className="title">Site is under maintenance</h1>
              <h2>We're working hard to improve the user experience and security. Stay tuned!</h2>
              <p>Try again later.</p>
              <div>
                <p className="has-text-centered mt-6">
                  <a href="mailto:support@savvly.com" className="button is-secondary mr-2">
                    Contact Us
                  </a>
                  <a href="/" className="button is-primary" onClick={handleReloadButton}>
                    Reload
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
