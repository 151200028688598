import { Worker, Viewer } from "@react-pdf-viewer/core"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import "@react-pdf-viewer/core/lib/styles/index.css"
import { REACT_APP_CDN_URL } from "@config"

const PdfPreview = ({ pdf }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  return (
    <>
      {!pdf && (
        <div
          style={{ height: "100%" }}
          className="is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
          <div className="loading-spinner"></div>
          <p>Loading Contract Draft</p>
        </div>
      )}

      {pdf && (
        <Worker workerUrl={`${REACT_APP_CDN_URL}/libs/pdfjs-worker.min.js`}>
          <div className="viewer-container">
            <Viewer fileUrl={pdf} plugins={[defaultLayoutPluginInstance]} />
          </div>
        </Worker>
      )}
    </>
  )
}

export default PdfPreview
