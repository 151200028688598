import * as Tooltip from "@radix-ui/react-tooltip"
import * as Sentry from "@sentry/react"
import React, { useCallback, useEffect, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { debounce } from "lodash"
import { CollapsiblePanel } from "@components"
import { getRetirementSavings } from "@api"
import { defaultRetirementSavingsData, removeCommasFromInput, DEBOUNCE_INPUT_TIMEOUT, validateFields } from "@utils"
import { AdvancedDetailsForm } from "./AdvancedDetailsForm"
import { RetirementsDetailsForm } from "./RetirementsDetailsForm"
import { RetirementSavingsChart } from "./RetirementSavingsChart"
import { RetirementSavingsInfo } from "./RetirementSavingsInfo"

export const RetirementCalculator = () => {
  const {
    register,
    control,
    setError,
    clearErrors,
    formState: { errors },
    watch
  } = useForm({ defaultValues: defaultRetirementSavingsData })

  const watchedValues = useWatch({ control })

  const [retirementSavings, setRetirementSavings] = useState([])

  useEffect(() => {
    ;(async () => {
      try {
        const response = await getRetirementSavings(defaultRetirementSavingsData)

        if (response) {
          setRetirementSavings(response)
        }
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            section: "Retirement Calculator"
          },
          extra: {
            message: "Cannot fetch data"
          }
        })
      }
    })()
  }, [])

  const onSubmit = async (dataForm) => {
    const formattedDataForm = removeCommasFromInput(dataForm)

    try {
      const response = await getRetirementSavings(formattedDataForm)

      if (response) {
        setRetirementSavings(response)
      }
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          section: "Retirement Calculator"
        },
        extra: {
          message: "Cannot fetch data"
        }
      })
    }
  }
  const debouncedSubmit = useCallback(debounce(onSubmit, DEBOUNCE_INPUT_TIMEOUT), [])

  const validateAndSubmit = useCallback(
    debounce(async (values) => {
      const isValid = validateFields(values, setError, clearErrors)

      if (isValid) {
        debouncedSubmit(values)
      }
    }, DEBOUNCE_INPUT_TIMEOUT),
    [debouncedSubmit, setError, clearErrors]
  )

  useEffect(() => {
    validateAndSubmit(watchedValues)
  }, [watchedValues, validateAndSubmit])

  return (
    <div className="main-container">
      <div className="columns is-family-secondary">
        <div className="column is-5 card m-4 p-6">
          <h1 className="is-size-3 has-text-weight-semibold is-family-poppins mb-4">Retirement Details</h1>
          <RetirementsDetailsForm register={register} errors={errors} />
          <CollapsiblePanel
            title={"Advanced Details"}
            content={<AdvancedDetailsForm register={register} errors={errors} watch={watch} />}
          />
        </div>
        <div className="column card m-4 p-6">
          <RetirementSavingsInfo retirementSavings={retirementSavings} />
          <div className="border-2 my-4" />
          <RetirementSavingsChart retirementSavings={retirementSavings} />
          <div className="border-2 my-4" />
          <div className="is-flex is-align-items-center">
            <span className="has-text-weight-semibold is-family-poppins">How do I read the chart?</span>
            <Tooltip.Provider delayDuration={100}>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center">
                    <AiOutlineQuestionCircle className="ml-2 has-text-primary is-clickable" />
                  </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content className="TooltipContent" sideOffset={5}>
                    <span className="is-family-secondary">
                      If your Current Retirement Forecast is greater than your Recommended Amount for Retirement, you're in good
                      shape! If not, consider boosting your monthly contributions in Savvly.
                    </span>
                    <Tooltip.Arrow className="TooltipArrow" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
        </div>
      </div>
    </div>
  )
}
