import React, { useEffect, useRef, useState } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { buildRetirementSavingsChartConfig } from "@config"

export const RetirementSavingsChart = ({ retirementSavings }) => {
  const [pieChartOptions, setPieChartOptions] = useState({})
  const highchartsRef = useRef(null)

  useEffect(() => {
    if (retirementSavings) {
      setPieChartOptions(buildRetirementSavingsChartConfig(retirementSavings))
    }
  }, [retirementSavings])

  return <HighchartsReact highcharts={Highcharts} options={pieChartOptions} ref={highchartsRef} />
}
