export const applyMask = (value, mask) => {
  switch (mask) {
    case "passport":
      value = value?.replace(/[&/#,+()$~%.'":*?<>{}]/g, "")?.replace(/[^a-zA-Z0-9]/g, "")
      break
    case "ssn":
      value = value
        ?.replace(/\D/g, "")
        ?.replace(/^(\d{3})/, "$1-")
        ?.replace(/-(\d{2})/, "-$1-")
        ?.replace(/(\d)-(\d{4}).*/, "$1-$2")
      break
    case "uscis":
      value = value
        ?.replace(/\D/g, "")
        ?.replace(/^(\d{3})/, "$1-")
        ?.replace(/-(\d{3})/, "-$1-")
        ?.replace(/(\d)-(\d{4}).*/, "$1-$2")
      break
    case "visa":
      value =
        value
          ?.replace(/\s/g, "")
          ?.match(/.{1,4}/g)
          ?.join(" ")
          ?.substr(0, 19) || ""
      break
    default:
      break
  }
  return value
}

export const removeMask = (value, mask) => {
  if (mask === "ssn" || mask === "uscis") {
    return value?.replace(/-/g, "")
  } else if (mask === "visa") {
    return value?.replace(/ /g, "")
  } else {
    return value
  }
}
