import { useEffect } from "react"
import { FaClipboardCheck } from "react-icons/fa"
import {
  formatNumberAsCurrency,
  createCypressSlug,
  formatDate,
  GENDER_MALE,
  IS_GREEN_CARD_YES,
  IS_US_CITIZEN_YES,
  MARRIED,
  MULTIPLE_PAYOUTS,
  QUALIFIED_PURCHASER
} from "@utils"
import { COUNTRIES, SOURCE_OF_WEALTH } from "@config"

const List = ({ title, values, editPage, setPage }) => {
  const keys = Object.keys(values)

  return (
    <>
      <div className="columns is-multiline is-mobile is-12 mx-3">
        <div className="column is-12 is-half-mobile is-flex is-justify-content-space-between p-0">
          <p className="column is-6 has-text-weight-bold has-text-primary has-text-weight-bold p-0">{title}</p>
          <button className="column is-2 edit-review-button button is-primary is-outlined p-0" onClick={() => setPage(editPage)}>
            Edit
          </button>
        </div>
        <ul className="column is-12 is-half-mobile">
          {keys
            ?.filter((key) => values[key]?.trim())
            .map((key, index) => (
              <div key={index}>
                <li className="columns is-flex is-justify-content-space-between label-spacing">
                  <strong className="column is-6 pl-0">{key}</strong>
                  <span
                    className="column is-6 pl-0"
                    data-cy={title === "Spouse Information" ? createCypressSlug(`spouse-${key}`) : createCypressSlug(key)}>
                    {values[key]}
                  </span>
                  <hr />
                </li>
              </div>
            ))}
        </ul>
      </div>
    </>
  )
}

const Review = (props) => {
  const { data, setValidations } = props

  const getSourceOfWealthValue = (data) => {
    const sourceOfWealth = SOURCE_OF_WEALTH.find((i) => i.value === data)

    return sourceOfWealth ? sourceOfWealth.title : null
  }

  useEffect(() => {
    setValidations([])
  }, [])

  const clientInfoValues = {
    "Investor Type": data.purchaserType === QUALIFIED_PURCHASER ? "Qualified Purchaser" : "Accredited Investor",
    "Full Name": `${data.firstName} ${data.lastName}`,
    Occupation: data.occupation,
    "Source of Wealth": getSourceOfWealthValue(data.sourceOfWealth),
    "Date of Birth": formatDate(data.birthDate),
    "Marital Status": data.isMarried === MARRIED ? "Married" : "Single",
    Gender: data.sex === GENDER_MALE ? "Male" : "Female",
    "Country of Origin": COUNTRIES[data.passportCountry],
    SSN: data.ssn,
    Citizenship: data.isUSCitizen === IS_US_CITIZEN_YES ? "U.S Citizen" : `Non U.S Citizen`,
    [data.isGreenCard === IS_GREEN_CARD_YES ? "Green Card Number" : "Visa Number"]: data.alienIdOrVisa,
    [data.isGreenCard === IS_GREEN_CARD_YES ? "Green Card Expiration Date" : "Visa Expiration Date"]: formatDate(
      data.alienIdOrVisaExpiration
    ),
    "Passport Number": data.passportNumber,
    "Passport Expiration Date": formatDate(data.passportExpiration)
  }

  const contractChoiceValues = {
    "Amount to Invest": formatNumberAsCurrency(data.funding),
    "Investment Fund": data.ETF,
    "Advisor Fee (in bps)": String(data.advisorFee),
    [data.payoutOption === MULTIPLE_PAYOUTS ? "Payout Ages" : "Payout Age"]: String(data.payoutAges)
  }

  const contactInformationValues = {
    "Email Address": data.email,
    "Spouse Email Address": data.spouseEmail,
    Address: data.address,
    City: `${data.city}, ${data.state}, ${data.zipCode}`
  }

  const spouseValues = {
    "Full Name": `${data.spouseFirstName} ${data.spouseLastName}`,
    "Date of Birth": formatDate(data.spouseBirthDate),
    Gender: data.spouseGender === GENDER_MALE ? "Male" : "Female",
    "Country of Origin": data.spousePassportCountry,
    SSN: data.spouseSSN,
    Citizenship: data.spouseIsUSCitizen === IS_US_CITIZEN_YES ? "U.S Citizen" : "Non U.S Citizen",
    [data.spouseIsGreenCard === IS_GREEN_CARD_YES ? "Green Card Number" : "Visa Number"]: data.spouseAlienIdOrVisa,
    [data.spouseIsGreenCard === IS_GREEN_CARD_YES ? "Green Card Expiration Date" : "Visa Expiration Date"]: formatDate(
      data.spouseAlienIdOrVisaExpiration
    ),
    "Passport Number": data.spousePassportNumber,
    "Passport Expiration Date": formatDate(data.spousePassportExpiration)
  }

  return (
    <>
      <h1 className="is-size-3 mb-4 is-mobile-centered has-text-weight-bold has-text-primary">
        One more thing before we generate the contract.
      </h1>
      <div className="columns is-justify-content-space-around">
        <div className="column is-12">
          <h2 className="is-size-4 has-text-weight-medium mb-4 has-text-grey mb-6">
            {" "}
            <FaClipboardCheck className="has-text-primary" /> Let's verify the information is correct.
          </h2>
          <div className="columns is-multiline">
            <div className="column is-6">
              <List title="Client Profile" values={clientInfoValues} editPage={1} {...props} />
              <br />
              {data.isMarried === MARRIED && <List title="Spouse Information" editPage={1} values={spouseValues} {...props} />}
            </div>
            <div className="column is-6">
              <List
                title="Contract Choice"
                values={contractChoiceValues}
                editPage={data.isMarried === MARRIED ? 5 : 4}
                {...props}
              />
              <br />
              <List
                title="Contact Information"
                values={contactInformationValues}
                editPage={data.isMarried === MARRIED ? 4 : 3}
                {...props}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Review
