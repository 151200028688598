import { getOrdinalIndicator, nFormatter } from "@utils"
import { ESTIMATOR_SAVVLY_BONUS } from "@config"

export const SINGLE_UPFRONT_PAYMENT = "Single upfront payment"
export const DEFAULT_INVESTMENT_AMOUNT_NUMERIC = 10_000
export const DEFAULT_MONTHLY_INSTALLMENT = 100

export const PUBLIC_SAVVLY_INSTALLMENTS_MIN_INVESTMENT_AMOUNT = 1_000
export const PUBLIC_SAVVLY_INSTALLMENTS_MAX_INVESTMENT_AMOUNT = 300_000
export const MONTHLY_INSTALLMENT_VALUES = [
  { label: "$100/month", value: 100 },
  { label: "$250/month", value: 250 },
  { label: "$500/month", value: 500 },
  { label: "$750/month", value: 750 },
  { label: "$1,000/month", value: 1000 },
  { label: "$2,500/month", value: 2500 },
  { label: "$5,000/month", value: 5000 }
]
export const FREQUENCY_AND_DURATION_OPTIONS = [
  { label: "Single upfront payment", value: "Single upfront payment" },
  { label: "12 monthly payments (1 year)", value: "1" },
  { label: "24 monthly payments (2 years)", value: "2" },
  { label: "36 monthly payments (3 years)", value: "3" },
  { label: "60 monthly payments (5 years)", value: "5" },
  { label: "120 monthly payments (10 years)", value: "10" }
]

export const TARGET_OPTION_TO_LIMIT_AGE_RANGE = 5

export const ESTIMATOR_INSTALLMENT_ENDPOINT = "Installment"
export const ESTIMATOR_MULTIPLE_PAYOUTS_ENDPOINT = "Multiple"

export const PIE_CHART_INVESTMENT_LABEL = "Investment"
export const PIE_CHART_MONTHLY_INVESTMENT_LABEL = "Investment Amount"
export const getDescriptionLabel = "<b>Tap to see the amount of each payout</b>"

export const getPieChartTitle = (total) => {
  return `<b>Total Estimated Payouts<br/><span style="color: ${ESTIMATOR_SAVVLY_BONUS};">$${nFormatter(total)}<span/></b>`
}
export const getLabelNames = (position, pointName) => {
  return `<b>${position}${getOrdinalIndicator(position)} <br/>Payout <br/>at ${pointName}</b>`
}

export const genderLabel = {
  male: "Male",
  female: "Female"
}

export const FULL_RANGE_AGE_LIMIT_PAYMENTS = {
  start: 18,
  end: 75
}

export const LIMITED_RANGE_AGE_LIMIT_PAYMENTS = {
  start: 18,
  end: 70
}

export const getCurrentAgeData = (ageLimits) => {
  const MIN_AGE = ageLimits.start
  const MAX_AGE = ageLimits.end

  return Array.from({ length: MAX_AGE - MIN_AGE + 1 }, (_, i) => i + MIN_AGE)
}

export const shouldDisplayOption = (label, currentAge) => {
  if (label === FREQUENCY_AND_DURATION_OPTIONS[TARGET_OPTION_TO_LIMIT_AGE_RANGE].label) {
    const ageLimits = LIMITED_RANGE_AGE_LIMIT_PAYMENTS
    return currentAge <= ageLimits.end
  }
  return true
}

export const shouldDisableOption = (label, currentAge) => {
  return !shouldDisplayOption(label, currentAge)
}

export const getWithdrawalAgeData = (currentAge) => {
  const MAX_WITHDRAWAL_AGE = 100
  return Array.from({ length: MAX_WITHDRAWAL_AGE - currentAge + 1 }, (_, i) => i + Number(currentAge))
}
