import { createCypressSlug } from "@utils"

const ContractMoneyInput = ({ inputChanged, data, field, title, errors, min, max }) => {
  const handleChange = (e) => {
    let value = 0

    if (e.target.value !== "") {
      value = parseFloat(e.target.value.replaceAll(/\D+/g, "")).toLocaleString()
    }
    inputChanged(field, value)
  }

  const errs = errors[field]

  return (
    <div className="field" data-cy={createCypressSlug(`contract-field-${title}`)}>
      <label className="contractLabel">{title}</label>
      <div className="control has-icons-left">
        <input
          onChange={handleChange}
          value={data[field] ?? ""}
          className={"input " + (errs ? "is-danger" : "")}
          type="text"
          placeholder={title}
          minLength={min}
          maxLength={max}
          data-cy={createCypressSlug(`contract-input-${title}`)}
        />
        <span className="icon is-left">$</span>
      </div>
      <span className="help is-danger">{errs}</span>
    </div>
  )
}

export default ContractMoneyInput
