import React, { useState } from "react"
import { ProfileModal } from "@components/estimator"

export const ClientAgeGender = ({ gender, setGender, currentAge, setCurrentAge, payoutAge, setPayoutAge, pageId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <section>
      <div className="is-size-7 gender-select mt-2">
        <div className="is-flex is-justify-content-space-between pl-2 is-clickable has-text-weight-bold">
          <span data-cy="control-client-age-gender">
            Single: {gender}, Age {currentAge}
          </span>
          <div
            href="#"
            className="has-text-primary pl-2 is-underlined"
            onClick={() => {
              toggleModal()
            }}>
            <span data-cy="edit-btn-age-gender">Edit</span>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ProfileModal
          pageId={pageId}
          toggleModal={toggleModal}
          isModalOpen={isModalOpen}
          gender={gender}
          setGender={setGender}
          payoutAge={payoutAge}
          setPayoutAge={setPayoutAge}
          currentAge={currentAge}
          setCurrentAge={setCurrentAge}
        />
      )}
    </section>
  )
}
