import React, { useState, useEffect } from "react"
import {
  ToastNotification,
  fiveYearsDifferenceNotification,
  payoutAgeGreaterThanClientAge,
  payoutAgeOutOfRangeNotification
} from "@components/notification"

export const PayoutAgeModal = ({ gender, setPayoutAge, payoutAge, isModalOpen, toggleModal, currentAge }) => {
  const [tempPayoutAge, setTempPayoutAge] = useState(payoutAge)

  const handleInput = () => {
    if (gender === "Male" && tempPayoutAge >= 70 && tempPayoutAge <= 100) {
      setPayoutAge(tempPayoutAge)
      if (tempPayoutAge > currentAge) {
        if (Math.abs(currentAge - tempPayoutAge) < 5) {
          setPayoutAge(currentAge + 5)
          ToastNotification(fiveYearsDifferenceNotification(currentAge))
        }
      } else {
        setPayoutAge(currentAge + 5)
        ToastNotification(payoutAgeGreaterThanClientAge(tempPayoutAge, currentAge))
      }
      toggleModal()
    } else if (gender === "Female" && tempPayoutAge >= 75 && tempPayoutAge <= 100) {
      setPayoutAge(tempPayoutAge)
      toggleModal()
    } else {
      ToastNotification(payoutAgeOutOfRangeNotification(gender))
      setTempPayoutAge(75)
      toggleModal()
    }
  }

  useEffect(() => {
    setTempPayoutAge(tempPayoutAge)
  }, [tempPayoutAge])

  return (
    <div className={`modal ${isModalOpen ? "is-active" : null}`}>
      <div className="modal-background" onClick={toggleModal}></div>
      <div className="modal-content">
        <div className="box box-container modal-default-height">
          <div className="content is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
            <h1 className="title has-text-primary has-text-centered">Payout Age</h1>
            <div className="card is-rounded has-background-white-ter">
              <div className="card-content py-6 px-6">
                <div className="is-flex px-6 mx-6 is-flex-direction-column is-justify-content-center is-align-items-center">
                  <input
                    className="input is-primary has-text-primary has-text-weight-bold"
                    data-cy="payout-age-number-input"
                    type="number"
                    min={gender === "Male" ? 70 : 75}
                    max={gender === "Male" ? 90 : 95}
                    value={tempPayoutAge}
                    onChange={(e) => setTempPayoutAge(e.target.value)}
                  />
                  <button className="button is-primary is-size-6 mt-4" data-cy="set-payout-age-btn" onClick={handleInput}>
                    SET PAYOUT AGE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="field is-flex is-justify-content-flex-end m-3">
            <div className="control">
              <button className="button is-text" onClick={toggleModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
