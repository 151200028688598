import { useMsal } from "@azure/msal-react"
import React, { useContext, useState } from "react"

const AppContext = React.createContext()

export function useAppContext() {
  return useContext(AppContext)
}

export const AppContextProvider = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(true)
  const [estimatorEndpoint, setEstimatorEndpoint] = useState(null)

  const ACCOUNT_APPROVED = "approved"
  const { accounts } = useMsal()

  let advisorId, userIsApproved, homeAccountId, fullName, idTokenClaims

  if (accounts && accounts.length > 0) {
    const { idTokenClaims: accountInfo, homeAccountId: homeId } = accounts?.[0]
    advisorId = accountInfo?.oid
    userIsApproved = accountInfo?.extension_status === ACCOUNT_APPROVED
    homeAccountId = homeId
    fullName = `${accountInfo?.given_name} ${accountInfo?.family_name}`
    idTokenClaims = accountInfo
  }

  return (
    <AppContext.Provider
      value={{
        estimatorEndpoint,
        setEstimatorEndpoint,
        showSideBar,
        setShowSideBar,
        advisorId,
        userIsApproved,
        homeAccountId,
        fullName,
        idTokenClaims
      }}>
      {children}
    </AppContext.Provider>
  )
}
