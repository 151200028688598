import React, { useState } from "react"
import { ReturnInvestmentModal } from "@components/estimator"

export const ReturnPercentage = ({ averageReturn, setAverageReturn }) => {
  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false)
  const toggleReturnModal = () => {
    setIsReturnModalOpen(!isReturnModalOpen)
  }
  return (
    <section className="pt-1 pb-3 is-flex is-justify-content-center">
      <p className="is-size-7 average-return-select has-text-weight-bold">
        <span data-cy="control-average-market-return">Est. {averageReturn}% Return</span>
        <a
          className="has-text-primary pl-2"
          onClick={() => {
            toggleReturnModal()
          }}>
          <span className="is-underlined" data-cy="edit-average-market-return-btn">
            Edit
          </span>
        </a>
      </p>
      <ReturnInvestmentModal
        isReturnModalOpen={isReturnModalOpen}
        toggleReturnModal={toggleReturnModal}
        averageReturn={averageReturn}
        setAverageReturn={setAverageReturn}
      />
    </section>
  )
}
