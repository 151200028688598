import React from "react"
import ReactDOM from "react-dom/client"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { ReportWebVitals } from "./ReportWebVitals"
import "./index.scss"
import App from "./App"
import { AppContextProvider } from "@context"
import axios from "axios"
import { msalConfig, REACT_APP_SENTRY_DSN, REACT_APP_ENVIRONMENT, REACT_APP_API_URL } from "@config"

const msalInstance = new PublicClientApplication(msalConfig)

axios.defaults.baseURL = REACT_APP_API_URL
axios.defaults.timeout = 30000

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: REACT_APP_ENVIRONMENT,

  tracesSampleRate: 0.1
})

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <MsalProvider instance={msalInstance}>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </MsalProvider>
)

ReportWebVitals()
