import { nFormatter } from "@utils"
import {
  PUBLIC_ESTIMATOR_DATALABELS,
  PUBLIC_ESTIMATOR_X_TICKS,
  PUBLIC_ESTIMATOR_Y_TICKS,
  RI_BORDER_COLOR,
  RI_INDEX_FUNDS_ALONE,
  RI_INVESTMENT,
  RI_X_TICKS,
  RI_Y_TICKS,
  RI_GRID,
  LS_Y_TICKS,
  LS_GRID,
  MP_DATALABELS,
  MP_X_TICKS,
  MP_Y_TICKS,
  MP_GRID
} from "./colors"

export const privateLumpSumOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      right: 35
    }
  },
  plugins: {
    title: {
      display: false
    },
    legend: {
      position: "top",
      align: "centered",
      pointStyle: "circle",
      labels: {
        font: {
          size: "14"
        },
        usePointStyle: true,
        padding: 20
      }
    },
    datalabels: {
      font: {
        size: "14",
        weight: "bold"
      }
    }
  },
  scales: {
    x: {
      grace: "10%",
      stacked: true,
      display: true,
      grid: {
        display: false
      },
      ticks: {
        font: {
          size: "15",
          weight: "bolder"
        }
      }
    },
    y: {
      grace: "10%",
      type: "linear",
      stacked: true,
      display: true,
      ticks: {
        color: `${LS_Y_TICKS}`,
        callback: (value) => nFormatter(value),
        maxTicksLimit: 3
      },
      grid: {
        color: `${LS_GRID}`
      }
    }
  }
}

export const privateMultiplePayoutsOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false
    },
    legend: {
      position: "top",
      align: "centered",
      usePointStyle: true,
      pointStyle: "circle",
      labels: {
        font: {
          size: "14"
        },
        usePointStyle: true,
        padding: 20
      }
    },
    datalabels: {
      color: `${MP_DATALABELS}`,
      font: {
        size: "14",
        weight: "bold"
      }
    }
  },
  scales: {
    x: {
      stacked: true,
      display: true,
      grid: {
        display: false
      },
      ticks: {
        color: `${MP_X_TICKS}`,
        font: {
          size: "15",
          weight: "bolder"
        }
      }
    },
    y: {
      type: "linear",
      stacked: true,
      display: true,
      grace: "10%",
      ticks: {
        color: `${MP_Y_TICKS}`,
        beginAtZero: false,
        maxTicksLimit: 5,
        callback: (value) => nFormatter(value)
      },
      grid: {
        color: `${MP_GRID}`
      }
    }
  }
}

export const publicMultiplePayoutOptions = {
  responsive: true,
  hover: {
    mode: null
  },
  grouped: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      enabled: false
    },
    title: {
      display: false
    },
    legend: {
      onClick: false,
      display: true,
      labels: {
        fullSize: true,
        color: "black",
        font: {
          size: 16
        }
      }
    },
    datalabels: {
      color: `${PUBLIC_ESTIMATOR_DATALABELS}`,
      font: {
        size: "17",
        weight: "bold"
      }
    }
  },
  scales: {
    x: {
      stacked: true,
      display: true,
      grid: {
        display: false
      },
      ticks: {
        color: `${PUBLIC_ESTIMATOR_X_TICKS}`,
        font: {
          size: "17",
          weight: "bold"
        }
      }
    },
    y: {
      stacked: true,
      display: true,
      grace: "10%",
      ticks: {
        color: `${PUBLIC_ESTIMATOR_Y_TICKS}`,
        font: {
          size: "17"
        },
        beginAtZero: false,
        maxTicksLimit: 5,
        callback: (value) => {
          return "$" + nFormatter(value)
        }
      },
      grid: {
        display: false
      }
    }
  }
}

export const returnInvestmentOptions = {
  responsive: true,
  maintainAspectRatio: true,
  elements: {
    bar: {
      barPercentage: 1,
      borderWidth: {
        left: 5,
        right: 5
      },
      borderColor: `${RI_BORDER_COLOR}`
    }
  },
  layout: {
    padding: {
      right: 0
    }
  },
  plugins: {
    title: {
      display: false
    },
    legend: {
      position: "top",
      align: "centered",
      boxHeight: 60,
      usePointStyle: true,
      pointStyle: "circle",
      labels: {
        usePointStyle: true,
        padding: 15
      }
    },
    datalabels: {
      display: true,
      color: (context) => {
        if (context.dataset.label === "Index Funds Alone") return `${RI_INDEX_FUNDS_ALONE}`
        else if (context.dataset.label === "Investment") return `${RI_INVESTMENT}`
        else return `${RI_INVESTMENT}`
      },
      anchor: "end",
      align: (context) => {
        return context.dataset.label === "Savvly Bonus" ? "end" : "start"
      },
      formatter: (value) => {
        return `$${nFormatter(value)}`
      },
      padding: 0,
      clip: true,
      font: {
        size: "12",
        weight: "bold"
      }
    }
  },
  scales: {
    x: {
      stacked: true,
      display: true,
      grid: {
        display: false
      },
      ticks: {
        color: `${RI_X_TICKS}`,
        font: {
          size: "15",
          weight: "bolder"
        }
      }
    },
    y: {
      beginAtZero: true,
      type: "linear",
      stacked: true,
      display: true,
      grace: "15%",
      ticks: {
        color: `${RI_Y_TICKS}`,
        callback: (value) => nFormatter(value),
        maxTicksLimit: 4
      },
      grid: {
        color: `${RI_GRID}`
      }
    }
  }
}
