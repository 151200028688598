import React from "react"
import { GENERAL_ERROR_MESSAGE } from "@config"

export const PdfErrorMessage = ({ error }) => {
  return (
    <div className="mt-6 is-flex is-justify-content-center">
      <article className="message is-danger">
        <div className="message-body">{error || GENERAL_ERROR_MESSAGE}</div>
      </article>
    </div>
  )
}
