import { Link, NavLink } from "react-router-dom"
import { useAppContext } from "@context"
import { LearnIcon, PlanIcon, StartIcon } from "@assets"

const Dashboard = () => {
  const { fullName } = useAppContext()

  return (
    <div id="main-dash" className="container p-4">
      <br />
      <div className="main-dashboard has-text-centered">
        <h1 className="title is-size-2 mb-1 has-text-primary" data-cy="welcome-message">
          Welcome to Savvly, {fullName}
        </h1>
        <p className="has-text-black is-size-5">We have a few ways you can get comfortable with the Savvly product</p>
      </div>
      <hr className="is-mobile-hide" />
      <div className="columns mt-6 is-8">
        <div className="column">
          <div className="card dash-link card-content mt-1 is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center has-text-centered">
            <h3 className="has-text-weight-bold has-text-primary is-size-5">Learn</h3>
            <p className="has-text-centered">Download brochures and get Savvly documentation</p>
            <img src={LearnIcon} alt="Learn icon" className="py-3" />
            <NavLink to="/documentation" className="button is-primary" data-cy="documentation-button">
              Download Brochures
            </NavLink>
          </div>
        </div>

        <div className="column">
          <div className="card dash-link card-content mt-1 is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center has-text-centered">
            <h3 className="has-text-weight-bold has-text-primary is-size-5">Plan</h3>
            <p className="has-text-centered">Try different variables to estimate return in various scenarios</p>
            <img src={PlanIcon} alt="Plan icon" className="py-3" />
            <NavLink className="button is-primary" to="/estimator" data-cy="estimator-button">
              Try the Estimator
            </NavLink>
          </div>
        </div>

        <div className="column">
          <div className="card dash-link card-content mt-1 is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center has-text-centered">
            <h3 className="has-text-weight-bold has-text-primary is-size-5">Start</h3>

            <p className="has-text-centered">Ready to start? Our easy process will create a contract in minutes</p>
            <img src={StartIcon} alt="Start icon" className="py-3" />
            <Link to="/newcontract" className="button is-primary" data-cy="contract-button">
              Start a Contract
            </Link>
          </div>
        </div>
      </div>

      <div className="meeting-dashed mt-5 p-4 has-text-centered">
        <div className="is-size-5 has-text-primary">Still have questions?</div>
        <div>Schedule a meeting with our financial advisor staff</div>
        <button
          className="button is-primary is-outlined mt-4"
          onClick={() =>
            window.open(
              "https://calendly.com/meet-savvly/savvly-platform-demo-call?utm_medium=website&utm_source=adviser&utm_content=dashboard",
              "_blank",
              "noopener,noreferrer"
            )
          }>
          Schedule a Meeting
        </button>
      </div>
    </div>
  )
}

export default Dashboard
