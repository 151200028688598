import React from "react"
import { Bar } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import { ESTIMATOR_SAVVLY_BONUS, ESTIMATOR_INDEX_FUNDS_ALONE, ESTIMATOR_INVESTMENT, ESTIMATOR_DATALABELS } from "@config"
import { nFormatter } from "@utils"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)
export function LumpSumPayout({ data, options, lumpSumRef }) {
  const chartData = {
    labels: [""],
    datasets: [
      {
        label: "Investment",
        data: [data?.investment],
        backgroundColor: `${ESTIMATOR_INVESTMENT}`,
        barThickness: 128,
        maxBarThickness: 128,
        datalabels: {
          labels: {
            totalBar: {
              formatter: function () {
                return `$${nFormatter(data?.investment)}`
              },
              anchor: "end",
              align: "right",
              color: `${ESTIMATOR_DATALABELS}`,
              offset: 65
            }
          }
        }
      },
      {
        label: "Index Funds Alone",
        data: [data?.index_fund_alone],
        backgroundColor: `${ESTIMATOR_INDEX_FUNDS_ALONE}`,
        barThickness: 127,
        datalabels: {
          labels: {
            totalBar: {
              formatter: function () {
                return `$${nFormatter(data?.index_fund_alone)}`
              },
              align: "right",
              color: `${ESTIMATOR_DATALABELS}`,
              offset: 65
            }
          }
        }
      },
      {
        label: "Savvly Bonus",
        data: [data?.savvly_upside],
        backgroundColor: `${ESTIMATOR_SAVVLY_BONUS}`,
        maxBarThickness: 128,
        barThickness: 127,
        datalabels: {
          labels: {
            totalBar: {
              formatter: function () {
                return `$${nFormatter(data?.total)}`
              },
              anchor: "end",
              align: "end",
              color: `${ESTIMATOR_DATALABELS}`,
              offset: 2
            },
            value: {
              formatter: function (value) {
                return `$${nFormatter(value)}`
              },
              align: "right",
              color: `${ESTIMATOR_DATALABELS}`,
              offset: 65,
              clamping: false
            }
          }
        }
      }
    ]
  }
  return (
    <div className="container-height" data-cy="lump-sum-bar">
      <h2 className="is-size-6 has-text-weight-bold">LUMP SUM PAYOUT</h2>
      <Bar options={options} data={chartData} ref={lumpSumRef} id={"lumpSumCanvas"} />
    </div>
  )
}
export const LumpSumPayoutMemoized = React.memo(LumpSumPayout)
