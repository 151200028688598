import { REACT_APP_MSAL_CLIENT_ID, REACT_APP_ENVIRONMENT } from "."

const tenants = {
  dev: "savvlydev",
  stg: "savvlystg",
  prd: "savvlyprd"
}

const authDomains = {
  dev: "savvlydev.onmicrosoft.com",
  stg: "savvlystg.onmicrosoft.com",
  prd: "auth.savvly.com"
}

const userflows = {
  prd: {
    signin: "B2C_1_sign_in",
    signup: "B2C_1_sign_up"
  },
  stg: {
    signin: "B2C_1_sign_in",
    signup: "B2C_1_sign_up"
  },
  dev: {
    signin: "B2C_1_Savvly_signin",
    signup: "B2C_1_Savvly_ria_signup"
  }
}

const tenant = tenants[REACT_APP_ENVIRONMENT]
const userflow = userflows[REACT_APP_ENVIRONMENT]
const authDomain = authDomains[REACT_APP_ENVIRONMENT]

export const msalConfig = {
  auth: {
    clientId: REACT_APP_MSAL_CLIENT_ID,
    authority: `https://${tenant}.b2clogin.com/${authDomain}/${userflow?.signin}`,
    knownAuthorities: [`${tenant}.b2clogin.com`]
  }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["openid", "offline_access", REACT_APP_MSAL_CLIENT_ID]
}

export const registerRequest = {
  authority: `https://${tenant}.b2clogin.com/${authDomain}/${userflow?.signup}`,
  scopes: ["openid", "offline_access"]
}
