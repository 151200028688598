import axios from "axios"
import axiosRetry from "axios-retry"
import { useMsal } from "@azure/msal-react"
import { useAppContext } from "@context"
import { loginRequest } from "@config"

const useQuery = () => {
  const { instance, accounts } = useMsal()
  const { advisorId } = useAppContext()

  let status = false
  let error = ""

  const api = axios.create({
    headers: {
      "Content-Type": "application/json",
      "x-adviser-id": advisorId
    }
  })

  axiosRetry(api, {
    retries: 5,
    retryDelay: (retryCount) => {
      return Math.pow(2, retryCount) * 1000
    }
  })

  const query = async ({ path, data, method = "GET", timeout }) => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    }

    const { accessToken } = await instance.acquireTokenSilent(request)

    let response, statusCode
    try {
      const res = await api({
        method,
        url: path,
        headers: {
          Authorization: "Bearer " + accessToken
        },
        data,
        timeout
      })
      response = res.data
      statusCode = res.status
      status = true
    } catch (e) {
      status = false
      error = e
    }

    return {
      status,
      statusCode,
      response,
      error
    }
  }

  return { query }
}

export default useQuery
