import * as Sentry from "@sentry/react"
import { REACT_APP_API_V2_URL } from "@config"
import { useQuery } from "@hooks"
import { convertContractPayload } from "@utils"

export const useContract = () => {
  const { query } = useQuery()

  const editContract = async (contractId, data, advisorId) => {
    const convertedData = convertContractPayload(data, advisorId)
    const options = {
      method: "POST",
      path: `${REACT_APP_API_V2_URL}/subscription-agreements/${contractId}`,
      data: convertedData
    }
    try {
      const { response, error } = await query(options)

      if (error) {
        Sentry.captureException(error, {
          tags: {
            section: "Edit Contract"
          },
          extra: {
            message: `Unable to update contract with Id ${contractId}`
          }
        })
      }
      return response
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          section: "Edit contract"
        },
        extra: {
          message: `Unable to update contract with Id ${contractId}`
        }
      })
    }
  }

  const getContract = async (contractId) => {
    try {
      const options = {
        method: "GET",
        path: `${REACT_APP_API_V2_URL}/subscription-agreements/${contractId}`
      }
      const { response, error } = await query(options)
      if (error) {
        Sentry.captureException(error, {
          tags: {
            section: "Get contract"
          },
          extra: {
            message: `Unable to get contract with Id ${contractId}`
          }
        })
      }
      return response
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          section: "Get contract"
        },
        extra: {
          message: `Unable to get contract with Id ${contractId}`
        }
      })
    }
  }

  return { editContract, getContract }
}
