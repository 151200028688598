import { useNavigate } from "react-router-dom"
import { FaFemale, FaMale } from "react-icons/fa"
import { COUNTRIES } from "@config"
import {
  GENDER_MALE,
  MARRIED,
  MULTIPLE_PAYOUTS,
  QUALIFIED_PURCHASER,
  formatNumberAsCurrency,
  formatDate,
  getSourceOfWealthValue,
  createCypressSlug
} from "@utils"

export default function ClientProfile({ contract, setSelectedContract, setCurrentView }) {
  const generalInfo = contract?.primaryClient
  const clientInfo = contract?.primaryClient?.user
  const spouseInfo = contract?.primaryClient?.spouse
  const navigate = useNavigate()

  const clientGeneralInformation = {
    // generalInfo
    "Full Name": `${clientInfo?.firstName} ${clientInfo?.middleName ?? ""} ${clientInfo?.lastName}`,
    Occupation: generalInfo?.occupation,
    Gender: clientInfo?.gender === GENDER_MALE ? "Male" : "Female",
    "Date of Birth": formatDate(clientInfo?.birthDate),
    "Source of Wealth": getSourceOfWealthValue(generalInfo?.sourceOfWealth),
    "Marital Status": generalInfo?.maritalStatus === MARRIED ? "Married" : "Single",
    // investmentInfo
    "Investor Type": generalInfo?.type === QUALIFIED_PURCHASER ? "Qualified Purchaser" : "Accredited Investor",
    "Amount to Invest": formatNumberAsCurrency(contract.fundingAmount),
    "Investment Fund": contract.fundName,
    "Adviser Fee (in bps)": String(contract.riaFeePercentage),
    [contract.payoutType === MULTIPLE_PAYOUTS ? "Payout Ages" : "Payout Age"]: String(contract.payoutAges),
    // contactInfo
    "Email Address": clientInfo?.email,
    Address: `${clientInfo?.address?.street1}, ${clientInfo?.address?.street2 ?? ""}`,
    City: `${clientInfo?.address?.city}, ${clientInfo?.address?.state}, ${clientInfo?.address?.zipCode}`,
    // citizenshipInfo
    Citizenship: clientInfo?.citizenshipInfo?.isUsCitizen ? "U.S Citizen" : "Non U.S Citizen",
    SSN: clientInfo?.citizenshipInfo?.ssn,
    "Country of Origin": COUNTRIES[clientInfo?.citizenshipInfo?.passportCountry],
    [clientInfo?.citizenshipInfo?.isPermanentResident ? "Green Card Number" : "Visa Number"]: clientInfo?.citizenshipInfo
      ?.isPermanentResident
      ? clientInfo?.citizenshipInfo?.greenCardNumber
      : clientInfo?.citizenshipInfo?.visaNumber,
    [clientInfo?.citizenshipInfo?.isPermanentResident ? "Green Card Expiration Date" : "Visa Expiration Date"]: formatDate(
      clientInfo?.citizenshipInfo?.isPermanentResident
        ? clientInfo?.citizenshipInfo?.greenCardExpirationDate
        : clientInfo?.citizenshipInfo?.visaExpirationDate
    ),
    "Passport Number": clientInfo?.citizenshipInfo?.passportNumber,
    "Passport Expiration Date": formatDate(clientInfo?.citizenshipInfo?.passportExpirationDate),
    // contractStatus
    "Contract Status": contract?.status
  }
  const clientGeneralInfoToRender = ["Occupation", "Gender", "Date of Birth", "Source of Wealth", "Marital Status"]
  const clientInvestmentInfoToRender = [
    "Investor Type",
    "Amount to Invest",
    "Investment Fund",
    "Adviser Fee (in bps)",
    contract.payoutType === MULTIPLE_PAYOUTS ? "Payout Ages" : "Payout Age"
  ]
  const clientContactInfoToRender = ["Email Address", "Address", "City"]
  const clientCitizenshipInfoToRender = [
    "Citizenship",
    "SSN",
    clientInfo?.citizenshipInfo?.passportCountry !== null ? "Country of Origin" : "",
    clientInfo?.citizenshipInfo?.isPermanentResident ? "Green Card Number" : "Visa Number",
    clientInfo?.citizenshipInfo?.isPermanentResident ? "Green Card Expiration Date" : "Visa Expiration Date",
    "Passport Number",
    "Passport Expiration Date"
  ]

  const spouseGeneralInformation = {
    // generalInfo
    "Full Name": `${spouseInfo?.firstName} ${spouseInfo?.middleName ?? ""} ${spouseInfo?.lastName}`,
    Gender: spouseInfo?.gender === GENDER_MALE ? "Male" : "Female",
    "Date of Birth": formatDate(spouseInfo?.birthDate),
    // contactInfo
    [generalInfo?.maritalStatus === MARRIED ? "Email Address" : ""]:
      generalInfo?.maritalStatus === MARRIED ? spouseInfo.email : "",
    // citizenshipInfo
    Citizenship: spouseInfo?.citizenshipInfo?.isUsCitizen ? "U.S Citizen" : "Non U.S Citizen",
    SSN: spouseInfo?.citizenshipInfo?.ssn,
    "Country of Origin": COUNTRIES[spouseInfo?.citizenshipInfo?.passportCountry],
    [spouseInfo?.citizenshipInfo?.isPermanentResident ? "Green Card Number" : "Visa Number"]: spouseInfo?.citizenshipInfo
      ?.isPermanentResident
      ? spouseInfo?.citizenshipInfo?.greenCardNumber
      : spouseInfo?.citizenshipInfo?.visaNumber,
    [spouseInfo?.citizenshipInfo?.isPermanentResident ? "Green Card Expiration Date" : "Visa Expiration Date"]: formatDate(
      spouseInfo?.citizenshipInfo?.isPermanentResident
        ? spouseInfo?.citizenshipInfo?.greenCardExpirationDate
        : spouseInfo?.citizenshipInfo?.visaExpirationDate
    ),
    "Passport Number": spouseInfo?.citizenshipInfo?.passportNumber,
    "Passport Expiration Date": formatDate(spouseInfo?.citizenshipInfo?.passportExpirationDate)
  }

  const spouseGeneralInfoToRender = ["Gender", "Date of Birth"]
  const spouseContactInfoToRender = ["Email Address"]
  const spouseCitizenshipInfoToRender = [
    "Citizenship",
    "SSN",
    spouseInfo?.citizenshipInfo?.passportCountry !== null ? "Country of Origin" : "",
    spouseInfo?.citizenshipInfo?.isPermanentResident ? "Green Card Number" : "Visa Number",
    spouseInfo?.citizenshipInfo?.isPermanentResident ? "Green Card Expiration Date" : "Visa Expiration Date",
    "Passport Number",
    "Passport Expiration Date"
  ]

  const onCloseClientInformation = () => {
    setSelectedContract()
    setCurrentView("contractsTable")
  }

  const handleEditPage = () => {
    navigate(`/editContract/${contract?.id}`)
  }

  return (
    <>
      <div className="is-flex is-justify-content-space-between">
        <button className="button is-primary" onClick={() => onCloseClientInformation()}>
          Close Preview
        </button>
        {contract.status !== "SIGNED" && (
          <button title={`Edit Client Information`} className="button is-primary is-outlined " onClick={handleEditPage}>
            Edit Client Information
          </button>
        )}
      </div>
      <hr />
      <div className="container">
        <div className="card">
          <div className="card-content">
            <div className="content">
              <h3 className="has-text-primary">
                Client Information{" "}
                {clientGeneralInformation.Gender === "Male" ? (
                  <FaMale className="client-icon" />
                ) : (
                  <FaFemale className="client-icon" />
                )}
              </h3>
              <hr />
              <div className="columns">
                <div className="column is-4 has-column-border-r-styled">
                  <div className="is-flex is-flex-direction-column is-justify-content-space-between is-full-height">
                    <div className="">
                      <h2 data-cy={`client-value-full-name`}>{clientGeneralInformation["Full Name"]}</h2>
                      {clientGeneralInfoToRender.map((key, index) => (
                        <p key={index}>
                          <b>{key}</b>:{" "}
                          <span data-cy={`client-value-${createCypressSlug(key)}`}>{clientGeneralInformation[key]}</span>
                        </p>
                      ))}
                    </div>
                    <div className="mt-auto">
                      <p>
                        <b>Contract Status</b>:{" "}
                        <span
                          className={`${
                            clientGeneralInformation["Contract Status"] === "DRAFT" ? "is-warning" : "is-success"
                          } tag is-light is-medium`}
                          data-cy={`client-value-contract-status`}>
                          {clientGeneralInformation["Contract Status"]}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="columns">
                    <div className="column has-column-border-r-styled">
                      <h3 className="is-size-5 has-text-primary">Investment information</h3>
                      {clientInvestmentInfoToRender.map((key, index) => (
                        <p key={index}>
                          <b>{key}</b>:{" "}
                          <span data-cy={`client-value-${createCypressSlug(key)}`}>{clientGeneralInformation[key]}</span>
                        </p>
                      ))}
                      <h3 className="is-size-5 has-text-primary">Contact information</h3>
                      {clientContactInfoToRender.map((key, index) => (
                        <p key={index}>
                          <b>{key}</b>:{" "}
                          <span data-cy={`client-value-${createCypressSlug(key)}`}>{clientGeneralInformation[key]}</span>
                        </p>
                      ))}
                    </div>
                    <div className="column has-column-border-r-styled">
                      <h3 className="is-size-5 has-text-primary">Citizenship information</h3>
                      {clientCitizenshipInfoToRender.map((key, index) => {
                        const keyValue = clientGeneralInformation[key] ?? ""
                        return (
                          keyValue !== "" && (
                            <p key={index}>
                              <b>{key}</b>: <span data-cy={`client-value-${createCypressSlug(key)}`}>{keyValue}</span>
                            </p>
                          )
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {generalInfo?.maritalStatus === MARRIED && (
        <div className="container mt-3">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <h3 className="has-text-primary">
                  Spouse Information{" "}
                  {spouseGeneralInformation?.Gender === "Male" ? (
                    <FaMale className="client-icon" />
                  ) : (
                    <FaFemale className="client-icon" />
                  )}
                </h3>
                <hr />
                <div className="columns">
                  <div className="column is-4 has-column-border-r-styled">
                    <h2 data-cy="spouse-value-full-name">{spouseGeneralInformation["Full Name"]}</h2>
                    {spouseGeneralInfoToRender.map((key, index) => (
                      <p key={index}>
                        <b>{key}</b>:{" "}
                        <span data-cy={`spouse-value-${createCypressSlug(key)}`}>{spouseGeneralInformation[key]}</span>
                      </p>
                    ))}
                  </div>
                  <div className="column">
                    <div className="columns">
                      <div className="column has-column-border-r-styled">
                        <h3 className="is-size-5 has-text-primary">Contact information</h3>
                        {spouseContactInfoToRender.map((key, index) => (
                          <p key={index}>
                            <b>{key}</b>:{" "}
                            <span data-cy={`spouse-value-${createCypressSlug(key)}`}>{spouseGeneralInformation[key]}</span>
                          </p>
                        ))}
                      </div>
                      <div className="column has-column-border-r-styled">
                        <h3 className="is-size-5 has-text-primary">Citizenship information</h3>
                        {spouseCitizenshipInfoToRender.map((key, index) => {
                          const keyValue = spouseGeneralInformation[key] ?? ""
                          return (
                            keyValue !== "" && (
                              <p key={index}>
                                <b>{key}</b>: <span data-cy={`spouse-value-${createCypressSlug(key)}`}>{keyValue}</span>
                              </p>
                            )
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
