import {
  AdvisorGuide,
  AdvisorGuidePdf,
  QualifiedPurchaserGuide,
  QualifiedPurchaserGuidePdf,
  PrivatePlacementMemorandum,
  PrivatePlacementMemorandumPdf,
  SubscriptionAgreement,
  SubscriptionAgreementPdf,
  FundingInstructions,
  FundingInstructionsPdf,
  AdvisorScript,
  AdvisorScriptPdf,
  AccreditedInvestorGuide,
  AccreditedInvestorGuidePdf,
  LimitedPartnershipAgreement,
  LimitedPartnershipAgreementPdf
} from "@assets"

export const BROCHURES = [
  {
    title: "Qualified Purchaser Guide",
    pdfLink: QualifiedPurchaserGuidePdf,
    img: QualifiedPurchaserGuide
  },
  {
    title: "Advisor Guide",
    pdfLink: AdvisorGuidePdf,
    img: AdvisorGuide
  },
  {
    title: "Funding Instructions",
    pdfLink: FundingInstructionsPdf,
    img: FundingInstructions
  },
  {
    title: "Subscription Agreement Template",
    pdfLink: SubscriptionAgreementPdf,
    img: SubscriptionAgreement
  },
  {
    title: "Private Placement Memorandum",
    pdfLink: PrivatePlacementMemorandumPdf,
    img: PrivatePlacementMemorandum
  },
  {
    title: "Limited Partnership Agreement",
    pdfLink: LimitedPartnershipAgreementPdf,
    img: LimitedPartnershipAgreement
  },
  {
    title: "Advisor Script",
    pdfLink: AdvisorScriptPdf,
    img: AdvisorScript
  },
  {
    title: "Accredited Investor Guide",
    pdfLink: AccreditedInvestorGuidePdf,
    img: AccreditedInvestorGuide
  }
]
