import React, { useEffect } from "react"
import { ContractInput, StepsProgressBar } from "@components/contractForm"
import { ContractDateInput, ContractTextInput, ToggleButton } from "@components/inputs"
import { COUNTRIES } from "@config"

const SsnInfo = (props) => {
  const { data, inputChanged, setValidations } = props

  useEffect(() => {
    let validations = [
      {
        field: "ssn",
        type: "required"
      }
    ]

    if (data.isUSCitizen === "N") {
      validations = [
        ...validations,
        {
          field: "isGreenCard",
          type: "required"
        },
        {
          field: "alienIdOrVisa",
          type: "required"
        },
        {
          field: "alienIdOrVisaExpiration",
          type: "required"
        },
        {
          field: "alienIdOrVisaExpiration",
          type: "max10years"
        },
        {
          field: "passportNumber",
          type: "required"
        },
        {
          field: "passportExpiration",
          type: "required"
        },
        {
          field: "passportExpiration",
          type: "max10years"
        },
        {
          field: "passportCountry",
          type: "required"
        }
      ]
    }
    setValidations(validations)
  }, [data.isUSCitizen])

  return (
    <>
      <StepsProgressBar step={2} />
      <div className="columns is-justify-content-space-around is-flex-wrap-wrap">
        <div className="column is-8">
          <div id="ssn-info">
            <h1 className="is-size-3 mb-4 is-mobile-centered has-text-weight-bold has-text-primary">
              Is the client a U.S. Citizen?
            </h1>
            <ToggleButton
              value={data.isUSCitizen}
              sideA={"Y"}
              sideB={"N"}
              labelA={"Yes"}
              labelB={"No"}
              setState={(value) => inputChanged("isUSCitizen", value)}
            />

            {data.isUSCitizen === "Y" && (
              <ContractTextInput {...props} title="Social Security Number (SSN)" field="ssn" mask={"ssn"} />
            )}
            {data.isUSCitizen === "N" && (
              <>
                <p>What is their resident status?</p>
                <div>
                  <ToggleButton
                    value={data.isGreenCard}
                    labelA={"U.S Permanent Resident (Green Card)"}
                    labelB={"U.S Visa Holder"}
                    sideA={"Y"}
                    sideB={"N"}
                    setState={(value) => inputChanged("isGreenCard", value)}
                  />
                </div>
                <br />
                <div className="columns is-multiline">
                  <div className="column is-6">
                    <ContractTextInput
                      {...props}
                      title="Social Security Number (SSN)"
                      field="ssn"
                      mask={"ssn"}
                      placeholder="123-45-6789"
                    />
                  </div>
                  <ContractInput
                    {...props}
                    type="dropdown"
                    title="Country of Origin"
                    field="passportCountry"
                    values={Object.keys(COUNTRIES).map((country) => ({ title: COUNTRIES[country], value: country }))}
                  />
                  <div className="column is-6">
                    <ContractTextInput
                      {...props}
                      title={data.isGreenCard === "Y" ? "USCIS #" : "Visa Number"}
                      mask={data.isGreenCard === "Y" ? "uscis" : "visa"}
                      max={data.isGreenCard === "Y" ? 11 : 19}
                      field="alienIdOrVisa"
                    />
                  </div>
                  <div className="column is-6">
                    <ContractDateInput
                      {...props}
                      title={`${data.isGreenCard === "Y" ? "USCIS #" : "Visa"} Expiration Date`}
                      field="alienIdOrVisaExpiration"
                    />
                  </div>

                  <div className="column is-6">
                    <ContractTextInput
                      {...props}
                      title="Passport Number"
                      field="passportNumber"
                      mask={"passport"}
                      max="9"
                      placeholder="A12345678"
                    />
                  </div>

                  <div className="column is-6">
                    <ContractDateInput {...props} title="Passport Expiration Date" field="passportExpiration" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SsnInfo
