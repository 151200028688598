import { formatNumberAsCurrency, formatValueAsFloat } from "./"
import { FaRegEye } from "react-icons/fa"

const formatDate = (date) => {
  if (!date) {
    return "Invalid date"
  }

  const formattedDate = new Date(date?.updatedAt).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric"
  })

  return formattedDate
}

export const getContractDataValues = (value) => {
  const contractId = value?.id
  const contractModified = formatDate(value)
  const fullName = `${value?.primaryClient?.user?.firstName} ${value?.primaryClient?.user?.lastName}`
  const contractKeyValue = value?.id

  const funds = {
    renderValue: formatNumberAsCurrency(value?.fundingAmount),
    sortValue: formatValueAsFloat(value?.fundingAmount)
  }

  const contractStatus = {
    renderValue: `${
      value?.status === "DRAFT" ? "is-warning" : value?.status === "SENT" ? "is-info" : "is-success"
    } tag is-light is-medium`,
    sortValue: value?.status
  }

  return { contractId, contractModified, fullName, contractKeyValue, funds, contractStatus }
}

export const contractKeyValue = (value) => {
  return getContractDataValues(value).contractKeyValue
}

export const createColumnSettings = (openContractPreview, openClientInformation) => {
  return [
    {
      label: "CONTRACT",
      render: (id) => (
        <button
          value={getContractDataValues(id).contractId}
          onClick={() => openContractPreview(id)}
          className="button is-primary"
          data-cy={`preview-contract-${getContractDataValues(id).contractId}`}>
          Preview Contract
        </button>
      )
    },
    {
      label: "DATE MODIFIED",
      render: (date) => formatDate(date),
      sortColumn: (date) => new Date(date?.updatedAt).getTime()
    },
    {
      label: "FULL NAME",
      render: (fullName) => getContractDataValues(fullName).fullName,
      sortColumn: (fullName) => getContractDataValues(fullName).fullName
    },
    {
      label: "FUNDS",
      render: (fund) => getContractDataValues(fund).funds.renderValue,
      sortColumn: (fund) => getContractDataValues(fund).funds.sortValue
    },
    {
      label: "CONTRACT STATUS",
      render: (contractStatus) => (
        <span className={getContractDataValues(contractStatus).contractStatus.renderValue}>
          {getContractDataValues(contractStatus).contractStatus.sortValue}
        </span>
      ),
      sortColumn: (contractStatus) => getContractDataValues(contractStatus).contractStatus.sortValue
    },
    {
      label: "ACTIONS",
      render: (value) => (
        <FaRegEye
          className="is-clickable mr-1"
          title="View Client Information"
          onClick={() => openClientInformation(value)}
          value={value.id}
          data-cy={`view-client-profile-${getContractDataValues(value).contractId}`}
        />
      )
    }
  ]
}
