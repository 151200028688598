import React from "react"

export const IdleModal = ({ isModalOpen, remaining, handleStillHere, logout, instance }) => {
  return (
    <div className={`modal ${isModalOpen ? "is-active" : null}`} data-cy="idle-modal">
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="box box-container modal-default-height">
          <div className="content is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
            <h1 className="title has-text-primary has-text-centered">Your session is about to expire</h1>
            <div className="card is-rounded has-background-white-ter has-text-centered">
              <p className="card-content pb-0 px-6">Your session will expire soon and you will be logged out automatically in:</p>
              <p className="is-size-2 pb-5">00:{remaining} seconds</p>
            </div>
          </div>
          <div className="field is-flex is-justify-content-center m-3">
            <div className="control">
              <button className="button is-primary mr-2" onClick={() => logout(instance)} data-cy="idle-modal-logout-btn">
                Logout
              </button>
              <button className="button is-primary is-outlined ml-2" onClick={handleStillHere} data-cy="idle-modal-reconnect-btn">
                I'm still here
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
