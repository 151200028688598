import React, { useRef } from "react"
import { useNavigate } from "react-router-dom"
import { MultiFileDropzone } from "@components/contractForm"
import { useUploadFile } from "@hooks"
import { IconNumberOne, IconNumberTwo, IconNumberThree } from "@assets"

export const UploadDocumentModal = ({ isModalOpen, toggleModal, setFiles, files, clientId }) => {
  const { uploadFile } = useUploadFile()
  const navigate = useNavigate()
  const filesCount = useRef(files.length)

  const onSubmitFiles = async () => {
    let errors = 0

    for (const { file } of files) {
      const fileResponse = await uploadFile(file, clientId)
      if (!fileResponse) errors += 1
    }
    if (errors === 0) navigate("/contracts")
  }
  return (
    <div className={`modal ${isModalOpen ? "is-active" : null}`}>
      <div className="modal-background" onClick={toggleModal}></div>
      <div className="modal-content retry-upload-modal">
        <div className="modal-content">
          <div className="box box-container">
            <div className="content is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
              <div className="columns is-12 is-justify-content-space-around">
                <MultiFileDropzone files={files} setFiles={setFiles} />
              </div>
              <div className="column is-12">
                <p className="has-text-centered">
                  <span className="has-text-black has-text-weight-bold">To be compliant</span> <br />
                  Savvly needs the following documents.
                </p>
                <ul>
                  <li className="is-flex is-align-items-center mt-2 mb-3">
                    <img src={IconNumberOne} alt="icon number one" className="number-list" />
                    <span>Driver License or ID or Passport as Proof of identity</span>
                  </li>
                  <li className="is-flex is-align-items-center mt-2 mb-3">
                    <img src={IconNumberTwo} alt="icon number two" className="number-list" />
                    <span>W9 Form as Proof of income tax</span>
                  </li>
                  <li className="is-flex is-align-items-center mt-2 mb-3">
                    <img src={IconNumberThree} alt="icon number three" className="number-list" />
                    <span>Bank Statement or Utility Bill as Proof of residency</span>
                  </li>
                </ul>
              </div>
              <div className="has-text-centered">
                {filesCount.current === files.length && (
                  <p className="is-5 has-text-danger has-text-centered">
                    Please try again <br /> Something went wrong uploading your documents.
                  </p>
                )}
                <button className="button is-primary mr-3" onClick={() => onSubmitFiles()}>
                  Send Files
                </button>
              </div>
            </div>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={toggleModal}></button>
      </div>
    </div>
  )
}
