import React from "react"
import { InputField } from "@components/inputs"

export const AdvancedDetailsForm = ({ register, errors, watch }) => {
  const currentAge = watch("currentAge", 0)
  const retirementAge = watch("retirementAge", 0)

  return (
    <>
      <div className="column is-12">
        <InputField
          type="number"
          name="retirementAge"
          labelText="Retirement Age"
          placeholder="Enter your expected retirement age"
          tooltipText="If you were born in 1960 or later, 67 is when you can retire with full benefits."
          register={register}
          errors={errors}
          rules={{
            required: true,
            validate: (value) => value > currentAge || "Retirement age must be greater than current age"
          }}
        />
      </div>
      <div className="column is-12">
        <InputField
          type="number"
          name="lifeExpectancy"
          labelText="Life Expectancy"
          placeholder="Enter your estimated life expectancy"
          tooltipText="How long do you expect to live? This is how long you'll need your retirement savings to last."
          register={register}
          errors={errors}
          rules={{
            required: true,
            validate: (value) => value > retirementAge || "Life expectancy must be greater than retirement age",
            valueAsNumber: true
          }}
        />
      </div>
      <div className="column is-12">
        <InputField
          type="number"
          name="preRetirementRateOfReturn"
          labelText="Pre-Retirement Rate of Return"
          placeholder="Your expected annual return before retirement"
          tooltipText="What do you expect your investments to earn between now and retirement? Our default of a 8% average annual return is a standard estimate based on historic returns."
          register={register}
          errors={errors}
          rules={{ required: true }}
        />
      </div>
      <div className="column is-12">
        <InputField
          type="number"
          name="postRetirementRateOfReturn"
          labelText="Post-Retirement Rate of Return"
          placeholder="Your expected annual return during retirement"
          tooltipText="Your rate of return during retirement is typically lower than pre-retirement because most people invest in lower-risk investments."
          register={register}
          errors={errors}
          rules={{ required: true }}
        />
      </div>
      <div className="column is-12">
        <InputField
          type="number"
          name="inflationRate"
          labelText="Inflation Rate"
          placeholder="Enter expected inflation rate"
          register={register}
          errors={errors}
          rules={{ required: true }}
        />
      </div>
      <div className="column is-12">
        <InputField
          type="number"
          name="annualIncomeIncrease"
          labelText="Annual Income Increase"
          placeholder="Enter any expected annual income increase"
          register={register}
          errors={errors}
          rules={{ required: true }}
        />
      </div>
    </>
  )
}
