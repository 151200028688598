export const FREQUENTLY_ASKED_QUESTIONS = [
  {
    question: "What makes Savvly work?",
    answer: `Savvly is an alternative investment fund that makes risk pooling accessible to your clients. Risk pooling is the same
        concept behind most annuities, pension plans, and even social security —every investor contributes, and those who live
        the longest derive the most benefit.`
  },
  {
    question: "Is it insurance?",
    answer: `No. Savvly is not an insurance policy or annuity. There is no insurance company taking profits from the Endowment
    Pool. Instead, that money goes to the investors who reach their Endowment Ages.`
  },
  {
    question: "Is it a traditional investment fund?",
    answer: `No. Client money is invested in a brand name fund that tracks a major stock market index. Savvly does not manage
    this fund. It is held by a third-party custodian.`
  },
  {
    question: "Which accounts are eligible?",
    answer: `Clients should use after-tax funding sources.`
  },
  {
    question: "When and how is the payout received?",
    answer: `Upon reaching Endowment Age, your client will have access to fund shares equal to the principal and returns of the
    index fund, plus their share of the Endowment Pool in a single in-kind transfer of assets. This transfer is
    executed under the Limited Partnership agreement such that it is intended to be made to the client only and
    designed not to be assigned to anyone else.`
  },
  {
    question: "Can clients distribute their investments across several payout ages?",
    answer: `Yes, as long as the total amount invested meets the minimum requirement.`
  },
  {
    question: "What if my client dies before payout age?",
    answer: ` Their beneficiary will receive 25% of the minimum of the initial contribution and the market value of the initial
    contribution less fees and expenses.`
  },
  {
    question: "Is there an option for couples?",
    answer: `Couples can invest in Savvly independently as long as the total amount invested meets the minimum requirement.`
  },
  {
    question: "Why has nobody offered this before?",
    answer: `Savvly has developed the proprietary AI, actuarial algorithms, and blockchain accounting to be able to accurately
    and equitably manage its unique Endowment Pool. Savvly was created by a team of financial industry executives,
    data scientists, and digital product designers. Savvly’s new model for longevity risk protection was fostered by a
    new wave of regulations aimed at helping Americans experience greater prosperity in retirement.`
  }
]
