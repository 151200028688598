import swal from "sweetalert2"

export const AlertType = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning"
}

export const SAVED_CONTRACT = "Contract saved."
export const UPDATED_CONTRACT = "Contract updated."
export const SAVING_CONTRACT_AS_DRAFT = "Saving contract as draft."
export const SAVE_CONTRACT_AS_DRAFT = "Contract saved as draft."
export const SENDING_CONTRACT_BY_EMAIL = "Sending contract by email."
export const CONTRACT_NOT_SENT = "Unable to send contract."
export const UNABLE_TO_SEND_CONTRACT =
  "Contract was created in draft state but we were unable to send. Please try sending it again."
export const CONTRACT_SENT = "Contract sent successfully"
export const CONTRACT_SAVED_AND_SENT = "Contract sent by email! Please check your inbox."
export const EDITING_CONTRACT_MESSAGE = "Updating contract details"
export const CONTRACT_EDITED_AND_SAVED_MESSAGE = "Contract updated successfully!"
export const LOADING_CONTRACT_INFORMATION = "Loading contract information. Please wait..."
export const CONTRACT_INFORMATION_SUCCESS_TITLE = "Contract Information Retrieved"
export const CONTRACT_INFORMATION_SUCCESS_MESSAGE = "The contract information has been successfully retrieved."

export const showAlertMessage = (type, title, text) => {
  const icon = AlertType[type] || null
  swal.fire({ icon, title, text })
}

export const confirmationAlertMessage = (icon, title, text) => {
  swal.fire({
    icon,
    title,
    text,
    showConfirmButton: false,
    timer: 1500
  })
}
export const promiseAlertMessage = (title) => {
  swal.fire({
    title,
    allowEscapeKey: false,
    allowOutsideClick: false,
    didOpen: () => {
      swal.showLoading()
    }
  })
}
