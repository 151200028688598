import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { NotFoundAnimation, SavvlyLogoBlack } from "@assets"

const PageNotFound = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/register") {
      navigate("/")
    }
  }, [])

  return (
    <>
      <div className="has-text-centered mt-1">
        <img src={SavvlyLogoBlack} alt="Savvly Inc. logo" />
      </div>
      <div className="container">
        <div className="columns is-flex is-align-items-center">
          <div className="column has-text-centered">
            <h1 className="title has-text-primary">404 Page Not Found</h1>
            <p className="subtitle">Nothing to be found here.</p>
            <a className="button is-primary mr-3" href="/">
              Go to Dashboard
            </a>
            <a className="button is-primary is-outlined" href="https://savvly.com">
              Go to Home Page
            </a>
          </div>
          <div className="column has-text-centered">
            <img src={NotFoundAnimation} alt="Savvly not found animation" />
          </div>
        </div>
      </div>
    </>
  )
}

export default PageNotFound
