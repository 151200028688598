import { LumpSumPayoutMemoized, MultiplePayoutsSimulationMemoized } from "@components/estimator"

export const EstimatorSimulation = ({
  investment,
  lumpSumPayoutData,
  multiplePayoutsData,
  lumpSumChartOptions,
  multiplePayoutsChartOptions,
  lumpSumPayoutRef,
  multiplePayoutsRef
}) => {
  return (
    <>
      <div className="column is-one-third-desktop is-12-tablet">
        <div
          className={`box is-flex is-flex-direction-column has-text-centered pt-4 pb-6 ${
            investment === "Lump Sum" && "has-background-light-orange"
          }`}>
          <LumpSumPayoutMemoized data={lumpSumPayoutData} options={lumpSumChartOptions} lumpSumRef={lumpSumPayoutRef} />
        </div>
      </div>
      <div className="column is-8-desktop is-12-tablet">
        <div
          className={`box has-text-centered multiple-payouts-height pt-4 pb-6 ${
            investment === "Multiple Payouts" && "has-background-light-orange"
          }`}>
          <MultiplePayoutsSimulationMemoized
            chartData={multiplePayoutsData}
            options={multiplePayoutsChartOptions}
            multiplePayoutsRef={multiplePayoutsRef}
          />
        </div>
      </div>
    </>
  )
}
