import * as Sentry from "@sentry/react"
import { REACT_APP_API_V2_URL } from "@config"

export const saveAdviser = async (idTokenClaims, query) => {
  try {
    const adviserData = {
      id: idTokenClaims?.oid,
      firstName: idTokenClaims.given_name,
      lastName: idTokenClaims.family_name,
      email: idTokenClaims.emails[0],
      crdNumber: idTokenClaims.extension_crd_number || null,
      managesQpAi: idTokenClaims.extension_manages_purchasers_or_investors,
      isRiaEmployed: idTokenClaims.extension_is_company,
      isIndividualAdviser: idTokenClaims.extension_is_individual,
      ...(idTokenClaims.extension_is_company && {
        firm: {
          name: idTokenClaims.extension_company_name,
          crdNumber: idTokenClaims.extension_company_crd_number,
          state: idTokenClaims.state
        }
      })
    }

    const { error } = await query({
      path: `${REACT_APP_API_V2_URL}/advisers`,
      data: JSON.stringify(adviserData),
      method: "POST"
    })
    if (error) {
      Sentry.captureException(error, {
        tags: {
          section: "Save adviser"
        },
        extra: {
          message: "Cannot save adviser account information",
          errors: error
        }
      })
    }
  } catch (e) {
    Sentry.captureException(e, {
      tags: {
        section: "Save adviser"
      },
      extra: {
        message: "There was an error saving adviser account information",
        errors: e
      }
    })
  }
}
