import { useEffect, useState } from "react"
import { PdfPreview } from "@components/pdf"
import { PdfErrorMessage } from "@components"
import {
  BAD_RESPONSE_MESSAGE,
  ERROR_PREVIEWING_CONTRACT,
  ERROR_PREVIEWING_TITLE,
  ERROR_SENDING_CONTRACT,
  FAILED_SENDING_CONTRACT,
  SERVER_ERROR_MESSAGE,
  REACT_APP_API_V2_URL
} from "@config"
import {
  CONTRACT_SAVED_AND_SENT,
  CONTRACT_SENT,
  promiseAlertMessage,
  SENDING_CONTRACT_BY_EMAIL,
  showAlertMessage,
  AlertType
} from "@components/notification"
import { useQuery } from "@hooks"

const ContractPreview = ({ setSelectedContract, contract, setCurrentView }) => {
  const { query } = useQuery()
  const [pdfFile, setPdfFile] = useState()
  const [pdfError, setPdfError] = useState(null)

  const generatePdf = async () => {
    try {
      const { response, error } = await query({
        path: `${REACT_APP_API_V2_URL}/subscription-agreements/${contract.id}/preview`
      })

      if (error) {
        setPdfError(SERVER_ERROR_MESSAGE)
        return
      }
      if (response && response.data) {
        setPdfFile(`data:application/pdf;base64,${response.data}`)
      } else {
        throw new Error(BAD_RESPONSE_MESSAGE)
      }
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          section: "Contract preview"
        },
        extra: {
          message: "Cannot preview contract"
        }
      })
      showAlertMessage(AlertType.ERROR, ERROR_PREVIEWING_TITLE, ERROR_PREVIEWING_CONTRACT)
    }
  }

  const sendContract = async () => {
    promiseAlertMessage(SENDING_CONTRACT_BY_EMAIL)

    try {
      const { response } = await query({
        path: `${REACT_APP_API_V2_URL}/subscription-agreements/${contract.id}/send`,
        method: "POST"
      })

      if (response) {
        showAlertMessage(AlertType.SUCCESS, CONTRACT_SENT, CONTRACT_SAVED_AND_SENT)
        setSelectedContract("")
        setCurrentView("contractsTable")
      }
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          section: "Contract creation"
        },
        extra: {
          message: "Cannot send contract"
        }
      })
      showAlertMessage(AlertType.ERROR, ERROR_SENDING_CONTRACT, FAILED_SENDING_CONTRACT)
      return false
    }
  }

  useEffect(() => {
    generatePdf()
  }, [contract.id])

  const onCloseContractPreview = () => {
    setSelectedContract("")
    setCurrentView("contractsTable")
  }

  return (
    <>
      <div className="buttons">
        <button className="button is-primary is-outlined" onClick={() => onCloseContractPreview()}>
          Close Preview
        </button>
        {pdfFile && contract.status === "DRAFT" && (
          <button className="button is-primary" onClick={sendContract}>
            Send Contract to {contract.primaryClient.user.email}
          </button>
        )}
      </div>
      <hr />
      {pdfError ? <PdfErrorMessage error={pdfError} /> : <PdfPreview pdf={pdfFile} />}
    </>
  )
}

export default ContractPreview
