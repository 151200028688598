import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { FileDropzone } from "@components/contractForm"
import { IconUploadFiles } from "@assets"

export function MultiFileDropzone({ files, setFiles }) {
  const onDrop = useCallback((accFiles, rejFiles) => {
    const mappedAcc = accFiles.map((file) => ({ file }))
    const mappedRej = rejFiles.map((r) => ({ ...r }))
    setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej])
  }, [])

  function onUpload(file) {
    setFiles((curr) =>
      curr.map((fw) => {
        if (fw.file === file) {
          return { ...fw }
        }
        return fw
      })
    )
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  })

  return (
    <>
      <div>
        <input {...getInputProps()} data-cy="multi-file-dropzone" />
        <div className="card is-rounded drag-drop-container has-text-centered">
          <div className="card-content" {...getRootProps()}>
            <div className="is-flex flex-direction-row is-justify-content-center is-align-items-center">
              <div className="field is-flex is-flex-direction-column is-align-items-center">
                <div className="container pt-6">
                  <img src={IconUploadFiles} className="icon-drag-drop" alt="icon upload files" />
                  <p className="has-text-grey">Drag &amp; Drop Files Here to Upload</p>
                  <p className="has-text-grey">or</p>
                  <button className="button is-outlined has-text-grey" type="file">
                    Browse Files
                  </button>
                </div>
              </div>
            </div>
          </div>
          {files.map((fileWrapper, idx) => (
            <div
              key={idx}
              className="is-flex flex-direction-row is-justify-content-space-between is-align-content-center is-align-items-center p-3 is-flex-wrap-nowrap is-italic">
              {fileWrapper.file && <FileDropzone setFiles={setFiles} onUpload={onUpload} file={fileWrapper.file} />}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
