import * as Sentry from "@sentry/react"
import axios from "axios"
import { REACT_APP_ESTIMATOR_API_URL } from "@config"

export const getMultiplePayouts = async (multiplePayoutsUrl) => {
  try {
    const { data } = await axios.get(`${REACT_APP_ESTIMATOR_API_URL}/${multiplePayoutsUrl}`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    if (data) return data
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        section: "Fetch Multiple Payouts"
      },
      extra: {
        message: "Cannot fetch data"
      }
    })
  }
}
