export const validateFields = (watchedValues, setError, clearErrors) => {
  let isValid = true

  const currentAge = Number(watchedValues?.currentAge)
  const retirementAge = Number(watchedValues?.retirementAge)
  const lifeExpectancy = Number(watchedValues?.lifeExpectancy)

  if (currentAge < 18 || currentAge > 100) {
    setError("currentAge", { type: "custom", message: "Current age must be between 18 and 100" })
    isValid = false
  } else {
    clearErrors("currentAge")
  }

  if (retirementAge < currentAge) {
    setError("retirementAge", { type: "custom", message: "Retirement age must be greater than current age" })
    isValid = false
  } else {
    clearErrors("retirementAge")
  }

  if (lifeExpectancy < retirementAge) {
    setError("lifeExpectancy", { type: "custom", message: "Life expectancy must be greater than retirement age" })
    isValid = false
  } else {
    clearErrors("lifeExpectancy")
  }

  return isValid
}
