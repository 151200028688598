import * as Sentry from "@sentry/react"
import { useState, useEffect, useRef } from "react"
import { Chart } from "chart.js/dist/chart"
import { chartPlusImagePlugin } from "@utils"

const useChart = (chartData, options, chartImages) => {
  const [chartInstance, setChartInstance] = useState(null)
  const chartRef = useRef(null)
  const { PlusSymbolEstimator } = chartImages

  useEffect(() => {
    const imgPlusSymbol = new Image()
    imgPlusSymbol.src = PlusSymbolEstimator

    const plusImageOnBackground = chartPlusImagePlugin(imgPlusSymbol)

    let myChartRef
    if (chartRef.current) {
      myChartRef = chartRef.current.getContext("2d")
    } else {
      return
    }

    const createChart = (imageLoadError) => {
      try {
        const chart = new Chart(myChartRef, {
          type: "bar",
          data: chartData,
          options,
          plugins: imageLoadError ? [] : [plusImageOnBackground]
        })
        setChartInstance(chart)
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            section: "useChart Hook"
          },
          extra: {
            message: "Chart Error"
          }
        })
      }
    }

    const destroyChart = () => {
      if (chartInstance) {
        chartInstance.destroy()
        setChartInstance(null)
      }
    }

    if (chartInstance) {
      destroyChart()
    }

    const handleLoad = () => createChart(false)
    const handleError = () => createChart(true)

    imgPlusSymbol.addEventListener("load", handleLoad)
    imgPlusSymbol.addEventListener("error", handleError)

    return () => {
      imgPlusSymbol.removeEventListener("load", handleLoad)
      imgPlusSymbol.removeEventListener("error", handleError)
      destroyChart()
    }
  }, [chartData, options, PlusSymbolEstimator])

  return chartRef
}

export default useChart
