import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export function ToastNotification({ msg, toastType }) {
  const defaultConfig = {
    type: toastType,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    isLoading: false
  }

  toast(msg, defaultConfig)
}
