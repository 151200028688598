import React, { useState } from "react"
import { FaAngleUp, FaAngleDown } from "react-icons/fa"
import { FREQUENTLY_ASKED_QUESTIONS } from "@utils"

const Collapse = ({ data }) => {
  const [state, setState] = useState({ cardState: false })
  const { cardState } = state

  const toggleCardState = () => {
    setState({ cardState: !state.cardState })
  }
  return (
    <div className="column is-12">
      <div className="card" aria-hidden={cardState ? "false" : "true"} data-cy={`faq-card-${data.question}`}>
        <header className="faq-card-header is-clickable" onClick={toggleCardState}>
          <div className="is-flex is-align-items-center is-justify-content-space-between">
            <p className="has-text-primary card-header-title">{data.question}</p>
            {cardState ? <FaAngleUp className="pr-2 has-text-primary" /> : <FaAngleDown className="pr-2 has-text-primary" />}
          </div>
        </header>
        <div className={`card-content faq-card-content ${cardState ? "px-5 py-4" : "card-content-hide p-0"}`}>
          <div className="content" data-cy={`faq-card-content-${data.question}`}>
            {data.answer}
          </div>
        </div>
      </div>
    </div>
  )
}

const FAQ = () => {
  return (
    <div className="container p-4 mt-5-non-desktop">
      <div className="is-flex is-justify-content-space-between">
        <h1 className="title has-text-primary">Frequently asked questions</h1>
      </div>
      <hr />
      <section className="section">
        <div className="container">
          <div className="is-multiline">
            {FREQUENTLY_ASKED_QUESTIONS.map((data, i) => (
              <div key={i}>
                <Collapse data={data} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

export default FAQ
