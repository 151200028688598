import React from "react"
import { ContractDateInput, ContractTextInput, ContractMoneyInput, ContractDropdown, ToggleButton } from "@components/inputs"

const ContractInput = (props) => {
  const { size = 6, type = "text" } = props

  const inputs = {
    text: <ContractTextInput />,
    date: <ContractDateInput />,
    toggle: <ToggleButton />,
    money: <ContractMoneyInput />,
    dropdown: <ContractDropdown />
  }

  const component = React.cloneElement(inputs[type], { ...props })

  return <div className={`column is-${size}`}>{component}</div>
}

export default ContractInput
