import { useEffect } from "react"
import { ACCREDITED_INVESTOR, GENDER_FEMALE, GENDER_MALE, MARRIED, QUALIFIED_PURCHASER, SINGLE } from "@utils"
import { ContractInput, StepsProgressBar } from "@components/contractForm"
import { SOURCE_OF_WEALTH } from "@config"

const ClientInformation = (props) => {
  const { setValidations, data, inputChanged } = props

  useEffect(() => {
    let validations = [
      {
        field: "firstName",
        type: "required"
      },
      {
        field: "lastName",
        type: "required"
      },
      {
        field: "occupation",
        type: "required"
      },
      {
        field: "sourceOfWealth",
        type: "required"
      },
      {
        field: "sex",
        type: "required"
      },
      {
        field: "birthDate",
        type: "required"
      },
      {
        field: "birthDate",
        type: "18yo"
      }
    ]
    if (data.isMarried === MARRIED) {
      validations = [
        ...validations,
        {
          field: "spouseFirstName",
          type: "required"
        },
        {
          field: "spouseLastName",
          type: "required"
        },
        {
          field: "spouseBirthDate",
          type: "required"
        },
        {
          field: "spouseBirthDate",
          type: "18yo"
        }
      ]
    }
    setValidations(validations)
  }, [data.isMarried])

  return (
    <div>
      <StepsProgressBar step={1} />
      <div className="columns is-justify-content-space-around is-flex-wrap-wrap">
        <div className="column is-8-desktop is-7-tablet">
          <h1 className="is-size-3 mb-4 is-mobile-centered has-text-weight-bold has-text-primary">Tell us about the client</h1>
          <div className="columns is-multiline">
            <ContractInput
              size="12"
              value={data.purchaserType}
              type="toggle"
              labelA={"Qualified Purchaser"}
              labelB={"Accredited Investor"}
              sideA={QUALIFIED_PURCHASER}
              sideB={ACCREDITED_INVESTOR}
              setState={(val) => {
                inputChanged("purchaserType", val)
              }}
            />
            <ContractInput {...props} title={"First Name"} field={"firstName"} size={4} />
            <ContractInput {...props} title={"Middle Name"} field={"middleName"} size={4} />
            <ContractInput {...props} title={"Last Name"} field={"lastName"} size={4} />
            <ContractInput {...props} title={"Occupation"} field={"occupation"} />
            <ContractInput
              {...props}
              type="dropdown"
              title="Source of Wealth"
              field="sourceOfWealth"
              values={SOURCE_OF_WEALTH.map((source) => ({ title: source.title, value: source.value }))}
            />
          </div>
          <div className="columns is-multiline">
            <ContractInput {...props} type="date" title="Date of Birth" field="birthDate" />
            <ContractInput
              {...props}
              type="dropdown"
              title="Marital Status"
              field="isMarried"
              values={[
                {
                  title: "Married",
                  value: MARRIED
                },
                {
                  title: "Single",
                  value: SINGLE
                }
              ]}
            />
            <ContractInput
              type="toggle"
              value={data.sex}
              title="Gender"
              sideA={GENDER_MALE}
              sideB={GENDER_FEMALE}
              labelA="Male"
              labelB="Female"
              setState={(val) => {
                inputChanged("sex", val)
              }}
            />
          </div>
        </div>
        {data.isMarried === MARRIED && (
          <div className="column is-4-desktop is-5-tablet spouse-info my-4" data-cy="spouse-info">
            <div className="p-3">
              <h1 className="is-size-4 has-text-weight-normal">Tell us about their spouse</h1>
              <div className="columns is-multiline">
                <ContractInput {...props} size="12" title="Spouse First Name" field="spouseFirstName" />
                <ContractInput {...props} size="12" title="Spouse Middle Name" field="spouseMiddleName" />
                <ContractInput {...props} size="12" title="Spouse Last Name" field="spouseLastName" />
                <ContractInput {...props} size="12" type="date" title="Spouse Date of Birth" field="spouseBirthDate" />
                <ContractInput
                  size="12"
                  type="toggle"
                  title="Spouse Gender"
                  value={data.spouseGender}
                  sideA="M"
                  sideB="F"
                  labelA="Male"
                  labelB="Female"
                  setState={(val) => {
                    inputChanged("spouseGender", val)
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ClientInformation
