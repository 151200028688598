import { useMsal } from "@azure/msal-react"
import { NavLink } from "react-router-dom"
import React, { useState } from "react"
import { BackgroundImage } from "./BackgroundImage"
import { useAppContext } from "@context"
import { SavvlyLogo, BlackPattern } from "@assets"

const Sidebar = () => {
  const [isActive, setIsActive] = useState(false)

  const { instance } = useMsal()
  const { homeAccountId } = useAppContext()

  // This is a comment to test cypress config
  const logout = (instance) => {
    instance.logoutRedirect({
      account: instance.getAccountByHomeId(homeAccountId),
      postLogoutRedirectUri: "/"
    })
  }

  const backgroundStyles = {
    backgroundImage: `url(${BlackPattern})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  }

  return (
    <>
      <div>
        <div className="sidebar-logo is-flex is-justify-content-center is-align-items-center p-4">
          <a href="/">
            <img src={SavvlyLogo} alt="Savvly Inc. logo" />
          </a>
          <a
            onClick={() => {
              setIsActive(!isActive)
            }}
            role="button"
            className={`navbar-burger has-text-white is-tablet-hide burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navigation-bar-mobile">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <BackgroundImage
          style={backgroundStyles}
          id="navigation-bar-mobile"
          className={`is-hidden-desktop navbar-menu has-text-weight-light is-size-6-mobile is-relative ${
            isActive ? "is-active" : "burger-is-not-active"
          }`}>
          <div className="navbar-start">
            <a className="navbar-item has-text-weight-light has-text-white px-5 py-3" href="/">
              Home
            </a>
            <a className="navbar-item has-text-weight-light has-text-white px-5 py-3" href="/contracts">
              Clients + Contracts
            </a>
            <a className="navbar-item has-text-weight-light has-text-white px-5 py-3" href="/estimator">
              Estimator
            </a>
            <a className="navbar-item has-text-weight-light has-text-white px-5 py-3" href="/documentation">
              Documentation
            </a>
            <a className="navbar-item has-text-weight-light has-text-white px-5 py-3" href="/faq">
              FAQs
            </a>
            <a
              href="#"
              onClick={() => logout(instance)}
              className="navbar-item logout-btn has-text-weight-medium has-text-white has-background-black px-5 py-3">
              Logout
            </a>
          </div>
        </BackgroundImage>
        <div className="nav-links is-flex is-flex-direction-column mt-5">
          <NavLink className="nav-link p-5 is-clickable" to="/" end data-cy="sidebar-home">
            Home
          </NavLink>
          <NavLink className="nav-link p-5 is-clickable" to="/contracts" data-cy="sidebar-clients-and-contracts">
            Clients + Contracts
          </NavLink>
          <NavLink className="nav-link p-5 is-clickable" to="/estimator" data-cy="sidebar-estimator">
            Estimator
          </NavLink>
          <NavLink className="nav-link p-5 is-clickable" to="/documentation" data-cy="sidebar-documentation">
            Documentation
          </NavLink>
          <NavLink className="nav-link p-5 is-clickable" to="/faq" data-cy="sidebar-faq">
            FAQs
          </NavLink>
        </div>
      </div>
      <div className="sidebar-footer is-flex is-justify-content-center is-flex-direction-column pl-4">
        <p className="footer-link is-clickable" onClick={() => logout(instance)} data-cy="sidebar-logout">
          Logout
        </p>
      </div>
    </>
  )
}

export default Sidebar
