import { useEffect } from "react"
import { useMsal } from "@azure/msal-react"
import * as Sentry from "@sentry/react"
import { loginRequest } from "@config"
import { LoadingCube } from "@assets"

const Login = () => {
  const { instance } = useMsal()

  useEffect(() => {
    instance.loginRedirect(loginRequest).catch((e) => {
      Sentry.captureException(e, {
        tags: {
          section: "Login Page"
        },
        extra: {
          message: "Cannot login"
        }
      })
    })
  }, [])

  return (
    <div className="is-flex is-justify-content-center is-align-items-center is-full-height">
      <img src={LoadingCube} alt="loading cube gif" />
    </div>
  )
}

export default Login
