import React from "react"
import { ToggleButton } from "@components/inputs"
import {
  IconInfoEstimator,
  IconLumpSumWhite,
  IconLumpSumBlack,
  IconMultiplePayoutsWhite,
  IconMultiplePayoutsBlack
} from "@assets"

export const PayoutOption = ({ setInvestment, investment }) => {
  return (
    <section>
      <div className="mt-2">
        <ToggleButton
          value={investment}
          sideA={"Lump Sum"}
          sideB={"Multiple Payouts"}
          labelA={"Lump Sum"}
          labelB={"Multiple Payouts"}
          iconActiveA={IconLumpSumWhite}
          iconActiveB={IconMultiplePayoutsWhite}
          iconInactiveA={IconLumpSumBlack}
          iconInactiveB={IconMultiplePayoutsBlack}
          setState={setInvestment}
          stylesA={"ml-5 is-size-6"}
          stylesB={"mr-5 is-size-6"}
        />
      </div>
      <div className="is-size-7 py-2 px-6 has-text-grey has-text-centered">
        <img src={IconInfoEstimator} alt="icon info estimator" className="icon-info-estimator" />
        {investment === "Lump Sum" ? (
          <span>Client will be paid a single large amount upon reaching the payout age</span>
        ) : (
          <span>Client will be paid multiple amounts upon reaching each payout age milestone.</span>
        )}
      </div>
    </section>
  )
}
