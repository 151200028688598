import * as Sentry from "@sentry/react"
import { toast } from "react-toastify"
import { uploadStatusNotification } from "@components/notification"
import axios from "axios"
import axiosRetry from "axios-retry"
import { useAppContext } from "@context"
import { REACT_APP_DOCUMENT_UPLOAD_API_URL, REACT_APP_DOCUMENT_UPLOAD_API_KEY } from "@config"

const useUploadFile = () => {
  const { advisorId } = useAppContext()

  const api = axios.create({
    baseURL: REACT_APP_DOCUMENT_UPLOAD_API_URL,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-functions-key": REACT_APP_DOCUMENT_UPLOAD_API_KEY,
      "x-advisor-id": advisorId
    }
  })

  axiosRetry(api, {
    retries: 5,
    retryDelay: (retryCount) => {
      return Math.pow(2, retryCount) * 1000
    },
    retryCondition: () => true
  })

  const uploadFile = async (file, contractId) => {
    const form = new FormData()
    form.append(file.name, file)

    try {
      const res = await toast.promise(
        api.post("/upload-document", form, {
          headers: {
            "x-contract-id": contractId,
            "x-filename": file.name
          }
        }),
        uploadStatusNotification(file.name)
      )
      return res.data
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          section: "Upload document"
        },
        extra: {
          message: "Cannot upload document"
        }
      })
      return false
    }
  }

  return { uploadFile }
}

export default useUploadFile
