import * as Sentry from "@sentry/react"
import React, { useState, useEffect } from "react"
import swal from "sweetalert2"
import { EstimatorRender, Returns, EstimatorSimulation, EstimatorControls } from "@components/estimator"
import { useQuery } from "@hooks"
import {
  privateLumpSumOptions,
  privateMultiplePayoutsOptions,
  returnInvestmentOptions,
  REACT_APP_ESTIMATOR_API_URL,
  setMultiplePayoutsData
} from "@config"
import { SavvlyLogoBlack } from "@assets"
import {
  RETURN_INVESTMENT_GRAPH_PAGE,
  LUMP_SUM_AND_MULTIPLE_PAYOUT_GRAPH_PAGE,
  DEFAULT_CLIENT_AGE,
  DEFAULT_AVERAGE_MARKET_RETURN,
  DEFAULT_CLIENT_GENDER,
  DEFAULT_CLIENT_PAYOUT_AGE,
  DEFAULT_INVESTMENT_AMOUNT
} from "@utils"
import { FaFirefox, FaSafari, FaChrome, FaGooglePlay, FaApple } from "react-icons/fa"

const Estimator = () => {
  const { query } = useQuery()
  const [currentAge, setCurrentAge] = useState(DEFAULT_CLIENT_AGE)
  const [gender, setGender] = useState(DEFAULT_CLIENT_GENDER)
  const [investment, setInvestment] = useState("Lump Sum")
  const [averageReturn, setAverageReturn] = useState(DEFAULT_AVERAGE_MARKET_RETURN)
  const [fundingAmount, setFundingAmount] = useState(DEFAULT_INVESTMENT_AMOUNT)
  const [payoutAge, setPayoutAge] = useState(DEFAULT_CLIENT_PAYOUT_AGE)
  const [pageId, setPageId] = useState(1)
  const [returns, setReturns] = useState([])
  const [lump, setLump] = useState({})
  const [retries, sumRetries] = useState(0)
  const [chartData, setChartData] = useState(null)

  const investmentSimulationUrl = `investment-simulation?gender=${gender}&current_age=${currentAge}&funding_amount=${fundingAmount}&average_return=${averageReturn}`
  const lumpSumPayoutSimulationUrl = `lumpsum-simulation?gender=${gender}&current_age=${currentAge}&average_return=${averageReturn}&funding_amount=${fundingAmount}&payout_age=${payoutAge}`
  const multiplePayoutsSimulationUrl = `multiple-payouts-simulation?gender=${gender}&current_age=${currentAge}&average_return=${averageReturn}&funding_amount=${fundingAmount}`

  const errorModal = () => {
    if (retries > 2) {
      swal
        .fire({
          icon: "error",
          title: "Error",
          text: "Please check your internet connection and try again.",
          showCancelButton: true,
          confirmButtonText: "Reload Page"
        })
        .then((result) => {
          if (result.isConfirmed) {
            location.reload()
          }
        })
    } else {
      swal
        .fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong loading the information!",
          showCancelButton: true,
          confirmButtonText: "Try Again"
        })
        .then((result) => {
          if (result.isConfirmed) {
            sumRetries((prev) => (prev += 1))
            swal.close()
          }
        })
    }
  }

  // Simulation Return on Investment
  useEffect(() => {
    const fetchData = async () => {
      const { response, error, status } = await query({
        path: `${REACT_APP_ESTIMATOR_API_URL}/${investmentSimulationUrl}`
      })

      if (status && response) setReturns(response)
      else {
        errorModal()
      }
    }
    fetchData()
  }, [investmentSimulationUrl, retries])

  // Simulation Lump Sum
  useEffect(() => {
    const fetchData = async () => {
      const { response, status } = await query({
        path: `${REACT_APP_ESTIMATOR_API_URL}/${lumpSumPayoutSimulationUrl}`
      })

      if (status && response) setLump(response)
      else {
        errorModal()
      }
    }

    fetchData()
  }, [lumpSumPayoutSimulationUrl, retries])

  // Simulation Multiple Payout
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { response, status } = await query({
          path: `${REACT_APP_ESTIMATOR_API_URL}/${multiplePayoutsSimulationUrl}`
        })

        if (status && response) {
          setChartData(setMultiplePayoutsData(response))
        } else {
          errorModal()
        }
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            section: "Estimator section"
          },
          extra: {
            message: "Cannot get multiple payout estimator data",
            errors: error
          }
        })
      }
    }
    fetchData()
  }, [multiplePayoutsSimulationUrl, retries])

  const handleReset = () => {
    setPageId(1)
    setCurrentAge(45)
    setGender("Male")
    setAverageReturn(6)
    setFundingAmount(50000)
    setPayoutAge(75)
  }

  return (
    <div className="container is-flex is-flex-direction-column p-4 mt-5-non-desktop">
      <div className="estimator-hiding has-background-white is-justify-content-center ">
        <div className="has-text-centered">
          <img src={SavvlyLogoBlack} alt="Logo Savvly Black" />
          <h1 className="title has-text-primary">Estimator view not available</h1>
          <div className="has-text-centered">
            <FaFirefox size={40} className="mr-2" />
            <FaSafari size={40} className="mr-2" />
            <FaChrome size={40} />
          </div>
          <p className="mt-3">This version of the estimator is available only on desktop mode.</p>
          <div className="has-text-centered mt-4">
            <a
              href="https://play.google.com/store/apps/details?id=com.akadenia.savvly"
              target="_blank"
              rel="noopener noreferrer"
              className="button has-background-black has-text-white mr-2 mt-2">
              <FaGooglePlay size={18} className="mr-1" />
              Get it on Google Play
            </a>
            <a
              href="https://apps.apple.com/us/app/savvly/id6452803457"
              target="_blank"
              rel="noopener noreferrer"
              className="button has-background-black has-text-white mt-2">
              <FaApple size={18} className="mr-1" />
              Download on the App Store
            </a>
          </div>
        </div>
      </div>
      <div className="is-flex is-justify-content-space-between is-mobile-estimator">
        <h1 className="title has-text-primary">Estimator</h1>
        {pageId === LUMP_SUM_AND_MULTIPLE_PAYOUT_GRAPH_PAGE && (
          <button className="button is-outlined mr-3 is-primary" data-cy="reset-estimator-btn" onClick={handleReset}>
            Reset
          </button>
        )}
      </div>
      <hr />
      <div className="columns is-multiline my-box">
        <EstimatorControls
          gender={gender}
          setGender={setGender}
          payoutAge={payoutAge}
          setPayoutAge={setPayoutAge}
          currentAge={currentAge}
          setCurrentAge={setCurrentAge}
          investment={investment}
          setInvestment={setInvestment}
          fundingAmount={fundingAmount}
          setFundingAmount={setFundingAmount}
          averageReturn={averageReturn}
          setAverageReturn={setAverageReturn}
          pageId={pageId}
        />
        <EstimatorRender pageId={pageId}>
          <Returns childId={1} returns={returns} options={returnInvestmentOptions} />
          <EstimatorSimulation
            childId={2}
            investment={investment}
            lumpSumPayoutData={lump}
            multiplePayoutsData={chartData}
            lumpSumChartOptions={privateLumpSumOptions}
            multiplePayoutsChartOptions={privateMultiplePayoutsOptions}
          />
        </EstimatorRender>
      </div>
      <div className="has-text-centered">
        {pageId === RETURN_INVESTMENT_GRAPH_PAGE && (
          <button className="button is-primary" data-cy="payout-schedule-btn" onClick={() => setPageId(pageId + 1)}>
            Choose your payout schedule
          </button>
        )}
      </div>
    </div>
  )
}

export default Estimator
