import * as Sentry from "@sentry/react"
import { useMsal } from "@azure/msal-react"
import { loginRequest } from "@config"

export const LogInButton = () => {
  const { instance } = useMsal()

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch((e) => {
      Sentry.captureException(e, {
        tags: {
          section: "Landing Page"
        },
        extra: {
          message: "Cannot login"
        }
      })
    })
  }

  return (
    <button className="button is-primary" onClick={() => handleLogin(instance)} data-cy="login-button">
      Advisor Log In
    </button>
  )
}
