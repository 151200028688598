export const fiveYearsDifferenceNotification = (currentAge) => {
  return {
    msg: `The first payment will be made at age of ${currentAge + 5}, five years from the current age of ${currentAge}.`,
    toastType: "info"
  }
}
export const payoutAgeOutOfRangeNotification = (gender) => {
  return {
    msg: `Please enter a payout age between ${gender === "Male" ? "70 and 100" : "75 and 100"} years.`,
    toastType: "warning"
  }
}
export const ageOutOfRangeNotification = (profileAge) => {
  return {
    msg: `${profileAge} is not a valid entry. Please enter an age between 18 and 65 years.`,
    toastType: "warning"
  }
}
export const payoutAgeGreaterThanClientAge = (payoutAge, currentAge) => {
  return {
    msg: `The payout age of ${payoutAge}, must be greater than the client age of ${currentAge}`,
    toastType: "info"
  }
}
export const femalePayoutAgeValidationNotification = () => {
  return {
    msg: `${profileAge} is not a valid entry for male. Please enter a valid age for male between 18 and 65 years`,
    toastType: "warning"
  }
}
export const returnInvestmentOutOfRangeNotification = (returnInvestment) => {
  return {
    msg: `${returnInvestment} is not a valid entry. Please enter a return value between 1 and 20.`,
    toastType: "warning"
  }
}
export const uploadCancelledNotification = (fileName) => {
  return {
    msg: `Upload of ${fileName} was cancelled.`,
    toastType: "info"
  }
}
export const uploadStatusNotification = (filename) => {
  return {
    pending: {
      render() {
        return `Uploading file ${filename}. Please Wait...`
      }
    },
    success: {
      render() {
        return `${filename} uploaded successfully.`
      },
      icon: "✅"
    },
    error: {
      render() {
        return `There was an error uploading the file: ${filename}. Please try again.`
      },
      icon: "❌"
    }
  }
}

export const sendingContractNotification = () => {
  return {
    pending: {
      render() {
        return `Sending the contract by email. Please wait...`
      },
      position: "top-center"
    },
    success: {
      render() {
        return `Contract has been sent successfully!`
      },
      icon: "✅",
      position: "top-center"
    },
    error: {
      render() {
        return `There was a problem sending the contract. Please try again.`
      },
      icon: "❌",
      position: "top-center"
    }
  }
}
export const returnInvestmentInvalidEntryNotification = (returnInvestment, validEntries) => {
  return {
    msg: `${returnInvestment} is not a valid entry. Please enter one of the following values: ${validEntries.map((i) => i)} `,
    toastType: "warning"
  }
}
export const maleAgeValidationNotification = () => {
  return {
    msg: "Please enter an age for male between 40 and 65 years",
    type: "warn"
  }
}
